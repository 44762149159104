/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any }
}

export type AcceptOrganizationInvitationInput = {
  uniqueId: Scalars['String']['input']
}

export type AcceptUserInvitationInput = {
  uniqueId: Scalars['String']['input']
}

export type Activity = {
  __typename?: 'Activity'
  active: Scalars['Boolean']['output']
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  description: Scalars['String']['output']
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  responsibleParty: ResponsibleParty
  subtopic: Subtopic
  subtopicId: Scalars['String']['output']
  topic: Topic
  topicId: Scalars['String']['output']
  updateFrequency: UpdateFrequency
  updatedAt: Scalars['DateTime']['output']
}

export type ActivityStatusCountInput = {
  orgId?: InputMaybe<Scalars['String']['input']>
}

export type ActivitySubscription = {
  __typename?: 'ActivitySubscription'
  activity: Activity
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  dueDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['String']['output']
  inheritedFromBank: Scalars['Boolean']['output']
  lastChecked?: Maybe<Scalars['DateTime']['output']>
  nextReminderDate?: Maybe<Scalars['DateTime']['output']>
  optedOut: Scalars['Boolean']['output']
  organization: Organization
  sourceBank?: Maybe<Organization>
  status: ActivitySubscriptionStatus
  statusChangedDate?: Maybe<Scalars['DateTime']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export enum ActivitySubscriptionStatus {
  Completed = 'COMPLETED',
  OptedOut = 'OPTED_OUT',
  OverDue = 'OVER_DUE',
  Pending = 'PENDING',
  Required = 'REQUIRED',
}

export type ActivityTask = {
  __typename?: 'ActivityTask'
  /** The explanation for the approval of the activity task */
  approvalUserExplanation?: Maybe<Scalars['String']['output']>
  /** The date the activity task was approved */
  approvedAt?: Maybe<Scalars['DateTime']['output']>
  /** The user that approved the activity task */
  approvedBy?: Maybe<OrganizationUser>
  assets?: Maybe<Array<Asset>>
  /** The user that the task is assigned to */
  assignedTo?: Maybe<OrganizationUser>
  attachments?: Maybe<Array<ActivityTaskAttachment>>
  comments?: Maybe<Array<ActivityTaskComment>>
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  dueDate: Scalars['DateTime']['output']
  id: Scalars['String']['output']
  submissionStatus: ActivityTaskSubmissionStatus
  submittedAt?: Maybe<Scalars['DateTime']['output']>
  /** The user that submitted the activity task */
  submittedBy?: Maybe<OrganizationUser>
  /** The explanation for the submission of the activity task */
  submittingUserExplanation?: Maybe<Scalars['String']['output']>
  subscription: ActivitySubscription
  /** Summary of the activity task */
  summary?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type ActivityTaskAttachment = {
  __typename?: 'ActivityTaskAttachment'
  createdAt: Scalars['DateTime']['output']
  createdBy?: Maybe<User>
  createdById: Scalars['String']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  fileKey: Scalars['String']['output']
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  taskId: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  url?: Maybe<Scalars['String']['output']>
}

export type ActivityTaskComment = {
  __typename?: 'ActivityTaskComment'
  author: User
  content: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['String']['output']
  mentionedUsers?: Maybe<Array<User>>
  task: ActivityTask
  updatedAt: Scalars['DateTime']['output']
}

export type ActivityTaskCountByStatus = {
  __typename?: 'ActivityTaskCountByStatus'
  count: Scalars['Float']['output']
  status: ActivityTaskSubmissionStatus
}

export type ActivityTaskStatusCount = {
  __typename?: 'ActivityTaskStatusCount'
  count: Scalars['Float']['output']
  status: ActivityTaskSubmissionStatus
}

export type ActivityTaskStatusCountOutput = {
  __typename?: 'ActivityTaskStatusCountOutput'
  statusCounts: Array<ActivityTaskStatusCount>
  weekEndDate: Scalars['DateTime']['output']
  weekStartDate: Scalars['DateTime']['output']
}

export enum ActivityTaskSubmissionStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  InReview = 'IN_REVIEW',
  OnHold = 'ON_HOLD',
  OptedOut = 'OPTED_OUT',
  OverDue = 'OVER_DUE',
  Pending = 'PENDING',
  Submitted = 'SUBMITTED',
}

export type AddActivityTaskCommentInput = {
  comment: Scalars['String']['input']
  taskId: Scalars['String']['input']
}

export type AddAssetToActivityTaskInput = {
  asset: CreateAssetInput
  description: Scalars['String']['input']
  taskId: Scalars['String']['input']
}

export type AllStatesAndTerritoriesAndForeignFlags = {
  __typename?: 'AllStatesAndTerritoriesAndForeignFlags'
  code: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type AnalyzeRemediationInPeriodInput = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  numDays?: InputMaybe<Scalars['Float']['input']>
  organizationIds: Array<Scalars['String']['input']>
}

export type AnalyzeRemediationInPeriodOutput = {
  __typename?: 'AnalyzeRemediationInPeriodOutput'
  totalCompletedAfterDueDate: Scalars['Float']['output']
  totalCompletedInDueDate: Scalars['Float']['output']
  totalCompletedTasks: Scalars['Float']['output']
  totalCompletedWithoutDueDate: Scalars['Float']['output']
}

export type ApproveActivityTaskInput = {
  approvingUserId: Scalars['ID']['input']
  explanation: Scalars['String']['input']
  taskId: Scalars['ID']['input']
}

export type ArchiveSpaceInput = {
  organizationId: Scalars['String']['input']
  spaceId: Scalars['String']['input']
}

export type Asset = {
  __typename?: 'Asset'
  activityTask?: Maybe<ActivityTask>
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  documentType: NarrativeDocumentType
  id: Scalars['String']['output']
  key: Scalars['String']['output']
  name: Scalars['String']['output']
  originalFileKey: Scalars['String']['output']
  originalMimeType: MimeType
  partnerProductType?: Maybe<PartnerProductType>
  partnerProgramType?: Maybe<PartnerProgramType>
  projectTask?: Maybe<ProjectTask>
  reviewRequests?: Maybe<Array<ReviewRequest>>
  state: AssetState
  suggestions?: Maybe<Array<Suggestion>>
  suggestionsJobRunning: Scalars['Boolean']['output']
  type: AssetType
  updatedAt: Scalars['DateTime']['output']
  user: User
  vectorDbState: Array<AssetVectorDbState>
  version: Scalars['String']['output']
  versionIds: Array<Scalars['String']['output']>
}

export type AssetEdit = {
  __typename?: 'AssetEdit'
  addedText?: Maybe<Scalars['String']['output']>
  asset: Asset
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  fromVersionId: Scalars['String']['output']
  id: Scalars['String']['output']
  removedText?: Maybe<Scalars['String']['output']>
  toVersionId: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  user: User
}

export type AssetSearchFilters = {
  createdAtEndDate?: InputMaybe<Scalars['DateTime']['input']>
  createdAtStartDate?: InputMaybe<Scalars['DateTime']['input']>
  deletedAtEndDate?: InputMaybe<Scalars['DateTime']['input']>
  deletedAtStartDate?: InputMaybe<Scalars['DateTime']['input']>
  documentType?: InputMaybe<NarrativeDocumentType>
  ids?: InputMaybe<Array<Scalars['String']['input']>>
  name?: InputMaybe<Scalars['String']['input']>
  organizationIds?: InputMaybe<Array<Scalars['String']['input']>>
  originalMimeType?: InputMaybe<MimeType>
  spaceIds?: InputMaybe<Array<Scalars['String']['input']>>
  state?: InputMaybe<AssetState>
  type?: InputMaybe<AssetType>
  updatedAtEndDate?: InputMaybe<Scalars['DateTime']['input']>
  updatedAtStartDate?: InputMaybe<Scalars['DateTime']['input']>
  userIds?: InputMaybe<Array<Scalars['String']['input']>>
  withDeleted?: InputMaybe<Scalars['Boolean']['input']>
}

/** The current state the asset is in */
export enum AssetState {
  Created = 'CREATED',
  InReview = 'IN_REVIEW',
  Reviewed = 'REVIEWED',
}

/** The type of asset */
export enum AssetType {
  Text = 'TEXT',
}

/** The state of the asset in the VectorDB ingestion process. */
export enum AssetVectorDbState {
  Failed = 'FAILED',
  Ingested = 'INGESTED',
  InProgress = 'IN_PROGRESS',
  NotToIngest = 'NOT_TO_INGEST',
  Pending = 'PENDING',
}

export type AssetVersion = {
  __typename?: 'AssetVersion'
  editableFile?: Maybe<PresignedUrl>
  editableMimeType?: Maybe<MimeType>
  file: PresignedUrl
  id: Scalars['String']['output']
  label: Scalars['String']['output']
  mimeType: MimeType
  roomId: Scalars['String']['output']
}

export type AuditTrail = {
  __typename?: 'AuditTrail'
  action: Scalars['String']['output']
  contextId?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  entityId: Scalars['String']['output']
  entityType: AuditTrailEntity
  id: Scalars['String']['output']
  metadata?: Maybe<Scalars['JSON']['output']>
  organization?: Maybe<Organization>
  organizationId?: Maybe<Scalars['String']['output']>
  sourceId?: Maybe<Scalars['String']['output']>
  sourceType?: Maybe<AuditTrailSource>
  updatedAt: Scalars['DateTime']['output']
}

/** The entity the audit trail is recording an action on. */
export enum AuditTrailEntity {
  Activity = 'Activity',
  ActivitySubscription = 'ActivitySubscription',
  ActivityTask = 'ActivityTask',
  Asset = 'Asset',
  Organization = 'Organization',
  Partnership = 'Partnership',
  Program = 'Program',
  Project = 'Project',
  ProjectTask = 'ProjectTask',
  Space = 'Space',
  User = 'User',
  Workflow = 'Workflow',
}

/** The source entity of the audit trail. */
export enum AuditTrailSource {
  Ai = 'Ai',
  User = 'User',
  Workflow = 'Workflow',
}

export type BumpAssetVersionInput = {
  id: Scalars['String']['input']
  versionId: Scalars['String']['input']
}

export type CfpbComplaintMetadata = {
  __typename?: 'CFPBComplaintMetadata'
  company: Scalars['String']['output']
  company_public_response?: Maybe<Scalars['String']['output']>
  company_response: Scalars['String']['output']
  complaint_id: Scalars['Float']['output']
  complaint_what_happened: Scalars['String']['output']
  consumer_consent_provided?: Maybe<Scalars['String']['output']>
  consumer_disputed: Scalars['String']['output']
  date_received: Scalars['DateTime']['output']
  date_sent_to_company: Scalars['DateTime']['output']
  has_narrative: Scalars['Boolean']['output']
  issue: Scalars['String']['output']
  product: Scalars['String']['output']
  state: Scalars['String']['output']
  sub_issue?: Maybe<Scalars['String']['output']>
  sub_product: Scalars['String']['output']
  submitted_via: Scalars['String']['output']
  tags?: Maybe<Scalars['String']['output']>
  timely: Scalars['String']['output']
  zip_code: Scalars['String']['output']
}

export type CouDocumentAssetRevision = {
  attributes?: InputMaybe<Scalars['String']['input']>
  authorsIds?: InputMaybe<Array<Scalars['String']['input']>>
  createdAt?: InputMaybe<Scalars['String']['input']>
  creatorId?: InputMaybe<Scalars['String']['input']>
  diffData?: InputMaybe<Scalars['String']['input']>
  externalId: Scalars['String']['input']
  fromVersion?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  toVersion?: InputMaybe<Scalars['Int']['input']>
}

export type CouDocumentAssetRevisionInput = {
  assetId: Scalars['String']['input']
  revisions: Array<CouDocumentAssetRevision>
}

export type CancelOrganizationInvitationInput = {
  invitationId: Scalars['String']['input']
  organizationId: Scalars['String']['input']
}

export type CancelUserInvitationInput = {
  invitationId: Scalars['String']['input']
  userId: Scalars['ID']['input']
}

export type CategorizedLink = {
  __typename?: 'CategorizedLink'
  href: Scalars['String']['output']
  text: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type ChangeType = {
  __typename?: 'ChangeType'
  added?: Maybe<Scalars['Boolean']['output']>
  removed?: Maybe<Scalars['Boolean']['output']>
  value?: Maybe<Scalars['String']['output']>
}

export type Comment = {
  __typename?: 'Comment'
  attributes?: Maybe<Scalars['String']['output']>
  author: User
  commentThread?: Maybe<CommentThread>
  content: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  externalId: Scalars['String']['output']
  id: Scalars['String']['output']
  suggestionThread?: Maybe<SuggestionThread>
  updatedAt: Scalars['DateTime']['output']
}

export type CommentInput = {
  attributes?: InputMaybe<Scalars['String']['input']>
  content: Scalars['String']['input']
  externalId: Scalars['String']['input']
  mentionedUserIds?: InputMaybe<Array<Scalars['String']['input']>>
}

export type CommentThread = {
  __typename?: 'CommentThread'
  asset: Asset
  attributes?: Maybe<Scalars['String']['output']>
  author: User
  comments?: Maybe<Array<Comment>>
  context?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  externalId: Scalars['String']['output']
  id: Scalars['String']['output']
  resolvedAt?: Maybe<Scalars['DateTime']['output']>
  resolvedBy?: Maybe<User>
  updatedAt: Scalars['DateTime']['output']
}

export type CompareRemediationsInPeriodsOutput = {
  __typename?: 'CompareRemediationsInPeriodsOutput'
  previousPeriod: AnalyzeRemediationInPeriodOutput
  recentPeriod: AnalyzeRemediationInPeriodOutput
  totalCompletedAfterDueDatePercentageChange?: Maybe<Scalars['Float']['output']>
  totalCompletedInDueDatePercentageChange?: Maybe<Scalars['Float']['output']>
  totalCompletedTasksPercentageChange?: Maybe<Scalars['Float']['output']>
  totalCompletedWithoutDueDatePercentageChange?: Maybe<Scalars['Float']['output']>
}

export enum ComparisonOperator {
  Equal = 'Equal',
  GreaterThan = 'GreaterThan',
  GreaterThanOrEqual = 'GreaterThanOrEqual',
  LessThan = 'LessThan',
  LessThanOrEqual = 'LessThanOrEqual',
  NotEqual = 'NotEqual',
}

export type CompetitorOrganization = {
  __typename?: 'CompetitorOrganization'
  name: Scalars['String']['output']
  url: Scalars['String']['output']
}

/** The type of complaint */
export enum ComplaintCategory {
  AccountAndBankingIssues = 'AccountAndBankingIssues',
  AccountSecurityAndAccessIssues = 'AccountSecurityAndAccessIssues',
  BillingAndCharges = 'BillingAndCharges',
  CommunicationAndResponseTime = 'CommunicationAndResponseTime',
  ComparisonsWithCompetitors = 'ComparisonsWithCompetitors',
  CustomerDemographicsAndContext = 'CustomerDemographicsAndContext',
  FinancialTransactionsAndDisputes = 'FinancialTransactionsAndDisputes',
  FraudAndSecurityConcerns = 'FraudAndSecurityConcerns',
  GeneralDissatisfaction = 'GeneralDissatisfaction',
  ImportantRepetitions = 'ImportantRepetitions',
  LegalAndRegulatoryIssues = 'LegalAndRegulatoryIssues',
  PolicyAndTermsDisputes = 'PolicyAndTermsDisputes',
  PositiveFeedback = 'PositiveFeedback',
  ProductSpecificComplaints = 'ProductSpecificComplaints',
  ReputationAndReviewFeedback = 'ReputationAndReviewFeedback',
  ReputationalImpact = 'ReputationalImpact',
  ServiceAndSupportIssues = 'ServiceAndSupportIssues',
  TechnologyAndUsability = 'TechnologyAndUsability',
  Unknown = 'Unknown',
}

export enum ComplianceHealthStatus {
  Healthy = 'HEALTHY',
  IssuesDetected = 'ISSUES_DETECTED',
}

export enum ComplianceStatus {
  Compliant = 'COMPLIANT',
  NotCompliant = 'NOT_COMPLIANT',
}

export type ConditionGroup = {
  __typename?: 'ConditionGroup'
  conditions: Array<RegulationCondition>
}

export type ConditionGroupInput = {
  conditions?: InputMaybe<Array<RegulationConditionInput>>
}

export type Coordinates = {
  __typename?: 'Coordinates'
  layout_height: Scalars['Int']['output']
  layout_width: Scalars['Int']['output']
  points: Array<Array<Scalars['Float']['output']>>
  system: Scalars['String']['output']
}

export type CountOverdueTasksInput = {
  currentDate?: InputMaybe<Scalars['DateTime']['input']>
  organizationIds: Array<Scalars['String']['input']>
}

export type CountTasksByStatusInput = {
  numberOfPreviousWeeks?: InputMaybe<Scalars['Int']['input']>
  organizationIds: Array<Scalars['String']['input']>
}

export type CreateActivityInput = {
  active?: Scalars['Boolean']['input']
  description: Scalars['String']['input']
  name: Scalars['String']['input']
  responsibleParty: ResponsibleParty
  subtopicId: Scalars['String']['input']
  topicId: Scalars['String']['input']
  updateFrequency: UpdateFrequency
}

export type CreateActivitySubscriptionInput = {
  activityId: Scalars['ID']['input']
  description?: InputMaybe<Scalars['String']['input']>
  dueDate?: InputMaybe<Scalars['DateTime']['input']>
  inheritedFromBank: Scalars['Boolean']['input']
  optedOut: Scalars['Boolean']['input']
  organizationId: Scalars['ID']['input']
  sourceBankId?: InputMaybe<Scalars['ID']['input']>
  status: ActivitySubscriptionStatus
}

export type CreateActivityTaskFromSubscriptionIdInput = {
  dueDate: Scalars['DateTime']['input']
  now: Scalars['DateTime']['input']
  subscriptionId: Scalars['ID']['input']
}

export type CreateAssetInput = {
  documentType: NarrativeDocumentType
  fileKey: Scalars['String']['input']
  name: Scalars['String']['input']
  originalFileKey?: InputMaybe<Scalars['String']['input']>
  originalMimeType?: InputMaybe<MimeType>
  partnerProductType?: InputMaybe<PartnerProductType>
  partnerProgramType?: InputMaybe<PartnerProgramType>
  versionId: Scalars['String']['input']
}

export type CreateBankSubscriptionInput = {
  activityId: Scalars['String']['input']
  bankId: Scalars['String']['input']
}

export type CreateBankSubscriptionsToActivitiesInput = {
  activityIds: Array<Scalars['String']['input']>
  bankId: Scalars['String']['input']
}

export type CreateCommentInput = {
  assetId: Scalars['String']['input']
  comment: CommentInput
  threadExternalId: Scalars['String']['input']
}

export type CreateCommentThreadInput = {
  assetId: Scalars['String']['input']
  attributes?: InputMaybe<Scalars['String']['input']>
  comments: Array<CommentInput>
  context?: InputMaybe<Scalars['String']['input']>
  externalId: Scalars['String']['input']
}

export type CreateMosaicActivityReportInput = {
  activityIds: Array<Scalars['ID']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  fromDate?: InputMaybe<Scalars['DateTime']['input']>
  organizationId?: InputMaybe<Scalars['ID']['input']>
  partnerIds?: InputMaybe<Array<Scalars['ID']['input']>>
  toDate?: InputMaybe<Scalars['DateTime']['input']>
}

export type CreateMosaicBankReportInput = {
  activityIds?: InputMaybe<Array<Scalars['ID']['input']>>
  description?: InputMaybe<Scalars['String']['input']>
  fromDate?: InputMaybe<Scalars['DateTime']['input']>
  organizationId: Scalars['ID']['input']
  partnerIds: Array<Scalars['ID']['input']>
  toDate?: InputMaybe<Scalars['DateTime']['input']>
}

export type CreateMosaicFintechReportInput = {
  activityIds?: InputMaybe<Array<Scalars['ID']['input']>>
  description?: InputMaybe<Scalars['String']['input']>
  fromDate?: InputMaybe<Scalars['DateTime']['input']>
  organizationId: Scalars['ID']['input']
  toDate?: InputMaybe<Scalars['DateTime']['input']>
}

export type CreateMosaicPartnershipInput = {
  healthScore?: InputMaybe<Scalars['Int']['input']>
  metadata?: InputMaybe<Scalars['JSON']['input']>
  orgType: OrganizationType
  partnerOrganizationLegalName: Scalars['String']['input']
  partnerOrganizationName: Scalars['String']['input']
  productType: PartnerProductType
  programId?: InputMaybe<Scalars['String']['input']>
  programType: PartnerProgramType
  sponsorOrganizationId: Scalars['String']['input']
  users?: InputMaybe<Array<CreateUserInput>>
  websiteUrl?: InputMaybe<Scalars['String']['input']>
}

export type CreateMosaicReportInput = {
  activityIds?: InputMaybe<Array<Scalars['ID']['input']>>
  description?: InputMaybe<Scalars['String']['input']>
  fromDate?: InputMaybe<Scalars['DateTime']['input']>
  organizationId?: InputMaybe<Scalars['ID']['input']>
  partnerIds?: InputMaybe<Array<Scalars['ID']['input']>>
  subtopicId?: InputMaybe<Scalars['ID']['input']>
  toDate?: InputMaybe<Scalars['DateTime']['input']>
  topicId?: InputMaybe<Scalars['ID']['input']>
  type: MosaicReportType
}

export type CreateNotificationPreferencesProjectInput = {
  muteAll?: Scalars['Boolean']['input']
  notifyComments: NotificationPreferencesProjectOptions
  notifyEmail?: Scalars['Boolean']['input']
  notifySlack?: Scalars['Boolean']['input']
  notifyStatusFrequency: NotificationPreferencesProjectFrequency
  notifyStatusUpdates: NotificationPreferencesProjectStatusOptions
  notifyTasks: NotificationPreferencesProjectOptions
  projectId: Scalars['String']['input']
  userId: Scalars['String']['input']
}

export type CreateNotificationPreferencesSpaceInput = {
  muteAll?: Scalars['Boolean']['input']
  notifyAddedToProject?: Scalars['Boolean']['input']
  notifyEmail?: Scalars['Boolean']['input']
  notifyProjectCreated?: Scalars['Boolean']['input']
  notifySlack?: Scalars['Boolean']['input']
  spaceId: Scalars['String']['input']
  userId: Scalars['String']['input']
}

export type CreateNotificationPreferencesUserInput = {
  muteAll?: Scalars['Boolean']['input']
  notifyEmail?: Scalars['Boolean']['input']
  notifySlack?: Scalars['Boolean']['input']
  userId: Scalars['String']['input']
}

export type CreateOrganizationInput = {
  metadata?: InputMaybe<Scalars['JSON']['input']>
  name: Scalars['String']['input']
  orgType?: InputMaybe<OrganizationType>
  products: Array<ProductType>
  teamSize: Scalars['String']['input']
  teamType: Scalars['String']['input']
  teamUrl: Scalars['String']['input']
  users?: InputMaybe<Array<CreateUserInput>>
}

export type CreateOrganizationInvitationFailedItem = {
  __typename?: 'CreateOrganizationInvitationFailedItem'
  email: Scalars['String']['output']
  message: Scalars['String']['output']
}

export type CreateOrganizationInvitationInput = {
  email: Scalars['String']['input']
  organizationId: Scalars['String']['input']
  role: OrganizationUserRole
}

export type CreateOrganizationInvitationsInput = {
  invitations: Array<OrganizationInvitationItem>
  organizationId: Scalars['String']['input']
  organizationName: Scalars['String']['input']
}

export type CreateOrganizationInvitationsResponse = {
  __typename?: 'CreateOrganizationInvitationsResponse'
  failedItems: Array<CreateOrganizationInvitationFailedItem>
}

export type CreatePartnerSourceInput = {
  config?: InputMaybe<Scalars['JSON']['input']>
  name: SourceName
  partnershipId: Scalars['ID']['input']
  type?: InputMaybe<SourceType>
}

export type CreatePartnerSourcesInput = {
  configs: Array<CreatePartnerSourceInput>
}

export type CreatePartnershipInput = {
  healthScore?: InputMaybe<Scalars['Int']['input']>
  metadata?: InputMaybe<Scalars['JSON']['input']>
  orgType: OrganizationType
  partnerOrganizationName: Scalars['String']['input']
  programId?: InputMaybe<Scalars['String']['input']>
  sponsorOrganizationId: Scalars['String']['input']
  users?: InputMaybe<Array<CreateUserInput>>
}

export type CreateProcessFieldInput = {
  description: Scalars['String']['input']
  isRequired: Scalars['Boolean']['input']
  options?: InputMaybe<Scalars['JSON']['input']>
  processId: Scalars['String']['input']
  title: Scalars['String']['input']
  type: FormFieldType
}

export type CreateProcessInput = {
  access?: InputMaybe<ProcessAccess>
  description: Scalars['String']['input']
  organizationId: Scalars['String']['input']
  spaceId: Scalars['String']['input']
  status?: InputMaybe<ProcessStatus>
  title: Scalars['String']['input']
}

export type CreateProcessMembersInput = {
  processId: Scalars['String']['input']
  userIds: Array<Scalars['String']['input']>
}

export type CreateProgramInput = {
  description: Scalars['String']['input']
  isActive?: Scalars['Boolean']['input']
  name: Scalars['String']['input']
  organizationId: Scalars['String']['input']
  productType: PartnerProductType
  programType: PartnerProgramType
  requirements: Array<RequirementInput>
}

export type CreateProjectInput = {
  description: Scalars['String']['input']
  emails: Array<ProjectInviteEmailItem>
  icon: Scalars['String']['input']
  iconColor: Scalars['String']['input']
  isPublic: Scalars['Boolean']['input']
  name: Scalars['String']['input']
  spaceId: Scalars['String']['input']
}

export type CreateProjectInvitationFailedItem = {
  __typename?: 'CreateProjectInvitationFailedItem'
  email: Scalars['String']['output']
  message: Scalars['String']['output']
}

export type CreateProjectInvitationInput = {
  email: Scalars['String']['input']
  projectId: Scalars['String']['input']
  role: ProjectUserRole
}

export type CreateProjectInvitationsInput = {
  invitations: Array<ProjectInvitationsItem>
  projectId: Scalars['String']['input']
}

export type CreateProjectInvitationsResponse = {
  __typename?: 'CreateProjectInvitationsResponse'
  failedItems: Array<CreateProjectInvitationFailedItem>
}

export type CreateProjectTaskInput = {
  asset: CreateAssetInput
  description?: InputMaybe<Scalars['String']['input']>
  dueDate?: InputMaybe<Scalars['DateTime']['input']>
  name: Scalars['String']['input']
  priority: ProjectTaskPriority
  projectId: Scalars['String']['input']
  workflowTemplateId: Scalars['String']['input']
}

export type CreateProjectUserInput = {
  projectId: Scalars['String']['input']
  role: Scalars['String']['input']
  userId: Scalars['String']['input']
}

export type CreateProjectWorkflowTemplateInput = {
  description?: InputMaybe<Scalars['String']['input']>
  id: Scalars['String']['input']
  name: Scalars['String']['input']
  nodes: Array<WorkflowNodeTemplateInput>
  organizationId: Scalars['String']['input']
  projectId?: InputMaybe<Scalars['String']['input']>
  validate?: InputMaybe<Scalars['Boolean']['input']>
}

export type CreateReviewRequestInput = {
  assetId: Scalars['String']['input']
  status: ReviewRequestStatus
}

export type CreateRoomForAssetInput = {
  id: Scalars['String']['input']
  versionId: Scalars['String']['input']
}

export type CreateRuleInput = {
  conditionGroups?: InputMaybe<Array<ConditionGroupInput>>
  name: Scalars['String']['input']
  partnerSourceId: Scalars['String']['input']
}

export type CreateSpaceInput = {
  description: Scalars['String']['input']
  emails: Array<SpaceInviteEmailItem>
  isPublic: Scalars['Boolean']['input']
  name: Scalars['String']['input']
  organizationId: Scalars['String']['input']
}

export type CreateSpaceInvitationFailedItem = {
  __typename?: 'CreateSpaceInvitationFailedItem'
  email: Scalars['String']['output']
  message: Scalars['String']['output']
}

export type CreateSpaceInvitationInput = {
  email: Scalars['String']['input']
  role: SpaceUserRole
  spaceId: Scalars['String']['input']
}

export type CreateSpaceInvitationsInput = {
  invitations: Array<SpaceInvitationsItem>
  spaceId: Scalars['String']['input']
}

export type CreateSpaceInvitationsResponse = {
  __typename?: 'CreateSpaceInvitationsResponse'
  failedItems: Array<CreateSpaceInvitationFailedItem>
}

export type CreateSpaceUserInput = {
  role: Scalars['String']['input']
  spaceId: Scalars['String']['input']
  userId: Scalars['String']['input']
}

export type CreateTaskAttachmentInput = {
  fileKey: Scalars['String']['input']
  name: Scalars['String']['input']
  taskId: Scalars['String']['input']
  url: Scalars['String']['input']
}

export type CreateTaskAttachmentUploadUrlInput = {
  mimeType: Scalars['String']['input']
  taskId: Scalars['String']['input']
}

export type CreateUserInput = {
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  role: OrganizationUserRole
  title?: InputMaybe<OrganizationUserTitle>
}

export type CreateUserInvitationFailedItem = {
  __typename?: 'CreateUserInvitationFailedItem'
  email: Scalars['String']['output']
  message: Scalars['String']['output']
}

export type CreateUserInvitationInput = {
  email: Scalars['String']['input']
  inviterId: Scalars['ID']['input']
  role: UserRole
}

export type CreateUserInvitationsInput = {
  invitations: Array<UserInvitationItem>
}

export type CreateUserInvitationsResponse = {
  __typename?: 'CreateUserInvitationsResponse'
  failedItems: Array<CreateUserInvitationFailedItem>
}

export type CreateWorkflowNodeTemplateInput = {
  node: WorkflowNodeTemplateInput
  templateId: Scalars['String']['input']
}

export type CreateWorkflowTemplateInput = {
  description?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  nodes: Array<WorkflowNodeTemplateInput>
  organizationId: Scalars['String']['input']
  projectId?: InputMaybe<Scalars['String']['input']>
}

export enum DateFieldFilterType {
  ApprovedAt = 'APPROVED_AT',
  CreatedAt = 'CREATED_AT',
  SubmittedAt = 'SUBMITTED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type DateRangeFilter = {
  dateField?: InputMaybe<DateFieldFilterType>
  fromDate?: InputMaybe<Scalars['DateTime']['input']>
  toDate?: InputMaybe<Scalars['DateTime']['input']>
}

export type Debug = {
  __typename?: 'Debug'
  requestHandlerMode: Scalars['String']['output']
}

export type DeleteAssetFromActivityTaskInput = {
  assetId: Scalars['String']['input']
  assetName?: InputMaybe<Scalars['String']['input']>
  taskId: Scalars['String']['input']
}

export type DeleteCommentInput = {
  assetId: Scalars['String']['input']
  externalId: Scalars['String']['input']
}

export type DeleteCommentThreadInput = {
  assetId: Scalars['String']['input']
  externalId: Scalars['String']['input']
}

export type DeleteIntegrationInput = {
  integrationProviderId: IntegrationProvider
  organizationId: Scalars['String']['input']
}

export type DeletePartnershipInput = {
  partnershipId: Scalars['String']['input']
}

export type DeleteSlackDataChannelInput = {
  collection: KnockChannelCollections
  objectId: Scalars['String']['input']
  organizationId: Scalars['String']['input']
}

export type DisabledRuleStates = {
  __typename?: 'DisabledRuleStates'
  disabledRuleSets: Array<Scalars['String']['output']>
  disabledRules: Array<Scalars['String']['output']>
}

export type DocumentAssetRevision = {
  __typename?: 'DocumentAssetRevision'
  attributes?: Maybe<Scalars['String']['output']>
  authorsIds?: Maybe<Array<Scalars['String']['output']>>
  createdAt: Scalars['DateTime']['output']
  creatorId?: Maybe<Scalars['String']['output']>
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  diffData?: Maybe<Scalars['String']['output']>
  externalId: Scalars['String']['output']
  fromVersion: Scalars['Int']['output']
  id: Scalars['String']['output']
  name?: Maybe<Scalars['String']['output']>
  toVersion: Scalars['Int']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type EnrichedCfpbComplaintMetadata = {
  __typename?: 'EnrichedCFPBComplaintMetadata'
  category: ComplaintCategory
  keywords: Array<Scalars['String']['output']>
  mentionsDispute: Scalars['Boolean']['output']
  regulations: Array<Scalars['String']['output']>
  summary: Scalars['String']['output']
  text: Scalars['String']['output']
  threatensLegalAction: Scalars['Boolean']['output']
}

export type EnrichedFragment = {
  __typename?: 'EnrichedFragment'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  discriminator: Scalars['String']['output']
  fragment: Fragment
  id: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type EnrichedFragmentMetadataUnion =
  | EnrichedCfpbComplaintMetadata
  | EnrichedGoogleNewsSearchResultMetadata
  | EnrichedRedditPostMetadata
  | EnrichedSarFilingMetadata
  | EnrichedTiktokPostMetadata
  | EnrichedTrustPilotReviewMetadata
  | EnrichedWebsitePageMetadata

export type EnrichedFragmentQaInput = {
  discriminator: Scalars['String']['input']
  query: Scalars['String']['input']
}

export type EnrichedGoogleNewsSearchResultMetadata = {
  __typename?: 'EnrichedGoogleNewsSearchResultMetadata'
  link: Scalars['String']['output']
  text: Scalars['String']['output']
}

export type EnrichedRedditPostMetadata = {
  __typename?: 'EnrichedRedditPostMetadata'
  postUrl: Scalars['String']['output']
  text: Scalars['String']['output']
}

export type EnrichedSarFilingMetadata = {
  __typename?: 'EnrichedSARFilingMetadata'
  keywords: Array<Scalars['String']['output']>
  summary: Scalars['String']['output']
  text: Scalars['String']['output']
}

export type EnrichedTiktokPostFragment = {
  __typename?: 'EnrichedTiktokPostFragment'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  discriminator: Scalars['String']['output']
  fragment: Fragment
  id: Scalars['String']['output']
  metadata: EnrichedTiktokPostMetadata
  updatedAt: Scalars['DateTime']['output']
}

export type EnrichedTiktokPostMetadata = {
  __typename?: 'EnrichedTiktokPostMetadata'
  coverImageUrl: Scalars['String']['output']
  playUrl: Scalars['String']['output']
  text: Scalars['String']['output']
  webUrl: Scalars['String']['output']
}

export type EnrichedTrustPilotReviewMetadata = {
  __typename?: 'EnrichedTrustPilotReviewMetadata'
  category: ComplaintCategory
  keywords: Array<Scalars['String']['output']>
  mentionsDispute: Scalars['Boolean']['output']
  regulations: Array<Scalars['String']['output']>
  summary: Scalars['String']['output']
  text: Scalars['String']['output']
  threatensLegalAction: Scalars['Boolean']['output']
}

export type EnrichedWebsitePageMetadata = {
  __typename?: 'EnrichedWebsitePageMetadata'
  suggestions: Array<TextSuggestion>
  text: Scalars['String']['output']
}

export type FilterAuditTrailByEntityIdsInput = {
  entityIds: Array<Scalars['String']['input']>
  organizationId?: InputMaybe<Scalars['String']['input']>
}

export type FilterAuditTrailInput = {
  actionTypes: Array<FilterFacet>
  entityTypes: Array<FilterFacet>
  organizationId: Array<FilterFacet>
}

export type FilterFacet = {
  method: FilterFacetMethod
  value: Scalars['String']['input']
}

/** The method used to filter the field. */
export enum FilterFacetMethod {
  Contains = 'Contains',
  EndsWith = 'EndsWith',
  Equals = 'Equals',
  StartsWith = 'StartsWith',
}

/** Type of a form field */
export enum FormFieldType {
  Checkbox = 'Checkbox',
  Date = 'Date',
  MultiSelect = 'MultiSelect',
  Number = 'Number',
  SingleSelect = 'SingleSelect',
  Text = 'Text',
}

export type Fragment = {
  __typename?: 'Fragment'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  /** Designates a version of this fragment based on its content */
  diffkey?: Maybe<Scalars['String']['output']>
  discriminator: Scalars['String']['output']
  id: Scalars['String']['output']
  /** Designates where this fragment`s files are stored in S3 */
  storagekey?: Maybe<Scalars['String']['output']>
  /** Designates this fragment as the primary fragment representing this ukey */
  ukey?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type FragmentMetadataUnion =
  | CfpbComplaintMetadata
  | GoogleNewsSearchResultMetadata
  | RedditPost
  | SarFilingMetadata
  | TikTokPostMetadata
  | TrustpilotReview
  | WebsitePage

export type FragmentQaInput = {
  discriminator: Scalars['String']['input']
  query: Scalars['String']['input']
}

export type GetActivitiesForOrganizationInput = {
  organizationId: Scalars['String']['input']
}

export type GetActivityTasksInput = {
  activityId?: InputMaybe<Scalars['String']['input']>
  activityIds?: InputMaybe<Array<Scalars['String']['input']>>
  assignedToId?: InputMaybe<Scalars['String']['input']>
  currentOrganizationId?: InputMaybe<Scalars['ID']['input']>
  currentUserId?: InputMaybe<Scalars['ID']['input']>
  dateRange?: InputMaybe<DateRangeFilter>
  /** Include relations in the query. Defaults to true. */
  includeRelations?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['Float']['input']>
  organizationId?: InputMaybe<Scalars['String']['input']>
  organizationIds?: InputMaybe<Array<Scalars['String']['input']>>
  sourceBankId?: InputMaybe<Scalars['String']['input']>
  submissionStatus?: InputMaybe<ActivityTaskSubmissionStatus>
  subscriptionId?: InputMaybe<Scalars['String']['input']>
}

export type GetAssetDownloadUrlInput = {
  assetId: Scalars['String']['input']
  versionId?: InputMaybe<Scalars['String']['input']>
}

export type GetAssetReviewRequestsInput = {
  assetId: Scalars['String']['input']
  status?: InputMaybe<ReviewRequestStatus>
}

export type GetAssetUploadUrlInput = {
  type?: InputMaybe<MimeType>
}

export type GetCommentThreadInput = {
  assetId: Scalars['String']['input']
  externalId: Scalars['String']['input']
}

export type GetCommentThreadsInput = {
  assetId: Scalars['String']['input']
}

export type GetDocumentAssetRevisionInput = {
  assetId: Scalars['String']['input']
  revisionId: Scalars['String']['input']
}

export type GetDocumentAssetRevisionsInput = {
  assetId: Scalars['String']['input']
}

export type GetFragmentsForPartnershipInput = {
  onlyPrimaryFragments?: InputMaybe<Scalars['Boolean']['input']>
  partnershipId: Scalars['String']['input']
}

export type GetLogoUploadUrlInput = {
  mimeType: Scalars['String']['input']
  organizationId: Scalars['String']['input']
}

export type GetOrganizationInput = {
  id: Scalars['String']['input']
}

export type GetProgramsForOrganizationInput = {
  organizationId: Scalars['String']['input']
}

export type GetProjectInvitationsForProjectInput = {
  projectId: Scalars['String']['input']
  status: ProjectInvitationStatus
}

export type GetProjectInvitationsForUserInput = {
  status: ProjectInvitationStatus
}

export type GetSpaceInvitationsForSpaceInput = {
  spaceId: Scalars['String']['input']
  status: SpaceInvitationStatus
}

export type GetSponsorInput = {
  organizationId: Scalars['String']['input']
}

export type GetSponsorshipsInput = {
  organizationId: Scalars['String']['input']
}

export type GetSuggestionsInput = {
  assetId: Scalars['String']['input']
  resolved?: InputMaybe<Scalars['Boolean']['input']>
}

export type GetUserAutocompleteInput = {
  assetId: Scalars['String']['input']
  text: Scalars['String']['input']
}

export type GetUsersExternalInput = {
  externalUserIds: Array<Scalars['String']['input']>
}

export type GoogleNewsSearchResultFragment = {
  __typename?: 'GoogleNewsSearchResultFragment'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  /** Designates a version of this fragment based on its content */
  diffkey?: Maybe<Scalars['String']['output']>
  discriminator: Scalars['String']['output']
  id: Scalars['String']['output']
  metadata: GoogleNewsSearchResultMetadata
  /** Designates where this fragment`s files are stored in S3 */
  storagekey?: Maybe<Scalars['String']['output']>
  /** Designates this fragment as the primary fragment representing this ukey */
  ukey?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type GoogleNewsSearchResultMetadata = {
  __typename?: 'GoogleNewsSearchResultMetadata'
  date: Scalars['DateTime']['output']
  link: Scalars['String']['output']
  snippet: Scalars['String']['output']
  sourceIcon: Scalars['String']['output']
  sourceName: Scalars['String']['output']
  thumbnail: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type Headers = {
  __typename?: 'Headers'
  age: Scalars['String']['output']
  altSvc: Scalars['String']['output']
  cacheControl: Scalars['String']['output']
  contentEncoding: Scalars['String']['output']
  contentLength: Scalars['String']['output']
  contentType: Scalars['String']['output']
  date: Scalars['String']['output']
  etag: Scalars['String']['output']
  lastModified: Scalars['String']['output']
  server: Scalars['String']['output']
  vary: Scalars['String']['output']
  via: Scalars['String']['output']
  xFirefoxSpdy: Scalars['String']['output']
}

export type InheritBankSubscriptionsInput = {
  bankId: Scalars['String']['input']
  fintechId: Scalars['String']['input']
}

export type Integration = {
  __typename?: 'Integration'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['String']['output']
  integrationProviderId: IntegrationProvider
  isEnabled: Scalars['Boolean']['output']
  organization: Organization
  updatedAt: Scalars['DateTime']['output']
  user?: Maybe<User>
}

export enum IntegrationProvider {
  Email = 'EMAIL',
  Gdrive = 'GDRIVE',
  Slack = 'SLACK',
}

export type Issue = {
  __typename?: 'Issue'
  attachments?: Maybe<Array<IssueAttachment>>
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  description: Scalars['String']['output']
  id: Scalars['String']['output']
  justification: Scalars['String']['output']
  level: Scalars['String']['output']
  partnerSourceEnrichedFragment?: Maybe<PartnerSourceEnrichedFragment>
  partnerSourceEnrichedFragmentId?: Maybe<Scalars['String']['output']>
  reasons?: Maybe<Array<IssueReason>>
  status: IssueStatus
  title: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type IssueAttachment = {
  __typename?: 'IssueAttachment'
  createdAt: Scalars['DateTime']['output']
  createdBy?: Maybe<User>
  createdById: Scalars['String']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  fileKey: Scalars['String']['output']
  id: Scalars['String']['output']
  issueId: Scalars['String']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  url?: Maybe<Scalars['String']['output']>
}

export type IssueItemOutput = {
  __typename?: 'IssueItemOutput'
  attachments?: Maybe<Array<IssueAttachment>>
  createdAt: Scalars['DateTime']['output']
  description: Scalars['String']['output']
  fragmentItem: PartnerSourceFragmentItemOutput
  id: Scalars['String']['output']
  justification: Scalars['String']['output']
  level: Scalars['String']['output']
  partnerSource: PartnerSource
  reasons: Array<IssueReason>
  status: IssueStatus
  ticketId: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type IssueReason = {
  __typename?: 'IssueReason'
  justification: Scalars['String']['output']
  reference: Scalars['String']['output']
  referenceUrl: Scalars['String']['output']
  title: Scalars['String']['output']
}

/** The status of the Issue */
export enum IssueStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
}

export enum KnockChannelCollections {
  Organization = 'ORGANIZATION',
  Projects = 'PROJECTS',
  Spaces = 'SPACES',
}

export type LatestActivityTaskSummary = {
  __typename?: 'LatestActivityTaskSummary'
  approvalUserExplanation?: Maybe<Scalars['String']['output']>
  comments: Array<ActivityTaskComment>
  lastRequestedAt: Scalars['DateTime']['output']
  lastSubmittedAt?: Maybe<Scalars['DateTime']['output']>
  submittingUserExplanation?: Maybe<Scalars['String']['output']>
}

export type LiveblocksRoom = {
  __typename?: 'LiveblocksRoom'
  createdAt: Scalars['String']['output']
  id: Scalars['String']['output']
  lastConnectionAt?: Maybe<Scalars['String']['output']>
}

export type LogAnalysisResponse = {
  __typename?: 'LogAnalysisResponse'
  output: Scalars['String']['output']
}

export type MsbActivity = {
  __typename?: 'MSBActivity'
  code: Scalars['Float']['output']
  name: Scalars['String']['output']
}

export type MsbLicenceFragment = {
  __typename?: 'MSBLicenceFragment'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  /** Designates a version of this fragment based on its content */
  diffkey?: Maybe<Scalars['String']['output']>
  discriminator: Scalars['String']['output']
  id: Scalars['String']['output']
  metadata: MsbLicenceMetadata
  /** Designates where this fragment`s files are stored in S3 */
  storagekey?: Maybe<Scalars['String']['output']>
  /** Designates this fragment as the primary fragment representing this ukey */
  ukey?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type MsbLicenceMetadata = {
  __typename?: 'MSBLicenceMetadata'
  allStatesAndTerritoriesAndForeignFlags: Array<AllStatesAndTerritoriesAndForeignFlags>
  authSignDate: Scalars['String']['output']
  city: Scalars['String']['output']
  dbaName: Scalars['String']['output']
  legalName: Scalars['String']['output']
  letterUrl: Scalars['String']['output']
  msbActivities: Array<MsbActivity>
  numberOfBranches: Scalars['String']['output']
  receivedDate: Scalars['String']['output']
  state: Scalars['String']['output']
  statesOfMSBActivities: Array<Scalars['String']['output']>
  streetAddress: Scalars['String']['output']
  zip: Scalars['String']['output']
}

export type MarkOutOfScopeInput = {
  subscriptionId: Scalars['String']['input']
}

export enum MimeType {
  Csv = 'Csv',
  Doc = 'Doc',
  Docx = 'Docx',
  Html = 'Html',
  Jpeg = 'Jpeg',
  Jpg = 'Jpg',
  Mov = 'Mov',
  Mp4 = 'Mp4',
  Pdf = 'Pdf',
  Png = 'Png',
  Svg = 'Svg',
  Text = 'Text',
  Webm = 'Webm',
  Webp = 'Webp',
  Zip = 'Zip',
}

export type MosaicActivitiesReport = {
  __typename?: 'MosaicActivitiesReport'
  description: Scalars['String']['output']
  id: Scalars['String']['output']
  reports: Array<MosaicActivityReport>
}

export type MosaicActivityReport = {
  __typename?: 'MosaicActivityReport'
  activity: Activity
  description: Scalars['String']['output']
  id: Scalars['String']['output']
  items: Array<MosaicReportItem>
}

export type MosaicAdminReport = {
  __typename?: 'MosaicAdminReport'
  description: Scalars['String']['output']
  id: Scalars['String']['output']
  reports: Array<MosaicBankHealthReport>
}

export type MosaicBankHealthReport = {
  __typename?: 'MosaicBankHealthReport'
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  overallHealth: Scalars['String']['output']
  topics: Array<TopicStatus>
}

export type MosaicBankReport = {
  __typename?: 'MosaicBankReport'
  description: Scalars['String']['output']
  id: Scalars['String']['output']
  organization: Organization
  reports: Array<MosaicFintechReport>
}

export type MosaicFintechReport = {
  __typename?: 'MosaicFintechReport'
  description: Scalars['String']['output']
  id: Scalars['String']['output']
  organization: Organization
  reports: Array<MosaicActivityReport>
}

export type MosaicPartnership = {
  __typename?: 'MosaicPartnership'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  healthScore?: Maybe<Scalars['Int']['output']>
  id: Scalars['String']['output']
  onboardingFinishTime?: Maybe<Scalars['DateTime']['output']>
  onboardingStartTime?: Maybe<Scalars['DateTime']['output']>
  partnerOrganization: Organization
  partnerOrganizationId: Scalars['String']['output']
  productType: PartnerProductType
  programType: PartnerProgramType
  sponsorOrganization: Organization
  sponsorOrganizationId: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type MosaicReportItem = {
  __typename?: 'MosaicReportItem'
  activityTasks: Array<ActivityTask>
  complianceStatus: ComplianceStatus
  id: Scalars['String']['output']
  latestActivityTaskSummary: LatestActivityTaskSummary
  partnerName: Scalars['String']['output']
}

export type MosaicReportOutput = {
  __typename?: 'MosaicReportOutput'
  report?: Maybe<MosaicReportUnion>
  type: MosaicReportType
}

export enum MosaicReportType {
  Activities = 'Activities',
  Activity = 'Activity',
  Adhoc = 'Adhoc',
  Bank = 'Bank',
  Fintech = 'Fintech',
}

export type MosaicReportUnion =
  | MosaicActivitiesReport
  | MosaicActivityReport
  | MosaicAdminReport
  | MosaicBankReport
  | MosaicFintechReport

export type Mutation = {
  __typename?: 'Mutation'
  acceptOrganizationInvitation: OrganizationInvitation
  acceptProjectInvitation: ProjectInvitation
  acceptSpaceInvitation: SpaceInvitation
  acceptUserInvitation: UserInvitation
  addAssetToActivityTask: ActivityTask
  addCommentToActivityTask: ActivityTaskComment
  advanceWorkflow?: Maybe<WorkflowNode>
  approveActivityTask: ActivityTask
  archiveProject: Scalars['Boolean']['output']
  archiveProjectTask: ProjectTask
  archiveSpace: Scalars['Boolean']['output']
  archiveWorkflowTemplate: Scalars['Boolean']['output']
  bumpAssetVersion: Asset
  cancelOrganizationInvitation: Scalars['Boolean']['output']
  cancelProjectInvitation: Scalars['Boolean']['output']
  cancelSpaceInvitation: Scalars['Boolean']['output']
  cancelUserInvitation: Scalars['Boolean']['output']
  createActivity: Activity
  createActivitySubscription: ActivitySubscription
  createActivityTaskAttachment: ActivityTaskAttachment
  createActivityTaskAttachmentUploadUrl: PresignedUrl
  createActivityTaskAttachments: Array<ActivityTaskAttachment>
  createActivityTaskFromSubscription: ActivityTask
  createAsset: Asset
  createBankSubscription: ActivitySubscription
  createBankSubscriptionsToActivities: Array<ActivitySubscription>
  createComment: Comment
  createCommentThread: CommentThread
  createIssuesForPartnerSource: Array<IssueItemOutput>
  createIssuesForPartnership: Array<Issue>
  createMosaicPartnership: MosaicPartnership
  createNotificationPreferencesProject: NotificationPreferencesProject
  createNotificationPreferencesSpace: NotificationPreferencesSpace
  createNotificationPreferencesUser: NotificationPreferencesUser
  createOrUpdateDocumentAssetRevisions: Array<DocumentAssetRevision>
  createOrganization: Organization
  createOrganizationInvitation: OrganizationInvitation
  createOrganizationInvitations: CreateOrganizationInvitationsResponse
  createPartnerSource: PartnerSource
  createPartnerSources: Array<PartnerSource>
  createPartnership: Partnership
  createProcess: Process
  createProcessMembers: Array<ProcessMember>
  createProgram: Program
  createProject: Project
  createProjectInvitation: ProjectInvitation
  createProjectInvitations: CreateProjectInvitationsResponse
  createProjectTask: ProjectTask
  createProjectTaskAttachment: ProjectTaskAttachment
  createProjectTaskAttachmentUploadUrl: PresignedUrl
  createProjectTaskAttachments: Array<ProjectTaskAttachment>
  createProjectUser: ProjectUser
  createProjectWorkflowTemplate: WorkflowTemplate
  createRule: Rule
  createSpace: Space
  createSpaceInvitation: SpaceInvitation
  createSpaceInvitations: CreateSpaceInvitationsResponse
  createSpaceUser: SpaceUser
  createUserInvitation: UserInvitation
  createUserInvitations: CreateUserInvitationsResponse
  createWorkflowNodeTemplate?: Maybe<WorkflowNodeTemplate>
  createWorkflowTemplate: WorkflowTemplate
  declineOrganizationInvitation: Scalars['Boolean']['output']
  declineProjectInvitation: Scalars['Boolean']['output']
  declineSpaceInvitation: Scalars['Boolean']['output']
  declineUserInvitation: Scalars['Boolean']['output']
  deleteActivities: Scalars['Boolean']['output']
  deleteActivity: Scalars['Boolean']['output']
  deleteActivitySubscription: Scalars['Boolean']['output']
  deleteActivitySubscriptionCascade: Scalars['Boolean']['output']
  deleteActivityTaskAttachment: Scalars['Boolean']['output']
  deleteAsset: Scalars['Boolean']['output']
  deleteAssetFromActivityTask: ActivityTask
  deleteComment: Scalars['Boolean']['output']
  deleteCommentThread: Scalars['Boolean']['output']
  deleteIntegration: Scalars['Boolean']['output']
  deleteIssue: Issue
  deleteMosaicPartnership: Scalars['Boolean']['output']
  deleteOrganization: Scalars['Boolean']['output']
  deletePartnerSource: PartnerSource
  deletePartnership: Scalars['Boolean']['output']
  deleteProgram: Scalars['Boolean']['output']
  deleteProject: Scalars['Boolean']['output']
  deleteProjectTaskAttachment: Scalars['Boolean']['output']
  deleteProjectUser: Scalars['Boolean']['output']
  deleteRule: Rule
  deleteSlackDataChannel: Scalars['Boolean']['output']
  deleteSpace: Scalars['Boolean']['output']
  deleteSpaceUser: Scalars['Boolean']['output']
  deleteWorkflowNodeTemplate?: Maybe<Scalars['Boolean']['output']>
  deleteWorkflowTemplate: Scalars['Boolean']['output']
  enrichmentQA: LogAnalysisResponse
  fragmentQA: LogAnalysisResponse
  getOrCreateLiveblocksRoomForAsset: LiveblocksRoom
  ignoreSuggestionsForAsset: Scalars['Boolean']['output']
  inheritBankSubscriptions: Scalars['Boolean']['output']
  initiatePartnerOnboarding: PartnerOnboardingOutput
  joinSpace: SpaceUser
  leaveOrganization: Scalars['Boolean']['output']
  leaveProject: Scalars['Boolean']['output']
  leaveSpace: Scalars['Boolean']['output']
  markOutOfScope: Scalars['Boolean']['output']
  optOutOfSubscription: Scalars['Boolean']['output']
  queueSuggestionsJobForAsset: Scalars['Boolean']['output']
  removeProcess: Process
  removeProcessField: Scalars['Boolean']['output']
  removeProcessMembers: ProcessMember
  reviewRequest: ReviewRequest
  runSinglePartnerSource: Scalars['Boolean']['output']
  saveProcessFields: Array<ProcessField>
  setProcessMembers: Array<ProcessMember>
  submitActivityTask: ActivityTask
  unarchiveProjectTask: ProjectTask
  updateActivity: Activity
  updateActivitySubscription: ActivitySubscription
  updateActivityTaskAssignee: ActivityTask
  updateActivityTaskDueDate: ActivityTask
  updateActivityTaskStatus: ActivityTask
  updateAsset: Asset
  updateComment: Comment
  updateCommentThread: CommentThread
  updateIssue: Issue
  updateNotificationPreferencesProject: NotificationPreferencesProject
  updateNotificationPreferencesSpace: NotificationPreferencesSpace
  updateNotificationPreferencesUser: NotificationPreferencesUser
  updateOrganization: Organization
  updateOrganizationMetadata: Organization
  updateOrganizationUser: OrganizationUser
  updatePartnerSource: PartnerSource
  updatePartnership: Partnership
  updateProcess: Process
  updateProcessField: ProcessField
  updateProgram: Program
  updateProject: Project
  updateProjectAsset: Asset
  updateProjectTask: ProjectTask
  updateProjectTaskStatus: ProjectTask
  updateProjectUser: ProjectUser
  updateProjectWorkflowTemplate: WorkflowTemplate
  updateReviewRequest: ReviewRequest
  updateRule: Rule
  updateSlackDataChannel: SlackDataChannel
  updateSpace: Space
  updateSpaceUser: SpaceUser
  updateSuggestion: Scalars['Boolean']['output']
  updateUser: User
  updateUserRole: User
  updateWorkflowNode: WorkflowNode
  updateWorkflowNodeTemplate?: Maybe<WorkflowNodeTemplate>
  updateWorkflowTemplate: WorkflowTemplate
  updateWorkflowTemplateActiveStatus: Scalars['Boolean']['output']
}

export type MutationAcceptOrganizationInvitationArgs = {
  input: AcceptOrganizationInvitationInput
}

export type MutationAcceptProjectInvitationArgs = {
  uniqueId: Scalars['String']['input']
}

export type MutationAcceptSpaceInvitationArgs = {
  uniqueId: Scalars['String']['input']
}

export type MutationAcceptUserInvitationArgs = {
  input: AcceptUserInvitationInput
}

export type MutationAddAssetToActivityTaskArgs = {
  input: AddAssetToActivityTaskInput
}

export type MutationAddCommentToActivityTaskArgs = {
  input: AddActivityTaskCommentInput
}

export type MutationAdvanceWorkflowArgs = {
  taskId: Scalars['String']['input']
}

export type MutationApproveActivityTaskArgs = {
  input: ApproveActivityTaskInput
}

export type MutationArchiveProjectArgs = {
  id: Scalars['String']['input']
}

export type MutationArchiveProjectTaskArgs = {
  id: Scalars['String']['input']
}

export type MutationArchiveSpaceArgs = {
  input: ArchiveSpaceInput
}

export type MutationArchiveWorkflowTemplateArgs = {
  id: Scalars['String']['input']
}

export type MutationBumpAssetVersionArgs = {
  input: BumpAssetVersionInput
}

export type MutationCancelOrganizationInvitationArgs = {
  input: CancelOrganizationInvitationInput
}

export type MutationCancelProjectInvitationArgs = {
  uniqueId: Scalars['String']['input']
}

export type MutationCancelSpaceInvitationArgs = {
  uniqueId: Scalars['String']['input']
}

export type MutationCancelUserInvitationArgs = {
  input: CancelUserInvitationInput
}

export type MutationCreateActivityArgs = {
  input: CreateActivityInput
}

export type MutationCreateActivitySubscriptionArgs = {
  input: CreateActivitySubscriptionInput
}

export type MutationCreateActivityTaskAttachmentArgs = {
  input: CreateTaskAttachmentInput
}

export type MutationCreateActivityTaskAttachmentUploadUrlArgs = {
  input: CreateTaskAttachmentUploadUrlInput
}

export type MutationCreateActivityTaskAttachmentsArgs = {
  input: Array<CreateTaskAttachmentInput>
}

export type MutationCreateActivityTaskFromSubscriptionArgs = {
  input: CreateActivityTaskFromSubscriptionIdInput
}

export type MutationCreateAssetArgs = {
  input: CreateAssetInput
}

export type MutationCreateBankSubscriptionArgs = {
  input: CreateBankSubscriptionInput
}

export type MutationCreateBankSubscriptionsToActivitiesArgs = {
  input: CreateBankSubscriptionsToActivitiesInput
}

export type MutationCreateCommentArgs = {
  input: CreateCommentInput
}

export type MutationCreateCommentThreadArgs = {
  input: CreateCommentThreadInput
}

export type MutationCreateIssuesForPartnerSourceArgs = {
  partnerSourceId: Scalars['String']['input']
}

export type MutationCreateIssuesForPartnershipArgs = {
  partnershipId: Scalars['String']['input']
}

export type MutationCreateMosaicPartnershipArgs = {
  input: CreateMosaicPartnershipInput
}

export type MutationCreateNotificationPreferencesProjectArgs = {
  input: CreateNotificationPreferencesProjectInput
}

export type MutationCreateNotificationPreferencesSpaceArgs = {
  input: CreateNotificationPreferencesSpaceInput
}

export type MutationCreateNotificationPreferencesUserArgs = {
  input: CreateNotificationPreferencesUserInput
}

export type MutationCreateOrUpdateDocumentAssetRevisionsArgs = {
  input: CouDocumentAssetRevisionInput
}

export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput
}

export type MutationCreateOrganizationInvitationArgs = {
  input: CreateOrganizationInvitationInput
}

export type MutationCreateOrganizationInvitationsArgs = {
  input: CreateOrganizationInvitationsInput
}

export type MutationCreatePartnerSourceArgs = {
  input: CreatePartnerSourceInput
}

export type MutationCreatePartnerSourcesArgs = {
  input: CreatePartnerSourcesInput
}

export type MutationCreatePartnershipArgs = {
  input: CreatePartnershipInput
}

export type MutationCreateProcessArgs = {
  input: CreateProcessInput
}

export type MutationCreateProcessMembersArgs = {
  input: CreateProcessMembersInput
}

export type MutationCreateProgramArgs = {
  input: CreateProgramInput
}

export type MutationCreateProjectArgs = {
  input: CreateProjectInput
}

export type MutationCreateProjectInvitationArgs = {
  input: CreateProjectInvitationInput
}

export type MutationCreateProjectInvitationsArgs = {
  input: CreateProjectInvitationsInput
}

export type MutationCreateProjectTaskArgs = {
  input: CreateProjectTaskInput
}

export type MutationCreateProjectTaskAttachmentArgs = {
  input: CreateTaskAttachmentInput
}

export type MutationCreateProjectTaskAttachmentUploadUrlArgs = {
  input: CreateTaskAttachmentUploadUrlInput
}

export type MutationCreateProjectTaskAttachmentsArgs = {
  input: Array<CreateTaskAttachmentInput>
}

export type MutationCreateProjectUserArgs = {
  input: CreateProjectUserInput
}

export type MutationCreateProjectWorkflowTemplateArgs = {
  input: CreateProjectWorkflowTemplateInput
}

export type MutationCreateRuleArgs = {
  input: CreateRuleInput
}

export type MutationCreateSpaceArgs = {
  input: CreateSpaceInput
}

export type MutationCreateSpaceInvitationArgs = {
  input: CreateSpaceInvitationInput
}

export type MutationCreateSpaceInvitationsArgs = {
  input: CreateSpaceInvitationsInput
}

export type MutationCreateSpaceUserArgs = {
  input: CreateSpaceUserInput
}

export type MutationCreateUserInvitationArgs = {
  input: CreateUserInvitationInput
}

export type MutationCreateUserInvitationsArgs = {
  input: CreateUserInvitationsInput
}

export type MutationCreateWorkflowNodeTemplateArgs = {
  input: CreateWorkflowNodeTemplateInput
}

export type MutationCreateWorkflowTemplateArgs = {
  input: CreateWorkflowTemplateInput
}

export type MutationDeclineOrganizationInvitationArgs = {
  input: AcceptOrganizationInvitationInput
}

export type MutationDeclineProjectInvitationArgs = {
  uniqueId: Scalars['String']['input']
}

export type MutationDeclineSpaceInvitationArgs = {
  uniqueId: Scalars['String']['input']
}

export type MutationDeclineUserInvitationArgs = {
  input: AcceptUserInvitationInput
}

export type MutationDeleteActivitiesArgs = {
  ids: Array<Scalars['String']['input']>
}

export type MutationDeleteActivityArgs = {
  id: Scalars['String']['input']
}

export type MutationDeleteActivitySubscriptionArgs = {
  id: Scalars['String']['input']
}

export type MutationDeleteActivitySubscriptionCascadeArgs = {
  cascade: Scalars['Boolean']['input']
  id: Scalars['String']['input']
}

export type MutationDeleteActivityTaskAttachmentArgs = {
  attachmentId: Scalars['String']['input']
}

export type MutationDeleteAssetArgs = {
  id: Scalars['String']['input']
}

export type MutationDeleteAssetFromActivityTaskArgs = {
  input: DeleteAssetFromActivityTaskInput
}

export type MutationDeleteCommentArgs = {
  input: DeleteCommentInput
}

export type MutationDeleteCommentThreadArgs = {
  input: DeleteCommentThreadInput
}

export type MutationDeleteIntegrationArgs = {
  input: DeleteIntegrationInput
}

export type MutationDeleteIssueArgs = {
  issueId: Scalars['String']['input']
}

export type MutationDeleteMosaicPartnershipArgs = {
  input: DeletePartnershipInput
}

export type MutationDeleteOrganizationArgs = {
  organizationId: Scalars['String']['input']
}

export type MutationDeletePartnerSourceArgs = {
  partnerSourceId: Scalars['String']['input']
}

export type MutationDeletePartnershipArgs = {
  input: DeletePartnershipInput
}

export type MutationDeleteProgramArgs = {
  id: Scalars['String']['input']
}

export type MutationDeleteProjectArgs = {
  id: Scalars['String']['input']
}

export type MutationDeleteProjectTaskAttachmentArgs = {
  attachmentId: Scalars['String']['input']
}

export type MutationDeleteProjectUserArgs = {
  id: Scalars['String']['input']
}

export type MutationDeleteRuleArgs = {
  ruleId: Scalars['String']['input']
}

export type MutationDeleteSlackDataChannelArgs = {
  input: DeleteSlackDataChannelInput
}

export type MutationDeleteSpaceArgs = {
  id: Scalars['String']['input']
}

export type MutationDeleteSpaceUserArgs = {
  id: Scalars['String']['input']
}

export type MutationDeleteWorkflowNodeTemplateArgs = {
  id: Scalars['String']['input']
}

export type MutationDeleteWorkflowTemplateArgs = {
  id: Scalars['String']['input']
}

export type MutationEnrichmentQaArgs = {
  input: EnrichedFragmentQaInput
}

export type MutationFragmentQaArgs = {
  input: FragmentQaInput
}

export type MutationGetOrCreateLiveblocksRoomForAssetArgs = {
  input: CreateRoomForAssetInput
}

export type MutationIgnoreSuggestionsForAssetArgs = {
  assetId: Scalars['String']['input']
}

export type MutationInheritBankSubscriptionsArgs = {
  input: InheritBankSubscriptionsInput
}

export type MutationInitiatePartnerOnboardingArgs = {
  url: Scalars['String']['input']
}

export type MutationJoinSpaceArgs = {
  id: Scalars['String']['input']
}

export type MutationLeaveOrganizationArgs = {
  organizationId: Scalars['String']['input']
}

export type MutationLeaveProjectArgs = {
  id: Scalars['String']['input']
}

export type MutationLeaveSpaceArgs = {
  id: Scalars['String']['input']
}

export type MutationMarkOutOfScopeArgs = {
  input: MarkOutOfScopeInput
}

export type MutationOptOutOfSubscriptionArgs = {
  input: OptOutOfSubscriptionInput
}

export type MutationQueueSuggestionsJobForAssetArgs = {
  input: UpdateAssetWithSuggestionsInput
}

export type MutationRemoveProcessArgs = {
  id: Scalars['String']['input']
}

export type MutationRemoveProcessFieldArgs = {
  id: Scalars['String']['input']
}

export type MutationRemoveProcessMembersArgs = {
  id: Scalars['String']['input']
}

export type MutationReviewRequestArgs = {
  input: CreateReviewRequestInput
}

export type MutationRunSinglePartnerSourceArgs = {
  partnerSourceId: Scalars['String']['input']
}

export type MutationSaveProcessFieldsArgs = {
  input: Array<CreateProcessFieldInput>
  processId: Scalars['String']['input']
}

export type MutationSetProcessMembersArgs = {
  input: CreateProcessMembersInput
}

export type MutationSubmitActivityTaskArgs = {
  input: SubmitActivityTaskInput
}

export type MutationUnarchiveProjectTaskArgs = {
  taskId: Scalars['String']['input']
}

export type MutationUpdateActivityArgs = {
  id: Scalars['String']['input']
  input: UpdateActivityInput
}

export type MutationUpdateActivitySubscriptionArgs = {
  input: UpdateActivitySubscriptionInput
}

export type MutationUpdateActivityTaskAssigneeArgs = {
  input: UpdateActivityTaskAssigneeInput
}

export type MutationUpdateActivityTaskDueDateArgs = {
  input: UpdateActivityTaskDueDateInput
}

export type MutationUpdateActivityTaskStatusArgs = {
  input: UpdateActivityTaskStatusInput
}

export type MutationUpdateAssetArgs = {
  input: UpdateAssetInput
}

export type MutationUpdateCommentArgs = {
  input: UpdateCommentInput
}

export type MutationUpdateCommentThreadArgs = {
  input: UpdateCommentThreadInput
}

export type MutationUpdateIssueArgs = {
  input: UpdateIssueInput
}

export type MutationUpdateNotificationPreferencesProjectArgs = {
  input: UpdateNotificationPreferencesProjectInput
}

export type MutationUpdateNotificationPreferencesSpaceArgs = {
  input: UpdateNotificationPreferencesSpaceInput
}

export type MutationUpdateNotificationPreferencesUserArgs = {
  input: UpdateNotificationPreferencesUserInput
}

export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput
}

export type MutationUpdateOrganizationMetadataArgs = {
  input: UpdateOrganizationMetadataInput
}

export type MutationUpdateOrganizationUserArgs = {
  input: UpdateOrganizationUserInput
}

export type MutationUpdatePartnerSourceArgs = {
  input: UpdatePartnerSourceInput
}

export type MutationUpdatePartnershipArgs = {
  input: UpdatePartnershipInput
}

export type MutationUpdateProcessArgs = {
  input: UpdateProcessInput
}

export type MutationUpdateProcessFieldArgs = {
  input: UpdateProcessFieldInput
}

export type MutationUpdateProgramArgs = {
  id: Scalars['String']['input']
  input: UpdateProgramInput
}

export type MutationUpdateProjectArgs = {
  id: Scalars['String']['input']
  input: UpdateProjectInput
}

export type MutationUpdateProjectAssetArgs = {
  input: UpdateProjectAssetInput
}

export type MutationUpdateProjectTaskArgs = {
  input: UpdateProjectTaskInput
}

export type MutationUpdateProjectTaskStatusArgs = {
  input: UpdateProjectTaskStatusInput
}

export type MutationUpdateProjectUserArgs = {
  id: Scalars['String']['input']
  input: UpdateProjectUserInput
}

export type MutationUpdateProjectWorkflowTemplateArgs = {
  input: UpdateProjectWorkflowTemplateInput
}

export type MutationUpdateReviewRequestArgs = {
  input: UpdateReviewRequestInput
}

export type MutationUpdateRuleArgs = {
  input: UpdateRuleInput
}

export type MutationUpdateSlackDataChannelArgs = {
  input: UpdateSlackDataChannelInput
}

export type MutationUpdateSpaceArgs = {
  id: Scalars['String']['input']
  input: UpdateSpaceInput
}

export type MutationUpdateSpaceUserArgs = {
  id: Scalars['String']['input']
  input: UpdateSpaceUserInput
}

export type MutationUpdateSuggestionArgs = {
  input: ResolveSuggestionInput
}

export type MutationUpdateUserArgs = {
  input: UpdateUserInput
}

export type MutationUpdateUserRoleArgs = {
  input: UpdateUserRoleInput
}

export type MutationUpdateWorkflowNodeArgs = {
  input: UpdateWorkflowNodeInput
}

export type MutationUpdateWorkflowNodeTemplateArgs = {
  input: UpdateWorkflowNodeTemplateInput
}

export type MutationUpdateWorkflowTemplateArgs = {
  input: UpdateWorkflowTemplateInput
}

export type MutationUpdateWorkflowTemplateActiveStatusArgs = {
  active: Scalars['Boolean']['input']
  id: Scalars['String']['input']
}

/** The type of document */
export enum NarrativeDocumentType {
  AtWillEmploymentPolicy = 'AT_WILL_EMPLOYMENT_POLICY',
  BlogPostsRelatedItems = 'BLOG_POSTS_RELATED_ITEMS',
  ConsultingContractorAgreement = 'CONSULTING_CONTRACTOR_AGREEMENT',
  ConsumerLending = 'CONSUMER_LENDING',
  CustomerEmail = 'CUSTOMER_EMAIL',
  CustomerSupportScriptsMaterials = 'CUSTOMER_SUPPORT_SCRIPTS_MATERIALS',
  DebitCardAgreement = 'DEBIT_CARD_AGREEMENT',
  DistributionAgreement = 'DISTRIBUTION_AGREEMENT',
  EmployeeCodeOfConduct = 'EMPLOYEE_CODE_OF_CONDUCT',
  EmploymentAgreement = 'EMPLOYMENT_AGREEMENT',
  EqualOpportunityNondiscriminationPolicy = 'EQUAL_OPPORTUNITY_NONDISCRIMINATION_POLICY',
  EthicsPolicy = 'ETHICS_POLICY',
  Form_1A = 'FORM_1A',
  InternetAdvertisement = 'INTERNET_ADVERTISEMENT',
  LicensingAgreement = 'LICENSING_AGREEMENT',
  MarketingAndAdvertisingPolicy = 'MARKETING_AND_ADVERTISING_POLICY',
  Nda = 'NDA',
  Other = 'OTHER',
  PartnershipAgreement = 'PARTNERSHIP_AGREEMENT',
  PrivacyPolicy = 'PRIVACY_POLICY',
  PurchaseOrder = 'PURCHASE_ORDER',
  SalesAgreement = 'SALES_AGREEMENT',
  SalesScriptsEnablementMaterials = 'SALES_SCRIPTS_ENABLEMENT_MATERIALS',
  ServicingAndCollectionsPolicy = 'SERVICING_AND_COLLECTIONS_POLICY',
  SocialMediaAdvertisement = 'SOCIAL_MEDIA_ADVERTISEMENT',
  TechnologySocialMediaIpPolicy = 'TECHNOLOGY_SOCIAL_MEDIA_IP_POLICY',
  TravelExpensesPolicy = 'TRAVEL_EXPENSES_POLICY',
  WebsiteCopy = 'WEBSITE_COPY',
  WebsiteTermsOfUse = 'WEBSITE_TERMS_OF_USE',
  WorkplaceHealthSafety = 'WORKPLACE_HEALTH_SAFETY',
}

export type NotificationPreferencesProject = {
  __typename?: 'NotificationPreferencesProject'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['String']['output']
  muteAll: Scalars['Boolean']['output']
  notifyComments: NotificationPreferencesProjectOptions
  notifyEmail: Scalars['Boolean']['output']
  notifySlack: Scalars['Boolean']['output']
  notifyStatusFrequency?: Maybe<NotificationPreferencesProjectFrequency>
  notifyStatusUpdates: NotificationPreferencesProjectStatusOptions
  notifyTasks: NotificationPreferencesProjectOptions
  project: Project
  projectId: Scalars['String']['output']
  projectUser: ProjectUser
  updatedAt: Scalars['DateTime']['output']
  user: User
  userId: Scalars['String']['output']
}

export enum NotificationPreferencesProjectFrequency {
  OnceADay = 'ONCE_A_DAY',
  OnceAWeek = 'ONCE_A_WEEK',
}

export enum NotificationPreferencesProjectOptions {
  All = 'ALL',
  Me = 'ME',
  None = 'NONE',
}

export enum NotificationPreferencesProjectStatusOptions {
  All = 'ALL',
  Me = 'ME',
  None = 'NONE',
}

export type NotificationPreferencesSpace = {
  __typename?: 'NotificationPreferencesSpace'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['String']['output']
  muteAll: Scalars['Boolean']['output']
  notifyAddedToProject: Scalars['Boolean']['output']
  notifyEmail: Scalars['Boolean']['output']
  notifyProjectCreated: Scalars['Boolean']['output']
  notifySlack: Scalars['Boolean']['output']
  space: Space
  spaceId: Scalars['String']['output']
  spaceUser: SpaceUser
  updatedAt: Scalars['DateTime']['output']
  user: User
  userId: Scalars['String']['output']
}

export type NotificationPreferencesUser = {
  __typename?: 'NotificationPreferencesUser'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['String']['output']
  muteAll: Scalars['Boolean']['output']
  notifyEmail: Scalars['Boolean']['output']
  notifySlack: Scalars['Boolean']['output']
  updatedAt: Scalars['DateTime']['output']
  user: User
  userId: Scalars['String']['output']
}

export type OpenGraph = {
  __typename?: 'OpenGraph'
  content: Scalars['String']['output']
  property: Scalars['String']['output']
}

export type OptOutOfSubscriptionInput = {
  subscriptionId: Scalars['String']['input']
}

export type Organization = {
  __typename?: 'Organization'
  auditTrails: Array<AuditTrail>
  createdAt: Scalars['DateTime']['output']
  defaultUser?: Maybe<OrganizationUser>
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  disabledRuleStates?: Maybe<DisabledRuleStates>
  id: Scalars['String']['output']
  integrations: Array<Integration>
  logoFileKey?: Maybe<Scalars['String']['output']>
  logoUrl?: Maybe<Scalars['String']['output']>
  metadata?: Maybe<Scalars['JSON']['output']>
  name: Scalars['String']['output']
  organizationProducts: Array<OrganizationProduct>
  partnerships: Array<Partnership>
  processes?: Maybe<Array<Process>>
  programs: Array<Program>
  slackData: Array<Slack>
  spaces: Array<Space>
  sponsor?: Maybe<Partnership>
  teamSize?: Maybe<Scalars['String']['output']>
  teamType?: Maybe<Scalars['String']['output']>
  teamUrl: Scalars['String']['output']
  type: OrganizationType
  updatedAt: Scalars['DateTime']['output']
  users: Array<OrganizationUser>
  workflowTemplates?: Maybe<Array<WorkflowTemplate>>
}

export type OrganizationInvitation = {
  __typename?: 'OrganizationInvitation'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  email: Scalars['String']['output']
  expiresAt: Scalars['DateTime']['output']
  id: Scalars['String']['output']
  inviter: User
  organization: Organization
  organizationId: Scalars['String']['output']
  role: OrganizationUserRole
  status: OrganizationInvitationStatus
  uniqueId: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type OrganizationInvitationItem = {
  email: Scalars['String']['input']
  role: OrganizationUserRole
}

export enum OrganizationInvitationStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
}

export type OrganizationOnlineBusinessFragment = {
  __typename?: 'OrganizationOnlineBusinessFragment'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  /** Designates a version of this fragment based on its content */
  diffkey?: Maybe<Scalars['String']['output']>
  discriminator: Scalars['String']['output']
  id: Scalars['String']['output']
  metadata: OrganizationOnlineBusinessMetadata
  /** Designates where this fragment`s files are stored in S3 */
  storagekey?: Maybe<Scalars['String']['output']>
  /** Designates this fragment as the primary fragment representing this ukey */
  ukey?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type OrganizationOnlineBusinessMetadata = {
  __typename?: 'OrganizationOnlineBusinessMetadata'
  address?: Maybe<PostalAddress>
  ceo?: Maybe<Person>
  competitor?: Maybe<Array<CompetitorOrganization>>
  description: Scalars['String']['output']
  founder?: Maybe<Scalars['String']['output']>
  foundingDate?: Maybe<Scalars['String']['output']>
  industry?: Maybe<Array<Scalars['String']['output']>>
  legalNames: Array<Scalars['String']['output']>
  logo?: Maybe<Scalars['String']['output']>
  naics?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  numberOfEmployees?: Maybe<Scalars['Float']['output']>
  revenue?: Maybe<Scalars['String']['output']>
  sameAs?: Maybe<Array<Scalars['String']['output']>>
  sic?: Maybe<Scalars['String']['output']>
  stockSymbol?: Maybe<Scalars['String']['output']>
  telephone?: Maybe<Scalars['String']['output']>
  url: Scalars['String']['output']
}

export type OrganizationProduct = {
  __typename?: 'OrganizationProduct'
  activeAt?: Maybe<Scalars['DateTime']['output']>
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['String']['output']
  isActive: Scalars['Boolean']['output']
  organization: Organization
  organizationId: Scalars['String']['output']
  product: Product
  productId: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type OrganizationSearchFilters = {
  createdAtEndDate?: InputMaybe<Scalars['DateTime']['input']>
  createdAtStartDate?: InputMaybe<Scalars['DateTime']['input']>
  deletedAtEndDate?: InputMaybe<Scalars['DateTime']['input']>
  deletedAtStartDate?: InputMaybe<Scalars['DateTime']['input']>
  ids?: InputMaybe<Array<Scalars['String']['input']>>
  name?: InputMaybe<Scalars['String']['input']>
  teamSize?: InputMaybe<Scalars['String']['input']>
  teamType?: InputMaybe<Scalars['String']['input']>
  teamUrl?: InputMaybe<Scalars['String']['input']>
  updatedAtEndDate?: InputMaybe<Scalars['DateTime']['input']>
  updatedAtStartDate?: InputMaybe<Scalars['DateTime']['input']>
  withDeleted?: InputMaybe<Scalars['Boolean']['input']>
}

export enum OrganizationType {
  Bank = 'BANK',
  Fintech = 'FINTECH',
}

export type OrganizationUser = {
  __typename?: 'OrganizationUser'
  createdAt: Scalars['DateTime']['output']
  defaultForOrganization?: Maybe<Organization>
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['String']['output']
  organization: Organization
  organizationId: Scalars['String']['output']
  role: OrganizationUserRole
  title: OrganizationUserTitle
  updatedAt: Scalars['DateTime']['output']
  user: User
  userId: Scalars['String']['output']
}

export enum OrganizationUserRole {
  Admin = 'ADMIN',
  Guest = 'GUEST',
  Member = 'MEMBER',
}

export enum OrganizationUserTitle {
  Cco = 'CCO',
  Ceo = 'CEO',
  Cto = 'CTO',
  Other = 'OTHER',
  PrimaryContact = 'PRIMARY_CONTACT',
}

export type Pagination = {
  page: Scalars['Int']['input']
  take: Scalars['Int']['input']
}

export type PaginationMeta = {
  __typename?: 'PaginationMeta'
  currentPage: Scalars['Int']['output']
  itemsPerPage: Scalars['Int']['output']
  totalItems: Scalars['Int']['output']
  totalPages: Scalars['Int']['output']
}

export type PartnerOnboardingOutput = {
  __typename?: 'PartnerOnboardingOutput'
  licences: Array<MsbLicenceFragment>
  links: Array<CategorizedLink>
  notableNews: Array<GoogleNewsSearchResultFragment>
  organization: OrganizationOnlineBusinessFragment
}

/** The product type offered by a partner organization */
export enum PartnerProductType {
  ApAutomation = 'AP_AUTOMATION',
  Charge = 'CHARGE',
  Checking = 'CHECKING',
  Credit = 'CREDIT',
  Debit = 'DEBIT',
  Deposit = 'DEPOSIT',
  Fleet = 'FLEET',
  InstallmentLoan = 'INSTALLMENT_LOAN',
  Payroll = 'PAYROLL',
  Prepaid = 'PREPAID',
  SecuredCharge = 'SECURED_CHARGE',
}

/** The program type of a partner organization */
export enum PartnerProgramType {
  Commercial = 'COMMERCIAL',
  Consumer = 'CONSUMER',
}

export type PartnerSource = {
  __typename?: 'PartnerSource'
  config: Scalars['JSON']['output']
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['String']['output']
  name: SourceName
  partnership: Partnership
  partnershipId: Scalars['String']['output']
  syncedAt?: Maybe<Scalars['DateTime']['output']>
  type: SourceType
  updatedAt: Scalars['DateTime']['output']
}

export type PartnerSourceEnrichedFragment = {
  __typename?: 'PartnerSourceEnrichedFragment'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  enrichedFragment: EnrichedFragment
  enrichedFragmentId: Scalars['String']['output']
  id: Scalars['String']['output']
  partnerSource: PartnerSource
  partnerSourceId: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type PartnerSourceEnrichedFragmentItemOutput = {
  __typename?: 'PartnerSourceEnrichedFragmentItemOutput'
  createdAt: Scalars['DateTime']['output']
  data?: Maybe<FragmentMetadataUnion>
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  enrichedData?: Maybe<EnrichedFragmentMetadataUnion>
  id: Scalars['String']['output']
  partnerSource: PartnerSource
  type: Scalars['String']['output']
  ukey: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type PartnerSourceFragmentItemOutput = {
  __typename?: 'PartnerSourceFragmentItemOutput'
  createdAt: Scalars['DateTime']['output']
  data?: Maybe<FragmentMetadataUnion>
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['String']['output']
  partnerSource: PartnerSource
  type: Scalars['String']['output']
  ukey: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type PartnerSourceWithCounts = {
  __typename?: 'PartnerSourceWithCounts'
  config: Scalars['JSON']['output']
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  fragmentCount: Scalars['Float']['output']
  id: Scalars['String']['output']
  issueCount: Scalars['Float']['output']
  name: SourceName
  partnership: Partnership
  partnershipId: Scalars['String']['output']
  syncedAt?: Maybe<Scalars['DateTime']['output']>
  type: SourceType
  updatedAt: Scalars['DateTime']['output']
}

export type Partnership = {
  __typename?: 'Partnership'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  healthScore?: Maybe<Scalars['Int']['output']>
  id: Scalars['String']['output']
  logoUrl?: Maybe<Scalars['String']['output']>
  onboardingFinishTime?: Maybe<Scalars['DateTime']['output']>
  onboardingStartTime?: Maybe<Scalars['DateTime']['output']>
  owner?: Maybe<User>
  ownerId?: Maybe<Scalars['String']['output']>
  partnerOrganization: Organization
  partnerOrganizationId: Scalars['String']['output']
  program: Program
  programId: Scalars['String']['output']
  projectTasks: Array<ProjectTask>
  sponsorOrganization: Organization
  sponsorOrganizationId: Scalars['String']['output']
  stage: PartnershipStage
  updatedAt: Scalars['DateTime']['output']
}

export type PartnershipProjectTaskInput = {
  category?: InputMaybe<RequirementCategory>
  partnershipId: Scalars['String']['input']
}

/** The overall step in the progress of a partners onboarding */
export enum PartnershipStage {
  Approved = 'APPROVED',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  DecisionPending = 'DECISION_PENDING',
  Declined = 'DECLINED',
  Invited = 'INVITED',
  InReview = 'IN_REVIEW',
  Live = 'LIVE',
  OnHold = 'ON_HOLD',
  Paused = 'PAUSED',
  Qa = 'QA',
  Started = 'STARTED',
  Submitted = 'SUBMITTED',
}

export type Person = {
  __typename?: 'Person'
  name: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type PostalAddress = {
  __typename?: 'PostalAddress'
  addressCountry: Scalars['String']['output']
  addressLocality: Scalars['String']['output']
  addressRegion: Scalars['String']['output']
  postalCode: Scalars['String']['output']
  streetAddress: Scalars['String']['output']
}

export type PresignedUrl = {
  __typename?: 'PresignedUrl'
  fileKey: Scalars['String']['output']
  mimeType?: Maybe<Scalars['String']['output']>
  signedUrl: Scalars['String']['output']
}

export type Process = {
  __typename?: 'Process'
  access: ProcessAccess
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  description: Scalars['String']['output']
  fields?: Maybe<Array<ProcessField>>
  id: Scalars['String']['output']
  members?: Maybe<Array<ProcessMember>>
  organization: Organization
  space: Space
  status: ProcessStatus
  title: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

/** Access level of a process */
export enum ProcessAccess {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type ProcessField = {
  __typename?: 'ProcessField'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  description: Scalars['String']['output']
  id: Scalars['String']['output']
  isRequired: Scalars['Boolean']['output']
  options?: Maybe<Scalars['JSON']['output']>
  process: Process
  title: Scalars['String']['output']
  type: FormFieldType
  updatedAt: Scalars['DateTime']['output']
}

export type ProcessMember = {
  __typename?: 'ProcessMember'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['String']['output']
  process: Process
  updatedAt: Scalars['DateTime']['output']
  user: User
}

/** Statuses of a process */
export enum ProcessStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
}

export type Product = {
  __typename?: 'Product'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  description: Scalars['String']['output']
  id: Scalars['String']['output']
  name: ProductType
  updatedAt: Scalars['DateTime']['output']
}

export enum ProductType {
  MarketingCompliance = 'MARKETING_COMPLIANCE',
  Mosaic = 'MOSAIC',
  PartnerOnboarding = 'PARTNER_ONBOARDING',
}

export type Program = {
  __typename?: 'Program'
  createdAt: Scalars['DateTime']['output']
  createdBy?: Maybe<User>
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  isActive: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  organization: Organization
  organizationId: Scalars['String']['output']
  partnerships: Array<Partnership>
  productType: PartnerProductType
  programRequirements: Array<ProgramRequirement>
  programType: PartnerProgramType
  updatedAt: Scalars['DateTime']['output']
}

export type ProgramRequirement = {
  __typename?: 'ProgramRequirement'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['String']['output']
  program: Program
  programId: Scalars['String']['output']
  requirement: Requirement
  requirementId: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type Project = {
  __typename?: 'Project'
  archivedAt?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  createdBy?: Maybe<User>
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  icon: Scalars['String']['output']
  iconColor: Scalars['String']['output']
  iconUrl?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  isPublic: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  notificatonPreferences: Array<NotificationPreferencesProject>
  projectUrl: Scalars['String']['output']
  space: Space
  tasks?: Maybe<Array<ProjectTask>>
  updatedAt: Scalars['DateTime']['output']
  users: Array<ProjectUser>
  workflowTemplates: Array<WorkflowTemplate>
}

export type ProjectInvitation = {
  __typename?: 'ProjectInvitation'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  email: Scalars['String']['output']
  id: Scalars['String']['output']
  inviter: User
  project: Project
  role: ProjectUserRole
  status: ProjectInvitationStatus
  uniqueId: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export enum ProjectInvitationStatus {
  Accepted = 'ACCEPTED',
  Canceled = 'CANCELED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
}

export type ProjectInvitationsItem = {
  email: Scalars['String']['input']
  role: ProjectUserRole
}

export type ProjectInviteEmailItem = {
  email: Scalars['String']['input']
}

export type ProjectSearchFilters = {
  createdAtEndDate?: InputMaybe<Scalars['DateTime']['input']>
  createdAtStartDate?: InputMaybe<Scalars['DateTime']['input']>
  createdById?: InputMaybe<Scalars['String']['input']>
  deletedAtEndDate?: InputMaybe<Scalars['DateTime']['input']>
  deletedAtStartDate?: InputMaybe<Scalars['DateTime']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  ids?: InputMaybe<Array<Scalars['String']['input']>>
  isPublic?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  organizationIds?: InputMaybe<Array<Scalars['String']['input']>>
  projectUrl?: InputMaybe<Scalars['String']['input']>
  spaceIds?: InputMaybe<Array<Scalars['String']['input']>>
  updatedAtEndDate?: InputMaybe<Scalars['DateTime']['input']>
  updatedAtStartDate?: InputMaybe<Scalars['DateTime']['input']>
  withDeleted?: InputMaybe<Scalars['Boolean']['input']>
}

export type ProjectTask = {
  __typename?: 'ProjectTask'
  archivedAt?: Maybe<Scalars['DateTime']['output']>
  asset?: Maybe<Asset>
  assignee?: Maybe<User>
  attachments?: Maybe<Array<ProjectTaskAttachment>>
  comments?: Maybe<Array<ProjectTaskComment>>
  createdAt: Scalars['DateTime']['output']
  createdBy?: Maybe<User>
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  dueDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  partnership?: Maybe<Partnership>
  partnershipId?: Maybe<Scalars['String']['output']>
  priority: ProjectTaskPriority
  project: Project
  projectId: Scalars['String']['output']
  requirement?: Maybe<Requirement>
  requirementId?: Maybe<Scalars['String']['output']>
  reviewer?: Maybe<User>
  status: ProjectTaskStatus
  updatedAt: Scalars['DateTime']['output']
  workflow: Workflow
}

export type ProjectTaskAttachment = {
  __typename?: 'ProjectTaskAttachment'
  createdAt: Scalars['DateTime']['output']
  createdBy?: Maybe<User>
  createdById: Scalars['String']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  fileKey: Scalars['String']['output']
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  taskId: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  url?: Maybe<Scalars['String']['output']>
}

export type ProjectTaskComment = {
  __typename?: 'ProjectTaskComment'
  author: User
  content: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['String']['output']
  mentionedUsers?: Maybe<Array<User>>
  task: ProjectTask
  updatedAt: Scalars['DateTime']['output']
}

export enum ProjectTaskPriority {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
  None = 'None',
}

export enum ProjectTaskStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Overdue = 'OVERDUE',
  Pending = 'PENDING',
}

export type ProjectUser = {
  __typename?: 'ProjectUser'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['String']['output']
  notificationPreferences: NotificationPreferencesProject
  project: Project
  role: ProjectUserRole
  updatedAt: Scalars['DateTime']['output']
  user: User
}

export enum ProjectUserRole {
  Admin = 'ADMIN',
  Guest = 'GUEST',
  Member = 'MEMBER',
}

export type Query = {
  __typename?: 'Query'
  activity: Activity
  activityTask: ActivityTask
  activityTaskAttachmentDownloadUrl: PresignedUrl
  activityTaskAttachments: Array<ActivityTaskAttachment>
  aiUser: User
  analyzeRemediationInPeriod: AnalyzeRemediationInPeriodOutput
  archivedProjectTasks: Array<ProjectTask>
  assetDownloadUrl: PresignedUrl
  assetEdits: Array<AssetEdit>
  assetUploadUrl: PresignedUrl
  assetVersionUpdateUrl: PresignedUrl
  assetVersions: Array<_AssetVersion>
  assetWithVersions: Array<Asset>
  assets: Array<Asset>
  checkOrganizationAccessToProduct: Scalars['Boolean']['output']
  commentThread: CommentThread
  commentThreads: Array<CommentThread>
  compareRemediationInPeriods: CompareRemediationsInPeriodsOutput
  computeTextDifferences: Array<Array<ChangeType>>
  countOverdueTasks: Scalars['Float']['output']
  countTasksByStatusForOrganization: Array<ActivityTaskStatusCountOutput>
  currentAssetVersions: Array<AssetVersion>
  documentAssetRevision: DocumentAssetRevision
  documentAssetRevisions: Array<DocumentAssetRevision>
  enrichedTiktokPostFragment?: Maybe<EnrichedTiktokPostFragment>
  enrichedTiktokPostFragments: Array<EnrichedTiktokPostFragment>
  filterAuditTrail: Array<AuditTrail>
  filterAuditTrailByEntityIds: Array<AuditTrail>
  getActiveActivitiesForOrganization: Array<Activity>
  getActiveNodeForTask?: Maybe<WorkflowNode>
  getActivities: Array<Activity>
  getActivitiesForOrganization: Array<Activity>
  getActivitiesReport: MosaicActivityReport
  getActivityReport: MosaicActivityReport
  getActivitySubscriptionsForActivity: Array<ActivitySubscription>
  /** Get activity tasks by specified filters (organizationId, subscriptionId, activityId) */
  getActivityTasksByFilter: Array<ActivityTask>
  /** Get a count of activity tasks statuses, optionally scoped to an org */
  getActivityTasksByStatusCount: Array<ActivityTaskCountByStatus>
  getActivityTasksCreatedInOrg: Array<ActivityTask>
  getActivityTasksForActivity: Array<ActivityTask>
  getActivityTasksForFintech: Array<ActivityTask>
  getActivityTasksForPartnerTodo: Array<ActivityTask>
  getActivityTasksForSubscriber: Array<ActivityTask>
  getActivityTasksForSubscription: Array<ActivityTask>
  getActivityTasksForTodo: Array<ActivityTask>
  getAdminBankReport: MosaicAdminReport
  getAllEnrichedFragmentsForPartnershipId: Array<PartnerSourceEnrichedFragmentItemOutput>
  getAllForPartnershipId: Array<PartnerSource>
  getAllFragmentsForPartnershipId: Array<PartnerSourceFragmentItemOutput>
  getAllFragmentsPartnerSourceId: Array<PartnerSourceEnrichedFragmentItemOutput>
  getAllMosaicPartnerships: Array<MosaicPartnership>
  getAllWithCountsForPartnershipId: Array<PartnerSourceWithCounts>
  getBankReport: MosaicBankReport
  getEnrichedFragmentByFragmentId: Array<PartnerSourceEnrichedFragmentItemOutput>
  getFintechReport: MosaicFintechReport
  getFragment: Array<PartnerSourceFragmentItemOutput>
  getMosaicPartnershipsForOrganization: Array<MosaicPartnership>
  getOrganizationByTeamUrl?: Maybe<Organization>
  getPartnerSourceById: PartnerSource
  getPartnershipsForOrganization: Array<Partnership>
  getProductsByOrganization: Array<OrganizationProduct>
  getProgramsForOrganization: Array<Program>
  getProjectTaskAuditTrailByWorkflowTemplate: Array<AuditTrail>
  getReport: MosaicReportOutput
  getSponsorOrg: Partnership
  getSubscriptionsForBank: Array<ActivitySubscription>
  getSubscriptionsForOrganization: Array<ActivitySubscription>
  getSubscriptionsInheritedForFintechInBank: Array<ActivitySubscription>
  getSubscriptionsInheritedForFintechsInBank: Array<ActivitySubscription>
  getTasksForPartnership: Array<ProjectTask>
  getTasksForUserAndOrganization: Array<ProjectTask>
  getUserAutocomplete: Array<User>
  getUsersByExternal: Array<User>
  googleNewsSearchResultFragment: Array<GoogleNewsSearchResultFragment>
  integration: Integration
  integrations: Array<Integration>
  issue: IssueItemOutput
  issues: Array<IssueItemOutput>
  issuesByPartnership: Array<IssueItemOutput>
  logoUploadUrl: PresignedUrl
  me: User
  notificationPreferencesProject?: Maybe<NotificationPreferencesProject>
  notificationPreferencesProjectsByProject: Array<NotificationPreferencesProject>
  notificationPreferencesProjectsByUser: Array<NotificationPreferencesProject>
  notificationPreferencesSpace?: Maybe<NotificationPreferencesSpace>
  notificationPreferencesSpacesBySpace: Array<NotificationPreferencesSpace>
  notificationPreferencesSpacesByUser: Array<NotificationPreferencesSpace>
  notificationPreferencesUser: NotificationPreferencesUser
  organization?: Maybe<Organization>
  organizationForMosaicAdmin?: Maybe<Organization>
  organizationInvitation: OrganizationInvitation
  organizationInvitations: Array<OrganizationInvitation>
  organizationOnlineBusinessFragments: Array<OrganizationOnlineBusinessFragment>
  organizationProjects: Array<Project>
  organizations: Array<Organization>
  organizationsByType: Array<Organization>
  originalFileDownloadUrl: PresignedUrl
  process: Process
  processField: ProcessField
  processFields: Array<ProcessField>
  processMember: ProcessMember
  processMembersForProcess: Array<ProcessMember>
  processes: Array<Process>
  program: Array<Program>
  project: Project
  projectByUrl: Project
  projectInvitation?: Maybe<ProjectInvitation>
  projectInvitationsForProject: Array<ProjectInvitation>
  projectInvitationsForUser: Array<ProjectInvitation>
  projectTask: ProjectTask
  projectTaskAttachmentDownloadUrl: PresignedUrl
  projectTaskAttachments: Array<ProjectTaskAttachment>
  projectTasks: Array<ProjectTask>
  projectTasksAssignedInOrg: Array<ProjectTask>
  projectTasksCreatedInOrg: Array<ProjectTask>
  projectUser: ProjectUser
  projectUsers: Array<ProjectUser>
  projects: Array<Project>
  projectsByUserInOrg: Array<Project>
  requirementCategories: Array<RequirementCategorySummary>
  resolveSuggestion: Scalars['Boolean']['output']
  reviewRequests: Array<ReviewRequest>
  rule: Rule
  rulesByPartnerSource: Array<Rule>
  search: SearchResult
  sentOrganizationInvitations: Array<OrganizationInvitation>
  sentProjectInvitations: Array<ProjectInvitation>
  sentSpaceInvitations: Array<SpaceInvitation>
  sentUserInvitations: Array<UserInvitation>
  slackChannel: Array<SlackChannel>
  slackChannels: Array<SlackChannel>
  slackDataChannel: SlackDataChannel
  slackTeamInfo: SlackTeamInfo
  slackUsers: Array<SlackUser>
  space: Space
  spaceByUrl: Space
  spaceInvitation?: Maybe<SpaceInvitation>
  spaceInvitations: Array<SpaceInvitation>
  spaceInvitationsForSpace: Array<SpaceInvitation>
  spaceUser: SpaceUser
  spaceUsers: Array<SpaceUser>
  spaces: Array<Space>
  suggestion: Suggestion
  suggestions: Array<Suggestion>
  userInvitation: UserInvitation
  userInvitations: Array<UserInvitation>
  workflowNodeTemplates?: Maybe<Array<WorkflowNodeTemplate>>
  workflowTemplate: WorkflowTemplate
  workflowTemplates: Array<WorkflowTemplate>
}

export type QueryActivityArgs = {
  id: Scalars['String']['input']
}

export type QueryActivityTaskArgs = {
  taskId: Scalars['String']['input']
}

export type QueryActivityTaskAttachmentDownloadUrlArgs = {
  attachmentId: Scalars['String']['input']
}

export type QueryActivityTaskAttachmentsArgs = {
  taskId: Scalars['String']['input']
}

export type QueryAnalyzeRemediationInPeriodArgs = {
  input: AnalyzeRemediationInPeriodInput
}

export type QueryArchivedProjectTasksArgs = {
  projectId: Scalars['String']['input']
}

export type QueryAssetDownloadUrlArgs = {
  input: GetAssetDownloadUrlInput
}

export type QueryAssetEditsArgs = {
  assetId: Scalars['String']['input']
}

export type QueryAssetUploadUrlArgs = {
  input: GetAssetUploadUrlInput
}

export type QueryAssetVersionUpdateUrlArgs = {
  id: Scalars['String']['input']
  mimeType: MimeType
}

export type QueryAssetVersionsArgs = {
  input: GetAssetDownloadUrlInput
}

export type QueryAssetWithVersionsArgs = {
  input: GetAssetDownloadUrlInput
}

export type QueryCheckOrganizationAccessToProductArgs = {
  organizationId: Scalars['String']['input']
  productId: Scalars['String']['input']
}

export type QueryCommentThreadArgs = {
  input: GetCommentThreadInput
}

export type QueryCommentThreadsArgs = {
  input: GetCommentThreadsInput
}

export type QueryCompareRemediationInPeriodsArgs = {
  input: AnalyzeRemediationInPeriodInput
}

export type QueryComputeTextDifferencesArgs = {
  fragmentId1: Scalars['String']['input']
  fragmentId2: Scalars['String']['input']
}

export type QueryCountOverdueTasksArgs = {
  input: CountOverdueTasksInput
}

export type QueryCountTasksByStatusForOrganizationArgs = {
  input: CountTasksByStatusInput
}

export type QueryCurrentAssetVersionsArgs = {
  input: GetAssetDownloadUrlInput
}

export type QueryDocumentAssetRevisionArgs = {
  input: GetDocumentAssetRevisionInput
}

export type QueryDocumentAssetRevisionsArgs = {
  input: GetDocumentAssetRevisionsInput
}

export type QueryEnrichedTiktokPostFragmentArgs = {
  enrichedFragmentId: Scalars['String']['input']
}

export type QueryFilterAuditTrailArgs = {
  input: FilterAuditTrailInput
}

export type QueryFilterAuditTrailByEntityIdsArgs = {
  input: FilterAuditTrailByEntityIdsInput
}

export type QueryGetActiveActivitiesForOrganizationArgs = {
  orgId: Scalars['String']['input']
}

export type QueryGetActiveNodeForTaskArgs = {
  taskId: Scalars['String']['input']
}

export type QueryGetActivitiesForOrganizationArgs = {
  input: GetActivitiesForOrganizationInput
}

export type QueryGetActivitiesReportArgs = {
  input: CreateMosaicActivityReportInput
}

export type QueryGetActivityReportArgs = {
  input: CreateMosaicActivityReportInput
}

export type QueryGetActivitySubscriptionsForActivityArgs = {
  id: Scalars['String']['input']
  organizationType?: InputMaybe<OrganizationType>
  sourceBankId?: InputMaybe<Scalars['String']['input']>
}

export type QueryGetActivityTasksByFilterArgs = {
  input: GetActivityTasksInput
}

export type QueryGetActivityTasksByStatusCountArgs = {
  input: ActivityStatusCountInput
}

export type QueryGetActivityTasksCreatedInOrgArgs = {
  id: Scalars['String']['input']
}

export type QueryGetActivityTasksForActivityArgs = {
  id: Scalars['String']['input']
}

export type QueryGetActivityTasksForFintechArgs = {
  input: GetActivityTasksInput
}

export type QueryGetActivityTasksForPartnerTodoArgs = {
  input: GetActivityTasksInput
}

export type QueryGetActivityTasksForSubscriberArgs = {
  input: GetActivityTasksInput
}

export type QueryGetActivityTasksForSubscriptionArgs = {
  id: Scalars['String']['input']
}

export type QueryGetActivityTasksForTodoArgs = {
  input: GetActivityTasksInput
}

export type QueryGetAllEnrichedFragmentsForPartnershipIdArgs = {
  input: GetFragmentsForPartnershipInput
}

export type QueryGetAllForPartnershipIdArgs = {
  partnershipId: Scalars['String']['input']
}

export type QueryGetAllFragmentsForPartnershipIdArgs = {
  input: GetFragmentsForPartnershipInput
}

export type QueryGetAllFragmentsPartnerSourceIdArgs = {
  partnerSourceId: Scalars['String']['input']
}

export type QueryGetAllWithCountsForPartnershipIdArgs = {
  partnershipId: Scalars['String']['input']
}

export type QueryGetBankReportArgs = {
  input: CreateMosaicBankReportInput
}

export type QueryGetEnrichedFragmentByFragmentIdArgs = {
  fragmentId: Scalars['String']['input']
}

export type QueryGetFintechReportArgs = {
  input: CreateMosaicFintechReportInput
}

export type QueryGetFragmentArgs = {
  fragmentId: Scalars['String']['input']
}

export type QueryGetMosaicPartnershipsForOrganizationArgs = {
  input: GetSponsorshipsInput
}

export type QueryGetOrganizationByTeamUrlArgs = {
  teamUrl: Scalars['String']['input']
}

export type QueryGetPartnerSourceByIdArgs = {
  partnerSourceId: Scalars['String']['input']
}

export type QueryGetPartnershipsForOrganizationArgs = {
  input: GetSponsorshipsInput
}

export type QueryGetProductsByOrganizationArgs = {
  organizationId: Scalars['String']['input']
}

export type QueryGetProgramsForOrganizationArgs = {
  input: GetProgramsForOrganizationInput
}

export type QueryGetProjectTaskAuditTrailByWorkflowTemplateArgs = {
  organizationId: Scalars['String']['input']
  workflowTemplateId: Scalars['String']['input']
}

export type QueryGetReportArgs = {
  input: CreateMosaicReportInput
}

export type QueryGetSponsorOrgArgs = {
  input: GetSponsorInput
}

export type QueryGetSubscriptionsForBankArgs = {
  bankId: Scalars['String']['input']
}

export type QueryGetSubscriptionsForOrganizationArgs = {
  orgId: Scalars['String']['input']
}

export type QueryGetSubscriptionsInheritedForFintechInBankArgs = {
  bankId: Scalars['String']['input']
  fintechId: Scalars['String']['input']
}

export type QueryGetSubscriptionsInheritedForFintechsInBankArgs = {
  bankId: Scalars['String']['input']
}

export type QueryGetTasksForPartnershipArgs = {
  input: PartnershipProjectTaskInput
}

export type QueryGetTasksForUserAndOrganizationArgs = {
  organizationId: Scalars['String']['input']
}

export type QueryGetUserAutocompleteArgs = {
  input: GetUserAutocompleteInput
}

export type QueryGetUsersByExternalArgs = {
  input: GetUsersExternalInput
}

export type QueryIntegrationArgs = {
  integrationProviderId: Scalars['String']['input']
  organizationId: Scalars['String']['input']
}

export type QueryIntegrationsArgs = {
  organizationId: Scalars['String']['input']
}

export type QueryIssueArgs = {
  id: Scalars['String']['input']
}

export type QueryIssuesArgs = {
  partnerSourceId: Scalars['String']['input']
}

export type QueryIssuesByPartnershipArgs = {
  partnershipId: Scalars['String']['input']
}

export type QueryLogoUploadUrlArgs = {
  input: GetLogoUploadUrlInput
}

export type QueryNotificationPreferencesProjectArgs = {
  projectId: Scalars['String']['input']
  userId: Scalars['String']['input']
}

export type QueryNotificationPreferencesProjectsByProjectArgs = {
  projectId: Scalars['String']['input']
}

export type QueryNotificationPreferencesProjectsByUserArgs = {
  userId: Scalars['String']['input']
}

export type QueryNotificationPreferencesSpaceArgs = {
  spaceId: Scalars['String']['input']
  userId: Scalars['String']['input']
}

export type QueryNotificationPreferencesSpacesBySpaceArgs = {
  spaceId: Scalars['String']['input']
}

export type QueryNotificationPreferencesSpacesByUserArgs = {
  userId: Scalars['String']['input']
}

export type QueryNotificationPreferencesUserArgs = {
  userId: Scalars['String']['input']
}

export type QueryOrganizationArgs = {
  input?: InputMaybe<GetOrganizationInput>
}

export type QueryOrganizationForMosaicAdminArgs = {
  input?: InputMaybe<GetOrganizationInput>
}

export type QueryOrganizationInvitationArgs = {
  uniqueId: Scalars['String']['input']
}

export type QueryOrganizationProjectsArgs = {
  organizationId: Scalars['String']['input']
}

export type QueryOrganizationsByTypeArgs = {
  type: Scalars['String']['input']
}

export type QueryOriginalFileDownloadUrlArgs = {
  input: GetAssetDownloadUrlInput
}

export type QueryProcessArgs = {
  id: Scalars['String']['input']
}

export type QueryProcessFieldArgs = {
  id: Scalars['String']['input']
}

export type QueryProcessFieldsArgs = {
  processId: Scalars['String']['input']
}

export type QueryProcessMemberArgs = {
  id: Scalars['String']['input']
}

export type QueryProcessMembersForProcessArgs = {
  processId: Scalars['String']['input']
}

export type QueryProcessesArgs = {
  organizationId: Scalars['String']['input']
}

export type QueryProgramArgs = {
  id: Scalars['String']['input']
}

export type QueryProjectArgs = {
  id: Scalars['String']['input']
}

export type QueryProjectByUrlArgs = {
  projectUrl: Scalars['String']['input']
  spaceId: Scalars['String']['input']
}

export type QueryProjectInvitationArgs = {
  uniqueId: Scalars['String']['input']
}

export type QueryProjectInvitationsForProjectArgs = {
  input: GetProjectInvitationsForProjectInput
}

export type QueryProjectInvitationsForUserArgs = {
  input: GetProjectInvitationsForUserInput
}

export type QueryProjectTaskArgs = {
  taskId: Scalars['String']['input']
}

export type QueryProjectTaskAttachmentDownloadUrlArgs = {
  attachmentId: Scalars['String']['input']
}

export type QueryProjectTaskAttachmentsArgs = {
  taskId: Scalars['String']['input']
}

export type QueryProjectTasksArgs = {
  projectId: Scalars['String']['input']
}

export type QueryProjectTasksAssignedInOrgArgs = {
  organizationId: Scalars['String']['input']
}

export type QueryProjectTasksCreatedInOrgArgs = {
  organizationId: Scalars['String']['input']
}

export type QueryProjectUserArgs = {
  id: Scalars['String']['input']
}

export type QueryProjectUsersArgs = {
  projectId: Scalars['String']['input']
}

export type QueryProjectsArgs = {
  spaceId: Scalars['String']['input']
}

export type QueryProjectsByUserInOrgArgs = {
  organizationId: Scalars['String']['input']
}

export type QueryRequirementCategoriesArgs = {
  partnershipId: Scalars['String']['input']
}

export type QueryResolveSuggestionArgs = {
  input: ResolveSuggestionInput
}

export type QueryReviewRequestsArgs = {
  input: GetAssetReviewRequestsInput
}

export type QueryRuleArgs = {
  id: Scalars['String']['input']
}

export type QueryRulesByPartnerSourceArgs = {
  partnerSourceId: Scalars['String']['input']
}

export type QuerySearchArgs = {
  input: SearchFilters
  organizationId: Scalars['String']['input']
  pagination: Pagination
}

export type QuerySentOrganizationInvitationsArgs = {
  organizationId: Scalars['String']['input']
}

export type QuerySentProjectInvitationsArgs = {
  projectId: Scalars['String']['input']
}

export type QuerySentSpaceInvitationsArgs = {
  spaceId: Scalars['String']['input']
}

export type QuerySentUserInvitationsArgs = {
  userId: Scalars['String']['input']
}

export type QuerySlackChannelArgs = {
  organizationId: Scalars['String']['input']
}

export type QuerySlackChannelsArgs = {
  organizationId: Scalars['String']['input']
}

export type QuerySlackDataChannelArgs = {
  collection: Scalars['String']['input']
  objectId: Scalars['String']['input']
  organizationId: Scalars['String']['input']
}

export type QuerySlackTeamInfoArgs = {
  organizationId: Scalars['String']['input']
}

export type QuerySlackUsersArgs = {
  organizationId: Scalars['String']['input']
}

export type QuerySpaceArgs = {
  id: Scalars['String']['input']
}

export type QuerySpaceByUrlArgs = {
  organizationId: Scalars['String']['input']
  spaceUrl: Scalars['String']['input']
}

export type QuerySpaceInvitationArgs = {
  uniqueId: Scalars['String']['input']
}

export type QuerySpaceInvitationsArgs = {
  status: Scalars['String']['input']
}

export type QuerySpaceInvitationsForSpaceArgs = {
  input: GetSpaceInvitationsForSpaceInput
}

export type QuerySpaceUserArgs = {
  id: Scalars['String']['input']
}

export type QuerySpaceUsersArgs = {
  spaceId: Scalars['String']['input']
}

export type QuerySpacesArgs = {
  organizationId: Scalars['String']['input']
}

export type QuerySuggestionArgs = {
  assetId: Scalars['String']['input']
  suggestionId: Scalars['String']['input']
}

export type QuerySuggestionsArgs = {
  input: GetSuggestionsInput
}

export type QueryUserInvitationArgs = {
  uniqueId: Scalars['String']['input']
}

export type QueryWorkflowNodeTemplatesArgs = {
  templateId: Scalars['String']['input']
}

export type QueryWorkflowTemplateArgs = {
  id: Scalars['String']['input']
}

export type QueryWorkflowTemplatesArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  archived?: InputMaybe<Scalars['Boolean']['input']>
  onlyConstructed: Scalars['Boolean']['input']
  organizationId: Scalars['String']['input']
  projectId?: InputMaybe<Scalars['String']['input']>
}

export type RedditPost = {
  __typename?: 'RedditPost'
  body?: Maybe<Scalars['String']['output']>
  category?: Maybe<Scalars['String']['output']>
  comments?: Maybe<Array<RedditSingleScrapeResult>>
  communityName: Scalars['String']['output']
  dataType: Scalars['String']['output']
  flair?: Maybe<Scalars['String']['output']>
  html?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  isAd?: Maybe<Scalars['Boolean']['output']>
  isVideo?: Maybe<Scalars['Boolean']['output']>
  numberOfComments?: Maybe<Scalars['Float']['output']>
  numberOfreplies?: Maybe<Scalars['Float']['output']>
  over18?: Maybe<Scalars['Boolean']['output']>
  parentId?: Maybe<Scalars['String']['output']>
  parsedCommunityName: Scalars['String']['output']
  parsedId: Scalars['String']['output']
  postId?: Maybe<Scalars['String']['output']>
  redditCreatedAt: Scalars['String']['output']
  scrapedAt: Scalars['String']['output']
  thumbnailUrl?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  upVoteRatio?: Maybe<Scalars['Float']['output']>
  upVotes: Scalars['Float']['output']
  url: Scalars['String']['output']
  userId: Scalars['String']['output']
  username: Scalars['String']['output']
}

export type RedditSingleScrapeResult = {
  __typename?: 'RedditSingleScrapeResult'
  body?: Maybe<Scalars['String']['output']>
  category?: Maybe<Scalars['String']['output']>
  communityName: Scalars['String']['output']
  dataType: Scalars['String']['output']
  flair?: Maybe<Scalars['String']['output']>
  html?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  isAd?: Maybe<Scalars['Boolean']['output']>
  isVideo?: Maybe<Scalars['Boolean']['output']>
  numberOfComments?: Maybe<Scalars['Float']['output']>
  numberOfreplies?: Maybe<Scalars['Float']['output']>
  over18?: Maybe<Scalars['Boolean']['output']>
  parentId?: Maybe<Scalars['String']['output']>
  parsedCommunityName: Scalars['String']['output']
  parsedId: Scalars['String']['output']
  postId?: Maybe<Scalars['String']['output']>
  redditCreatedAt: Scalars['String']['output']
  scrapedAt: Scalars['String']['output']
  thumbnailUrl?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  upVoteRatio?: Maybe<Scalars['Float']['output']>
  upVotes: Scalars['Float']['output']
  url: Scalars['String']['output']
  userId: Scalars['String']['output']
  username: Scalars['String']['output']
}

export enum Regulation {
  Ecoa = 'ECOA',
  Efta = 'EFTA',
  Ewa = 'EWA',
  Fdic = 'FDIC',
  Tila = 'TILA',
  Tisa = 'TISA',
  Udaap = 'UDAAP',
}

export type RegulationCondition = {
  __typename?: 'RegulationCondition'
  operator: ComparisonOperator
  regulation: Regulation
  type: RuleConditionType
  value: Scalars['String']['output']
  variable?: Maybe<Scalars['String']['output']>
}

export type RegulationConditionInput = {
  regulation: Scalars['String']['input']
  value: Scalars['String']['input']
}

export type Requirement = {
  __typename?: 'Requirement'
  category: RequirementCategory
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  mimeTypes?: Maybe<Array<MimeType>>
  name: Scalars['String']['output']
  programRequirements: Array<ProgramRequirement>
  projectTasks: Array<ProjectTask>
  updatedAt: Scalars['DateTime']['output']
  workflowTemplate?: Maybe<WorkflowTemplate>
  workflowTemplateId?: Maybe<Scalars['String']['output']>
}

/** The categorization of a requirement, used for program task management */
export enum RequirementCategory {
  Documents = 'DOCUMENTS',
  DueDiligence = 'DUE_DILIGENCE',
  LaunchChecklist = 'LAUNCH_CHECKLIST',
  RiskAssessment = 'RISK_ASSESSMENT',
}

/** Status of the overall requirement category, based on aggregate result of requirements statuses. */
export enum RequirementCategoryStatus {
  Complete = 'COMPLETE',
  InProgress = 'IN_PROGRESS',
  NotYetStarted = 'NOT_YET_STARTED',
}

export type RequirementCategorySummary = {
  __typename?: 'RequirementCategorySummary'
  category: RequirementCategory
  completedDocumentCount: Scalars['Int']['output']
  documentCount: Scalars['Int']['output']
  inProgressDocumentCount: Scalars['Int']['output']
  program: Program
  programId: Scalars['String']['output']
  progress: Scalars['Float']['output']
  status: RequirementCategoryStatus
  tasks: Array<ProjectTask>
}

export type RequirementInput = {
  category: RequirementCategory
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['String']['input']>
  mimeType?: InputMaybe<Array<MimeType>>
  name: Scalars['String']['input']
  workflowTemplateId?: InputMaybe<Scalars['String']['input']>
}

export type ResolveSuggestionInput = {
  assetId: Scalars['String']['input']
  resolveType: ResolveType
  suggestionId: Scalars['String']['input']
}

/** How to resolve the suggestion */
export enum ResolveType {
  Accept = 'ACCEPT',
  Ignore = 'IGNORE',
  Reject = 'REJECT',
}

export enum ResponsibleParty {
  Bank = 'Bank',
  Partner = 'Partner',
}

export type ReviewRequest = {
  __typename?: 'ReviewRequest'
  asset: Asset
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['String']['output']
  requesterUser: User
  status: ReviewRequestStatus
  updatedAt: Scalars['DateTime']['output']
}

/** The status of the review request */
export enum ReviewRequestStatus {
  Approved = 'APPROVED',
  Denied = 'DENIED',
  Pending = 'PENDING',
}

export type Rule = {
  __typename?: 'Rule'
  conditionGroups?: Maybe<Array<ConditionGroup>>
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  partnerSource: PartnerSource
  partnerSourceId: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export enum RuleConditionType {
  Regulation = 'regulation',
}

export type SarFilingMetadata = {
  __typename?: 'SARFilingMetadata'
  actionTaken: Scalars['String']['output']
  comments?: Maybe<Scalars['String']['output']>
  customerId: Scalars['String']['output']
  documentUrl?: Maybe<Scalars['String']['output']>
  filingDate: Scalars['DateTime']['output']
  filingId: Scalars['String']['output']
  reasonForSuspicion: Scalars['String']['output']
  reportedBy: Scalars['String']['output']
  status: Scalars['String']['output']
  transactionAmount: Scalars['Float']['output']
  transactionDate: Scalars['DateTime']['output']
  transactionType: Scalars['String']['output']
}

export type SearchFilters = {
  asset?: InputMaybe<AssetSearchFilters>
  organization?: InputMaybe<OrganizationSearchFilters>
  project?: InputMaybe<ProjectSearchFilters>
  query?: InputMaybe<Scalars['String']['input']>
  sortBy?: InputMaybe<SortBy>
  space?: InputMaybe<SpaceSearchFilters>
}

export type SearchObject = Asset | Organization | Project | Space

export type SearchResult = {
  __typename?: 'SearchResult'
  data: Array<SearchResultData>
  meta: PaginationMeta
}

export type SearchResultData = {
  __typename?: 'SearchResultData'
  link: Scalars['String']['output']
  object: SearchObject
}

export type Slack = {
  __typename?: 'Slack'
  appId?: Maybe<Scalars['String']['output']>
  authUser?: Maybe<Scalars['String']['output']>
  botUserId?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['String']['output']
  isActive: Scalars['Boolean']['output']
  isEnterprise?: Maybe<Scalars['Boolean']['output']>
  narrativeChannelId?: Maybe<Scalars['String']['output']>
  organization: Organization
  teamId: Scalars['String']['output']
  teamName?: Maybe<Scalars['String']['output']>
  token: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type SlackChannel = {
  __typename?: 'SlackChannel'
  entityType: KnockChannelCollections
  id: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type SlackDataChannel = {
  __typename?: 'SlackDataChannel'
  collection: KnockChannelCollections
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  objectId: Scalars['String']['output']
  organizationId: Scalars['String']['output']
}

export type SlackTeamInfo = {
  __typename?: 'SlackTeamInfo'
  domain: Scalars['String']['output']
  emailDomain: Scalars['String']['output']
  icon: Scalars['String']['output']
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  url?: Maybe<Scalars['String']['output']>
}

export type SlackUser = {
  __typename?: 'SlackUser'
  id: Scalars['String']['output']
  realName: Scalars['String']['output']
}

/** The sort by on searches */
export enum SortBy {
  Asc = 'ASC',
  Desc = 'DESC',
}

/** The platform that the source is based on */
export enum SourceName {
  Bbb = 'BBB',
  Cfpb = 'CFPB',
  Document = 'DOCUMENT',
  Facebook = 'FACEBOOK',
  GoogleNews = 'GOOGLE_NEWS',
  Instagram = 'INSTAGRAM',
  LinkedIn = 'LINKED_IN',
  Organization = 'ORGANIZATION',
  Reddit = 'REDDIT',
  Tiktok = 'TIKTOK',
  TiktokPost = 'TIKTOK_POST',
  TiktokProfile = 'TIKTOK_PROFILE',
  TrustPilot = 'TRUST_PILOT',
  Website = 'WEBSITE',
  X = 'X',
}

/** Determines whether the source is an internal or external type */
export enum SourceType {
  External = 'EXTERNAL',
  Internal = 'INTERNAL',
}

export type Space = {
  __typename?: 'Space'
  archivedAt?: Maybe<Scalars['DateTime']['output']>
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  icon: Scalars['String']['output']
  iconColor: Scalars['String']['output']
  id: Scalars['String']['output']
  isOnboarded: Scalars['Boolean']['output']
  isPublic: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  notificationPreferences: Array<NotificationPreferencesSpace>
  organization: Organization
  organizationId: Scalars['String']['output']
  owner: User
  ownerId: Scalars['String']['output']
  projects: Array<Project>
  spaceUrl: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  users: Array<SpaceUser>
}

export type SpaceInvitation = {
  __typename?: 'SpaceInvitation'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  email: Scalars['String']['output']
  id: Scalars['String']['output']
  inviter: User
  role: SpaceUserRole
  space: Space
  spaceId: Scalars['String']['output']
  status: SpaceInvitationStatus
  uniqueId: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export enum SpaceInvitationStatus {
  Accepted = 'ACCEPTED',
  Canceled = 'CANCELED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
}

export type SpaceInvitationsItem = {
  email: Scalars['String']['input']
  role: SpaceUserRole
}

export type SpaceInviteEmailItem = {
  email: Scalars['String']['input']
}

export type SpaceSearchFilters = {
  createdAtEndDate?: InputMaybe<Scalars['DateTime']['input']>
  createdAtStartDate?: InputMaybe<Scalars['DateTime']['input']>
  deletedAtEndDate?: InputMaybe<Scalars['DateTime']['input']>
  deletedAtStartDate?: InputMaybe<Scalars['DateTime']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  ids?: InputMaybe<Array<Scalars['String']['input']>>
  isOnboarded?: InputMaybe<Scalars['Boolean']['input']>
  isPublic?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  organizationIds?: InputMaybe<Array<Scalars['String']['input']>>
  ownerId?: InputMaybe<Scalars['String']['input']>
  spaceUrl?: InputMaybe<Scalars['String']['input']>
  updatedAtEndDate?: InputMaybe<Scalars['DateTime']['input']>
  updatedAtStartDate?: InputMaybe<Scalars['DateTime']['input']>
  withDeleted?: InputMaybe<Scalars['Boolean']['input']>
}

export type SpaceUser = {
  __typename?: 'SpaceUser'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['String']['output']
  notificationPreferences: NotificationPreferencesSpace
  role: SpaceUserRole
  space: Space
  updatedAt: Scalars['DateTime']['output']
  user: User
}

export enum SpaceUserRole {
  Admin = 'ADMIN',
  Guest = 'GUEST',
  Member = 'MEMBER',
}

export type StartUrl = {
  __typename?: 'StartUrl'
  url: Scalars['String']['output']
}

export type SubmitActivityTaskInput = {
  explanation: Scalars['String']['input']
  taskId: Scalars['ID']['input']
}

export type Subtopic = {
  __typename?: 'Subtopic'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  topic: Topic
  updatedAt: Scalars['DateTime']['output']
}

export type Suggestion = {
  __typename?: 'Suggestion'
  asset: Asset
  createdAt: Scalars['DateTime']['output']
  createdBy?: Maybe<User>
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  highLevelReason: Scalars['String']['output']
  highlightedText: Scalars['String']['output']
  id: Scalars['String']['output']
  metadata?: Maybe<SuggestionMetadata>
  potentialAlternateCopy?: Maybe<Scalars['String']['output']>
  reasonForChange: Scalars['String']['output']
  resolveType?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type SuggestionMetadata = {
  __typename?: 'SuggestionMetadata'
  coordinates: Coordinates
  filename: Scalars['String']['output']
  filetype: Scalars['String']['output']
  languages: Array<Scalars['String']['output']>
  page_number: Scalars['Int']['output']
  parent_id: Scalars['String']['output']
}

export type SuggestionThread = {
  __typename?: 'SuggestionThread'
  asset: Asset
  attributes?: Maybe<Scalars['String']['output']>
  comments?: Maybe<Array<Comment>>
  context?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  externalId: Scalars['String']['output']
  id: Scalars['String']['output']
  resolvedAt?: Maybe<Scalars['DateTime']['output']>
  resolvedBy?: Maybe<User>
  suggestion: Suggestion
  updatedAt: Scalars['DateTime']['output']
}

export type TextSuggestion = {
  __typename?: 'TextSuggestion'
  highLevelReason: Scalars['String']['output']
  highlightedText: Scalars['String']['output']
  potentialAlternateCopy?: Maybe<Scalars['String']['output']>
  reasonForChange: Scalars['String']['output']
}

export type TikTokPostAuthorMetadata = {
  __typename?: 'TikTokPostAuthorMetadata'
  avatar: Scalars['String']['output']
  bioLink: Scalars['String']['output']
  commerceUserInfo?: Maybe<Scalars['Boolean']['output']>
  digg: Scalars['Float']['output']
  fans: Scalars['Float']['output']
  following: Scalars['Float']['output']
  friends: Scalars['Float']['output']
  heart: Scalars['Float']['output']
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  nickName: Scalars['String']['output']
  privateAccount: Scalars['Boolean']['output']
  region: Scalars['String']['output']
  roomId: Scalars['String']['output']
  signature: Scalars['String']['output']
  ttSeller: Scalars['Boolean']['output']
  verified: Scalars['Boolean']['output']
  video: Scalars['Float']['output']
}

export type TikTokPostHashtagMetadata = {
  __typename?: 'TikTokPostHashtagMetadata'
  id: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type TikTokPostMediaMetadata = {
  __typename?: 'TikTokPostMediaMetadata'
  coverMediumUrl: Scalars['String']['output']
  musicAlbum: Scalars['String']['output']
  musicAuthor: Scalars['String']['output']
  musicId: Scalars['String']['output']
  musicName: Scalars['String']['output']
  musicOriginal: Scalars['Boolean']['output']
  playUrl: Scalars['String']['output']
}

export type TikTokPostMetadata = {
  __typename?: 'TikTokPostMetadata'
  authorMeta: TikTokPostAuthorMetadata
  collectCount?: Maybe<Scalars['Float']['output']>
  commentCount: Scalars['Float']['output']
  createTime: Scalars['Float']['output']
  createTimeISO: Scalars['DateTime']['output']
  diggCount: Scalars['Float']['output']
  effectStickers?: Maybe<Array<Scalars['String']['output']>>
  hashtags: Array<TikTokPostHashtagMetadata>
  id: Scalars['String']['output']
  input?: Maybe<Scalars['String']['output']>
  isAd?: Maybe<Scalars['Boolean']['output']>
  isPinned?: Maybe<Scalars['Boolean']['output']>
  isSlideshow?: Maybe<Scalars['Boolean']['output']>
  locationCreated?: Maybe<Scalars['String']['output']>
  mediaUrls: Array<Scalars['String']['output']>
  mentions: Array<Scalars['String']['output']>
  musicMeta: TikTokPostMediaMetadata
  playCount: Scalars['Float']['output']
  shareCount: Scalars['Float']['output']
  text: Scalars['String']['output']
  videoMeta: TikTokProfileVideoMetadata
  webVideoUrl: Scalars['String']['output']
}

export type TikTokProfileVideoMetadata = {
  __typename?: 'TikTokProfileVideoMetadata'
  coverUrl: Scalars['String']['output']
  definition: Scalars['String']['output']
  downloadAddr: Scalars['String']['output']
  duration: Scalars['Float']['output']
  format: Scalars['String']['output']
  height: Scalars['Float']['output']
  originalCoverUrl: Scalars['String']['output']
  originalDownloadAddr: Scalars['String']['output']
  width: Scalars['Float']['output']
}

export type Topic = {
  __typename?: 'Topic'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  subtopics?: Maybe<Array<Subtopic>>
  updatedAt: Scalars['DateTime']['output']
}

export type TopicStatus = {
  __typename?: 'TopicStatus'
  healthStatus: ComplianceHealthStatus
  id: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type TrustpilotConsumer = {
  __typename?: 'TrustpilotConsumer'
  countryCode: Scalars['String']['output']
  displayName: Scalars['String']['output']
  hasImage: Scalars['Boolean']['output']
  id: Scalars['String']['output']
  imageUrl: Scalars['String']['output']
  isVerified: Scalars['Boolean']['output']
  numberOfReviews: Scalars['Float']['output']
}

export type TrustpilotDates = {
  __typename?: 'TrustpilotDates'
  experiencedDate: Scalars['String']['output']
  publishedDate: Scalars['String']['output']
  updatedDate?: Maybe<Scalars['String']['output']>
}

export type TrustpilotLabels = {
  __typename?: 'TrustpilotLabels'
  merged: TrustpilotMerged
  verification?: Maybe<TrustpilotVerification>
}

export type TrustpilotMerged = {
  __typename?: 'TrustpilotMerged'
  businessIdentifyingName?: Maybe<Scalars['String']['output']>
}

export type TrustpilotReview = {
  __typename?: 'TrustpilotReview'
  consumer: TrustpilotConsumer
  consumersReviewCountOnSameDomain?: Maybe<Scalars['Float']['output']>
  consumersReviewCountOnSameLocation?: Maybe<Scalars['Float']['output']>
  dates: TrustpilotDates
  filtered: Scalars['Boolean']['output']
  hasUnhandledReports: Scalars['Boolean']['output']
  id: Scalars['String']['output']
  labels?: Maybe<TrustpilotLabels>
  language: Scalars['String']['output']
  likes: Scalars['Float']['output']
  location?: Maybe<Scalars['String']['output']>
  pending: Scalars['Boolean']['output']
  rating: Scalars['Float']['output']
  text: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type TrustpilotVerification = {
  __typename?: 'TrustpilotVerification'
  createdDateTime: Scalars['String']['output']
  hasDachExclusion: Scalars['Boolean']['output']
  isVerified: Scalars['Boolean']['output']
  reviewSourceName: Scalars['String']['output']
  verificationLevel: Scalars['String']['output']
  verificationSource: Scalars['String']['output']
}

export type UpdateActivityInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  responsibleParty?: InputMaybe<ResponsibleParty>
  subtopicId?: InputMaybe<Scalars['String']['input']>
  topicId?: InputMaybe<Scalars['String']['input']>
  updateFrequency?: InputMaybe<UpdateFrequency>
}

export type UpdateActivitySubscriptionInput = {
  description?: InputMaybe<Scalars['String']['input']>
  dueDate?: InputMaybe<Scalars['DateTime']['input']>
  id: Scalars['ID']['input']
  inheritedFromBank?: InputMaybe<Scalars['Boolean']['input']>
  lastChecked?: InputMaybe<Scalars['DateTime']['input']>
  nextReminderDate?: InputMaybe<Scalars['DateTime']['input']>
  optedOut?: InputMaybe<Scalars['Boolean']['input']>
  status?: InputMaybe<ActivitySubscriptionStatus>
  statusChangedDate?: InputMaybe<Scalars['DateTime']['input']>
}

export type UpdateActivityTaskAssigneeInput = {
  assigneeId: Scalars['String']['input']
  taskId: Scalars['String']['input']
}

export type UpdateActivityTaskDueDateInput = {
  dueDate: Scalars['DateTime']['input']
  taskId: Scalars['ID']['input']
}

export type UpdateActivityTaskStatusInput = {
  status: ActivityTaskSubmissionStatus
  submittedAt?: InputMaybe<Scalars['DateTime']['input']>
  taskId: Scalars['ID']['input']
}

export type UpdateAssetInput = {
  id: Scalars['String']['input']
  name?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<AssetState>
  suggestionsJobRunning?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateAssetWithSuggestionsInput = {
  assetId?: InputMaybe<Scalars['String']['input']>
}

export type UpdateCommentInput = {
  assetId: Scalars['String']['input']
  attributes?: InputMaybe<Scalars['String']['input']>
  content?: InputMaybe<Scalars['String']['input']>
  externalId: Scalars['String']['input']
  mentionedUserIds?: InputMaybe<Array<Scalars['String']['input']>>
}

export type UpdateCommentThreadInput = {
  assetId: Scalars['String']['input']
  attributes?: InputMaybe<Scalars['String']['input']>
  context?: InputMaybe<Scalars['String']['input']>
  externalId: Scalars['String']['input']
  resolved?: InputMaybe<Scalars['Boolean']['input']>
}

export enum UpdateFrequency {
  AfterEachAudit = 'AfterEachAudit',
  AfterEachTest = 'AfterEachTest',
  Annually = 'Annually',
  AsApplicable = 'AsApplicable',
  AsDirected = 'AsDirected',
  AsNeeded = 'AsNeeded',
  AsPerPlan = 'AsPerPlan',
  AsPerRiskAssessment = 'AsPerRiskAssessment',
  AsPerSchedule = 'AsPerSchedule',
  AsRequested = 'AsRequested',
  AsRequired = 'AsRequired',
  AsScheduled = 'AsScheduled',
  AtOnboarding = 'AtOnboarding',
  BeforeLaunch = 'BeforeLaunch',
  BeforeOnboarding = 'BeforeOnboarding',
  Daily = 'Daily',
  Initially = 'Initially',
  InitiallyAnnually = 'InitiallyAnnually',
  InitiallyThenAsChanged = 'InitiallyThenAsChanged',
  InitiallyThenAsNeeded = 'InitiallyThenAsNeeded',
  InitiallyThenAsUpdated = 'InitiallyThenAsUpdated',
  InitiallyThenOngoing = 'InitiallyThenOngoing',
  Monthly = 'Monthly',
  Ongoing = 'Ongoing',
  PriorToLaunchThenAnnually = 'PriorToLaunchThenAnnually',
  Quarterly = 'Quarterly',
  SemiAnnually = 'SemiAnnually',
  Weekly = 'Weekly',
  Within1YearOfLaunch = 'Within1YearOfLaunch',
  Within1YearOfLaunchThenAnnually = 'Within1YearOfLaunchThenAnnually',
}

export type UpdateIssueInput = {
  issueId: Scalars['String']['input']
  status?: InputMaybe<IssueStatus>
}

export type UpdateNotificationPreferencesProjectInput = {
  muteAll?: InputMaybe<Scalars['Boolean']['input']>
  notifyComments?: InputMaybe<NotificationPreferencesProjectOptions>
  notifyEmail?: InputMaybe<Scalars['Boolean']['input']>
  notifySlack?: InputMaybe<Scalars['Boolean']['input']>
  notifyStatusFrequency?: InputMaybe<NotificationPreferencesProjectFrequency>
  notifyStatusUpdates?: InputMaybe<NotificationPreferencesProjectStatusOptions>
  notifyTasks?: InputMaybe<NotificationPreferencesProjectOptions>
  projectId: Scalars['String']['input']
  userId: Scalars['String']['input']
}

export type UpdateNotificationPreferencesSpaceInput = {
  muteAll?: InputMaybe<Scalars['Boolean']['input']>
  notifyAddedToProject?: InputMaybe<Scalars['Boolean']['input']>
  notifyEmail?: InputMaybe<Scalars['Boolean']['input']>
  notifyProjectCreated?: InputMaybe<Scalars['Boolean']['input']>
  notifySlack?: InputMaybe<Scalars['Boolean']['input']>
  spaceId: Scalars['String']['input']
  userId: Scalars['String']['input']
}

export type UpdateNotificationPreferencesUserInput = {
  muteAll?: InputMaybe<Scalars['Boolean']['input']>
  notifyEmail?: InputMaybe<Scalars['Boolean']['input']>
  notifySlack?: InputMaybe<Scalars['Boolean']['input']>
  userId: Scalars['String']['input']
}

export type UpdateOrganizationInput = {
  id: Scalars['String']['input']
  logoFileKey?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  orgType?: InputMaybe<OrganizationType>
}

export type UpdateOrganizationMetadataInput = {
  id: Scalars['String']['input']
  metadata?: InputMaybe<Scalars['JSON']['input']>
}

export type UpdateOrganizationUserInput = {
  isMosaicAdmin: Scalars['Boolean']['input']
  organizationId: Scalars['String']['input']
  role: Scalars['String']['input']
  userId: Scalars['String']['input']
}

export type UpdatePartnerSourceInput = {
  config?: InputMaybe<Scalars['JSON']['input']>
  id: Scalars['String']['input']
  syncedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type UpdatePartnershipInput = {
  healthScore?: InputMaybe<Scalars['Int']['input']>
  onboardingFinishTime?: InputMaybe<Scalars['DateTime']['input']>
  onboardingStartTime?: InputMaybe<Scalars['DateTime']['input']>
  ownerId?: InputMaybe<Scalars['String']['input']>
  partnershipId: Scalars['String']['input']
  programId?: InputMaybe<Scalars['String']['input']>
  stage?: InputMaybe<PartnershipStage>
}

export type UpdateProcessFieldInput = {
  description?: InputMaybe<Scalars['String']['input']>
  id: Scalars['String']['input']
  isRequired?: InputMaybe<Scalars['Boolean']['input']>
  options?: InputMaybe<Scalars['JSON']['input']>
  processId?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<FormFieldType>
}

export type UpdateProcessInput = {
  access?: InputMaybe<ProcessAccess>
  description?: InputMaybe<Scalars['String']['input']>
  id: Scalars['String']['input']
  organizationId?: InputMaybe<Scalars['String']['input']>
  spaceId?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<ProcessStatus>
  title?: InputMaybe<Scalars['String']['input']>
}

export type UpdateProgramInput = {
  description?: InputMaybe<Scalars['String']['input']>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  organizationId?: InputMaybe<Scalars['String']['input']>
  productType?: InputMaybe<PartnerProductType>
  programType?: InputMaybe<PartnerProgramType>
  requirements?: InputMaybe<Array<RequirementInput>>
}

export type UpdateProjectAssetInput = {
  id: Scalars['String']['input']
  name?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<AssetState>
  suggestionsJobRunning?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateProjectInput = {
  description?: InputMaybe<Scalars['String']['input']>
  emails?: InputMaybe<Array<ProjectInviteEmailItem>>
  icon?: InputMaybe<Scalars['String']['input']>
  iconColor?: InputMaybe<Scalars['String']['input']>
  isPublic?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  spaceId?: InputMaybe<Scalars['String']['input']>
}

export type UpdateProjectTaskInput = {
  asset?: InputMaybe<CreateAssetInput>
  assigneeId?: InputMaybe<Scalars['String']['input']>
  comment?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  dueDate?: InputMaybe<Scalars['DateTime']['input']>
  priority?: InputMaybe<ProjectTaskPriority>
  reviewerId?: InputMaybe<Scalars['String']['input']>
  taskId: Scalars['String']['input']
}

export type UpdateProjectTaskStatusInput = {
  status: ProjectTaskStatus
  taskId: Scalars['String']['input']
}

export type UpdateProjectUserInput = {
  projectId: Scalars['String']['input']
  role: Scalars['String']['input']
}

export type UpdateProjectWorkflowTemplateInput = {
  description?: InputMaybe<Scalars['String']['input']>
  id: Scalars['String']['input']
  name: Scalars['String']['input']
  nodes: Array<WorkflowNodeTemplateInput>
  organizationId: Scalars['String']['input']
  projectId?: InputMaybe<Scalars['String']['input']>
  validate?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateReviewRequestInput = {
  id: Scalars['String']['input']
  status: ReviewRequestStatus
}

export type UpdateRuleInput = {
  conditionGroups?: InputMaybe<Array<ConditionGroupInput>>
  name: Scalars['String']['input']
  ruleId: Scalars['String']['input']
}

export type UpdateSlackDataChannelInput = {
  collection: KnockChannelCollections
  id: Scalars['String']['input']
  name: Scalars['String']['input']
  objectId: Scalars['String']['input']
  organizationId: Scalars['String']['input']
}

export type UpdateSpaceInput = {
  description?: InputMaybe<Scalars['String']['input']>
  isOnboarded: Scalars['Boolean']['input']
  isPublic: Scalars['Boolean']['input']
  name: Scalars['String']['input']
}

export type UpdateSpaceUserInput = {
  role: Scalars['String']['input']
  spaceId: Scalars['String']['input']
}

export type UpdateUserInput = {
  avatarUrl?: InputMaybe<Scalars['String']['input']>
  externalId?: InputMaybe<Scalars['String']['input']>
  firstLoggedInAt?: InputMaybe<Scalars['DateTime']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
}

export type UpdateUserRoleInput = {
  role: UserRole
}

export type UpdateWorkflowNodeInput = {
  id: Scalars['String']['input']
  payload: Scalars['JSON']['input']
}

export type UpdateWorkflowNodeTemplateInput = {
  id: Scalars['String']['input']
  parentId?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<WorkflowNodeType>
}

export type UpdateWorkflowTemplateInput = {
  description?: InputMaybe<Scalars['String']['input']>
  id: Scalars['String']['input']
  name: Scalars['String']['input']
  nodes: Array<WorkflowNodeTemplateInput>
  organizationId: Scalars['String']['input']
  projectId?: InputMaybe<Scalars['String']['input']>
}

export type User = {
  __typename?: 'User'
  assignments?: Maybe<Array<WorkflowNodeAssignment>>
  avatarUrl?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  createdTasks?: Maybe<Array<ProjectTask>>
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  email: Scalars['String']['output']
  externalId: Scalars['String']['output']
  firstLoggedInAt?: Maybe<Scalars['DateTime']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  lastName?: Maybe<Scalars['String']['output']>
  notificationPreferences?: Maybe<NotificationPreferencesUser>
  notificationPreferencesProject?: Maybe<Array<NotificationPreferencesProject>>
  notificationPreferencesSpace?: Maybe<Array<NotificationPreferencesSpace>>
  organizationUsers?: Maybe<Array<OrganizationUser>>
  partnerships?: Maybe<Array<Partnership>>
  processMembers?: Maybe<Array<ProcessMember>>
  projects?: Maybe<Array<ProjectUser>>
  reviewRequests?: Maybe<Array<ReviewRequest>>
  role: UserRole
  sentOrganizationInvitations?: Maybe<Array<OrganizationInvitation>>
  sentProjectInvitations?: Maybe<Array<ProjectInvitation>>
  tasks?: Maybe<Array<ProjectTask>>
  tasksToReview?: Maybe<Array<ProjectTask>>
  updatedAt: Scalars['DateTime']['output']
  workflowTemplates?: Maybe<Array<WorkflowTemplate>>
}

export type UserInvitation = {
  __typename?: 'UserInvitation'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  email: Scalars['String']['output']
  expiresAt: Scalars['DateTime']['output']
  id: Scalars['String']['output']
  inviter: User
  role: UserRole
  status: UserInvitationStatus
  uniqueId: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type UserInvitationItem = {
  email: Scalars['String']['input']
  role: UserRole
}

export enum UserInvitationStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
}

export enum UserRole {
  Admin = 'ADMIN',
  MosaicAdmin = 'MOSAIC_ADMIN',
  User = 'USER',
}

export type WebsiteCrawl = {
  __typename?: 'WebsiteCrawl'
  depth: Scalars['Int']['output']
  httpStatusCode: Scalars['Int']['output']
  loadedTime: Scalars['String']['output']
  loadedUrl: Scalars['String']['output']
  referrerUrl: Scalars['String']['output']
}

export type WebsiteMetadata = {
  __typename?: 'WebsiteMetadata'
  author?: Maybe<Scalars['String']['output']>
  canonicalUrl: Scalars['String']['output']
  description: Scalars['String']['output']
  headers?: Maybe<Headers>
  html?: Maybe<Scalars['String']['output']>
  jsonLd?: Maybe<Array<Scalars['JSON']['output']>>
  keywords?: Maybe<Scalars['String']['output']>
  languageCode: Scalars['String']['output']
  openGraph: Array<OpenGraph>
  title: Scalars['String']['output']
}

export type WebsitePage = {
  __typename?: 'WebsitePage'
  canonicalUrl: Scalars['String']['output']
  crawl: WebsiteCrawl
  debug?: Maybe<Debug>
  html: Scalars['String']['output']
  htmlUrl?: Maybe<Scalars['String']['output']>
  markdown: Scalars['String']['output']
  metadata: WebsiteMetadata
  screenshotUrl?: Maybe<Scalars['String']['output']>
  stagehandScreenshotUrl?: Maybe<Scalars['String']['output']>
  text: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type Workflow = {
  __typename?: 'Workflow'
  createdAt: Scalars['DateTime']['output']
  currentNode?: Maybe<WorkflowNode>
  currentNodeId?: Maybe<Scalars['String']['output']>
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  nodes: Array<WorkflowNode>
  rootNodeId?: Maybe<Scalars['String']['output']>
  status: WorkflowStatus
  task: ProjectTask
  template?: Maybe<WorkflowTemplate>
  templateId?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type WorkflowNode = {
  __typename?: 'WorkflowNode'
  assignees?: Maybe<Array<WorkflowNodeAssignment>>
  children?: Maybe<Array<WorkflowNode>>
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  parent?: Maybe<WorkflowNode>
  payload?: Maybe<Scalars['JSON']['output']>
  status: WorkflowNodeStatus
  type: WorkflowNodeType
  updatedAt: Scalars['DateTime']['output']
  workflow: Workflow
}

export type WorkflowNodeAssignment = {
  __typename?: 'WorkflowNodeAssignment'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['String']['output']
  node: WorkflowNode
  updatedAt: Scalars['DateTime']['output']
  user: User
}

export enum WorkflowNodeStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
}

export type WorkflowNodeTemplate = {
  __typename?: 'WorkflowNodeTemplate'
  children?: Maybe<Array<WorkflowNodeTemplate>>
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  payload?: Maybe<Scalars['JSON']['output']>
  type: WorkflowNodeType
  updatedAt: Scalars['DateTime']['output']
  workflow: WorkflowTemplate
}

export type WorkflowNodeTemplateInput = {
  id: Scalars['String']['input']
  name: Scalars['String']['input']
  parentId?: InputMaybe<Scalars['String']['input']>
  payload?: InputMaybe<Scalars['JSON']['input']>
  type: WorkflowNodeType
}

export enum WorkflowNodeType {
  AddCollaboratorsTask = 'ADD_COLLABORATORS_TASK',
  AiReview = 'AI_REVIEW',
  ArchiveTask = 'ARCHIVE_TASK',
  AssignTask = 'ASSIGN_TASK',
  ExecutionDelay = 'EXECUTION_DELAY',
  NotifySlackChannel = 'NOTIFY_SLACK_CHANNEL',
  NotifySlackUser = 'NOTIFY_SLACK_USER',
  NoOp = 'NO_OP',
  OnDocumentAssetHumanReviewed = 'ON_DOCUMENT_ASSET_HUMAN_REVIEWED',
  OnTaskAssigned = 'ON_TASK_ASSIGNED',
  OnTaskAssignedToSpecificUser = 'ON_TASK_ASSIGNED_TO_SPECIFIC_USER',
  OnTaskAttachmentAdded = 'ON_TASK_ATTACHMENT_ADDED',
  OnTaskCommentAdded = 'ON_TASK_COMMENT_ADDED',
  OnTaskDateSet = 'ON_TASK_DATE_SET',
  OnTaskPrioritySet = 'ON_TASK_PRIORITY_SET',
  OnTaskStatusSet = 'ON_TASK_STATUS_SET',
  OnTaskUnassigned = 'ON_TASK_UNASSIGNED',
  RemoveTaskDate = 'REMOVE_TASK_DATE',
  SendEmail = 'SEND_EMAIL',
  SetTaskDate = 'SET_TASK_DATE',
  SetTaskPriority = 'SET_TASK_PRIORITY',
  SlackSendMessage = 'SLACK_SEND_MESSAGE',
  UnassignTask = 'UNASSIGN_TASK',
}

export enum WorkflowStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InConstruction = 'IN_CONSTRUCTION',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
}

export type WorkflowTemplate = {
  __typename?: 'WorkflowTemplate'
  active: Scalars['Boolean']['output']
  archivedAt?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  createdBy?: Maybe<User>
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  nodes: Array<WorkflowNodeTemplate>
  organization?: Maybe<Organization>
  organizationId?: Maybe<Scalars['String']['output']>
  project?: Maybe<Project>
  projectId?: Maybe<Scalars['String']['output']>
  requirement?: Maybe<Requirement>
  rootNodeId?: Maybe<Scalars['String']['output']>
  status: WorkflowTemplateStatus
  type: WorkflowTemplateType
  updatedAt: Scalars['DateTime']['output']
  workflows?: Maybe<Array<Workflow>>
}

export enum WorkflowTemplateStatus {
  Created = 'CREATED',
  InConstruction = 'IN_CONSTRUCTION',
}

export enum WorkflowTemplateType {
  BuiltIn = 'BUILT_IN',
  Custom = 'CUSTOM',
}

export type _AssetVersion = {
  __typename?: '_AssetVersion'
  comments?: Maybe<Array<Scalars['JSON']['output']>>
  eTag: Scalars['String']['output']
  isLatest?: Maybe<Scalars['Boolean']['output']>
  lastModified: Scalars['DateTime']['output']
  signedUrl?: Maybe<Scalars['String']['output']>
  size: Scalars['Float']['output']
  versionId: Scalars['String']['output']
}

export type SpacesQueryFragment = {
  __typename: 'Space'
  id: string
  name: string
  description?: string | null
  spaceUrl: string
  icon: string
  iconColor: string
  owner: { __typename?: 'User'; id: string }
  organization: { __typename?: 'Organization'; id: string; name: string; teamUrl: string }
  projects: Array<{
    __typename?: 'Project'
    id: string
    name: string
    projectUrl: string
    icon: string
    iconColor: string
  }>
  users: Array<{ __typename?: 'SpaceUser'; user: { __typename?: 'User'; id: string } }>
}

export type SpaceSearchFragment = {
  __typename: 'Space'
  id: string
  name: string
  description?: string | null
  spaceUrl: string
  createdAt: any
  updatedAt: any
  deletedAt?: any | null
  owner: { __typename?: 'User'; id: string }
  organization: { __typename?: 'Organization'; id: string; name: string; teamUrl: string }
  projects: Array<{
    __typename?: 'Project'
    id: string
    name: string
    projectUrl: string
    icon: string
    iconColor: string
  }>
  users: Array<{ __typename?: 'SpaceUser'; user: { __typename?: 'User'; id: string } }>
}

export type AssetSearchFragment = {
  __typename: 'Asset'
  id: string
  createdAt: any
  updatedAt: any
  deletedAt?: any | null
  name: string
  key: string
  version: string
  type: AssetType
  documentType: NarrativeDocumentType
  state: AssetState
  originalMimeType: MimeType
  user: {
    __typename?: 'User'
    id: string
    email: string
    firstName?: string | null
    lastName?: string | null
    avatarUrl?: string | null
  }
}

export type OrganizationSearchFragment = {
  __typename: 'Organization'
  id: string
  createdAt: any
  updatedAt: any
  deletedAt?: any | null
  name: string
  teamUrl: string
  teamSize?: string | null
  metadata?: any | null
}

export type ProjectSearchFragment = {
  __typename: 'Project'
  id: string
  createdAt: any
  updatedAt: any
  deletedAt?: any | null
  name: string
  projectUrl: string
  description?: string | null
  isPublic: boolean
  createdBy?: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    email: string
  } | null
  space: { __typename?: 'Space'; name: string }
}

export type PaginationSearchFragment = {
  __typename?: 'PaginationMeta'
  totalItems: number
  itemsPerPage: number
  totalPages: number
  currentPage: number
}

export type AuditTrailFragment = {
  __typename: 'AuditTrail'
  id: string
  organizationId?: string | null
  createdAt: any
  contextId?: string | null
  action: string
  entityId: string
  entityType: AuditTrailEntity
  sourceId?: string | null
  sourceType?: AuditTrailSource | null
  metadata?: any | null
}

export type ProcessFieldsFragment = {
  __typename: 'Process'
  id: string
  status: ProcessStatus
  access: ProcessAccess
  title: string
  description: string
  createdAt: any
  updatedAt: any
  deletedAt?: any | null
  space: { __typename?: 'Space'; id: string; name: string; icon: string; iconColor: string }
  members?: Array<{
    __typename: 'ProcessMember'
    id: string
    user: {
      __typename?: 'User'
      id: string
      email: string
      firstName?: string | null
      lastName?: string | null
      avatarUrl?: string | null
    }
  }> | null
  fields?: Array<{
    __typename: 'ProcessField'
    id: string
    title: string
    description: string
    type: FormFieldType
    isRequired: boolean
    options?: any | null
  }> | null
}

export type ProcessMemberFieldsFragment = {
  __typename: 'ProcessMember'
  id: string
  user: {
    __typename?: 'User'
    id: string
    email: string
    firstName?: string | null
    lastName?: string | null
    avatarUrl?: string | null
  }
}

export type ProcessFieldFieldsFragment = {
  __typename: 'ProcessField'
  id: string
  title: string
  description: string
  type: FormFieldType
  isRequired: boolean
  options?: any | null
}

export type ActivityTaskDetailsFragment = {
  __typename: 'ActivityTask'
  createdAt: any
  deletedAt?: any | null
  description?: string | null
  dueDate: any
  id: string
  submittingUserExplanation?: string | null
  approvalUserExplanation?: string | null
  submissionStatus: ActivityTaskSubmissionStatus
  submittedAt?: any | null
  updatedAt: any
  summary?: string | null
  assignedTo?: {
    __typename?: 'OrganizationUser'
    id: string
    user: { __typename?: 'User'; firstName?: string | null; lastName?: string | null; avatarUrl?: string | null }
  } | null
  submittedBy?: {
    __typename?: 'OrganizationUser'
    id: string
    user: { __typename?: 'User'; firstName?: string | null; lastName?: string | null; avatarUrl?: string | null }
  } | null
  approvedBy?: {
    __typename?: 'OrganizationUser'
    id: string
    user: { __typename?: 'User'; firstName?: string | null; lastName?: string | null; avatarUrl?: string | null }
  } | null
  subscription: {
    __typename?: 'ActivitySubscription'
    id: string
    optedOut: boolean
    activity: {
      __typename?: 'Activity'
      id: string
      name: string
      description: string
      responsibleParty: ResponsibleParty
      topic: { __typename?: 'Topic'; id: string; name: string }
      subtopic: { __typename?: 'Subtopic'; id: string; name: string }
    }
    organization: { __typename?: 'Organization'; id: string; name: string }
    sourceBank?: { __typename?: 'Organization'; id: string; name: string } | null
  }
  comments?: Array<{
    __typename?: 'ActivityTaskComment'
    id: string
    content: string
    createdAt: any
    author: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      avatarUrl?: string | null
      email: string
    }
  }> | null
  assets?: Array<{
    __typename?: 'Asset'
    id: string
    name: string
    key: string
    version: string
    originalMimeType: MimeType
    createdAt: any
    user: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      avatarUrl?: string | null
    }
  }> | null
  attachments?: Array<{ __typename?: 'ActivityTaskAttachment'; id: string; name: string; fileKey: string }> | null
}

export type ActivityDetailsFragment = {
  __typename?: 'Activity'
  id: string
  name: string
  description: string
  updateFrequency: UpdateFrequency
  responsibleParty: ResponsibleParty
  active: boolean
  createdAt: any
  updatedAt: any
  topic: { __typename?: 'Topic'; id: string; name: string; description?: string | null; createdAt: any }
  subtopic: { __typename?: 'Subtopic'; id: string; name: string; description?: string | null; createdAt: any }
}

export type UpdateUserRoleMutationVariables = Exact<{
  input: UpdateUserRoleInput
}>

export type UpdateUserRoleMutation = {
  __typename?: 'Mutation'
  updateUserRole: { __typename?: 'User'; id: string; role: UserRole }
}

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput
}>

export type UpdateUserMutation = {
  __typename?: 'Mutation'
  updateUser: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    avatarUrl?: string | null
  }
}

export type CreateOrganizationMutationVariables = Exact<{
  input: CreateOrganizationInput
}>

export type CreateOrganizationMutation = {
  __typename?: 'Mutation'
  createOrganization: { __typename?: 'Organization'; id: string; teamUrl: string }
}

export type UpdateOrganizationMutationVariables = Exact<{
  input: UpdateOrganizationInput
}>

export type UpdateOrganizationMutation = {
  __typename?: 'Mutation'
  updateOrganization: { __typename?: 'Organization'; id: string; name: string; logoUrl?: string | null }
}

export type UpdateOrganizationMetadataMutationVariables = Exact<{
  input: UpdateOrganizationMetadataInput
}>

export type UpdateOrganizationMetadataMutation = {
  __typename?: 'Mutation'
  updateOrganizationMetadata: { __typename?: 'Organization'; id: string; metadata?: any | null }
}

export type DeleteOrganizationMutationVariables = Exact<{
  organizationId: Scalars['String']['input']
}>

export type DeleteOrganizationMutation = { __typename?: 'Mutation'; deleteOrganization: boolean }

export type LeaveOrganizationMutationVariables = Exact<{
  organizationId: Scalars['String']['input']
}>

export type LeaveOrganizationMutation = { __typename?: 'Mutation'; leaveOrganization: boolean }

export type CreateSpaceMutationVariables = Exact<{
  input: CreateSpaceInput
}>

export type CreateSpaceMutation = {
  __typename?: 'Mutation'
  createSpace: { __typename?: 'Space'; id: string; name: string; spaceUrl: string }
}

export type DeleteSpaceMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type DeleteSpaceMutation = { __typename?: 'Mutation'; deleteSpace: boolean }

export type ArchiveSpaceMutationVariables = Exact<{
  input: ArchiveSpaceInput
}>

export type ArchiveSpaceMutation = { __typename?: 'Mutation'; archiveSpace: boolean }

export type CreateProjectMutationVariables = Exact<{
  input: CreateProjectInput
}>

export type CreateProjectMutation = {
  __typename?: 'Mutation'
  createProject: { __typename?: 'Project'; id: string; name: string }
}

export type DeleteProjectMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type DeleteProjectMutation = { __typename?: 'Mutation'; deleteProject: boolean }

export type ArchiveProjectMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type ArchiveProjectMutation = { __typename?: 'Mutation'; archiveProject: boolean }

export type CreateProjectTaskMutationVariables = Exact<{
  input: CreateProjectTaskInput
}>

export type CreateProjectTaskMutation = {
  __typename?: 'Mutation'
  createProjectTask: {
    __typename?: 'ProjectTask'
    id: string
    asset?: { __typename?: 'Asset'; id: string; name: string } | null
    project: { __typename?: 'Project'; projectUrl: string; space: { __typename?: 'Space'; spaceUrl: string } }
  }
}

export type UpdateProjectTaskMutationVariables = Exact<{
  input: UpdateProjectTaskInput
}>

export type UpdateProjectTaskMutation = {
  __typename?: 'Mutation'
  updateProjectTask: {
    __typename?: 'ProjectTask'
    id: string
    dueDate?: any | null
    priority: ProjectTaskPriority
    reviewer?: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      avatarUrl?: string | null
    } | null
  }
}

export type ArchiveProjectTaskMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type ArchiveProjectTaskMutation = {
  __typename?: 'Mutation'
  archiveProjectTask: { __typename?: 'ProjectTask'; id: string }
}

export type CreateProjectTaskAttachmentsMutationVariables = Exact<{
  input: Array<CreateTaskAttachmentInput> | CreateTaskAttachmentInput
}>

export type CreateProjectTaskAttachmentsMutation = {
  __typename?: 'Mutation'
  createProjectTaskAttachments: Array<{ __typename?: 'ProjectTaskAttachment'; id: string }>
}

export type CreateActivityTaskAttachmentsMutationVariables = Exact<{
  input: Array<CreateTaskAttachmentInput> | CreateTaskAttachmentInput
}>

export type CreateActivityTaskAttachmentsMutation = {
  __typename?: 'Mutation'
  createActivityTaskAttachments: Array<{ __typename?: 'ActivityTaskAttachment'; id: string }>
}

export type UpdateProjectAssetMutationVariables = Exact<{
  input: UpdateProjectAssetInput
}>

export type UpdateProjectAssetMutation = {
  __typename?: 'Mutation'
  updateProjectAsset: { __typename?: 'Asset'; id: string; state: AssetState }
}

export type UpdateProjectTaskStatusMutationVariables = Exact<{
  input: UpdateProjectTaskStatusInput
}>

export type UpdateProjectTaskStatusMutation = {
  __typename?: 'Mutation'
  updateProjectTaskStatus: { __typename?: 'ProjectTask'; id: string; status: ProjectTaskStatus }
}

export type BumpAssetVersionMutationVariables = Exact<{
  input: BumpAssetVersionInput
}>

export type BumpAssetVersionMutation = {
  __typename?: 'Mutation'
  bumpAssetVersion: { __typename?: 'Asset'; id: string }
}

export type CreateOrUpdateDocumentAssetRevisionsMutationVariables = Exact<{
  input: CouDocumentAssetRevisionInput
}>

export type CreateOrUpdateDocumentAssetRevisionsMutation = {
  __typename?: 'Mutation'
  createOrUpdateDocumentAssetRevisions: Array<{
    __typename?: 'DocumentAssetRevision'
    id: string
    externalId: string
    name?: string | null
    createdAt: any
    creatorId?: string | null
    authorsIds?: Array<string> | null
    attributes?: string | null
    fromVersion: number
    toVersion: number
    diffData?: string | null
    updatedAt: any
  }>
}

export type CreateAssetReviewRequestMutationVariables = Exact<{
  input: CreateReviewRequestInput
}>

export type CreateAssetReviewRequestMutation = {
  __typename?: 'Mutation'
  reviewRequest: { __typename?: 'ReviewRequest'; id: string }
}

export type UpdateAssetReviewRequestMutationVariables = Exact<{
  input: UpdateReviewRequestInput
}>

export type UpdateAssetReviewRequestMutation = {
  __typename?: 'Mutation'
  updateReviewRequest: { __typename?: 'ReviewRequest'; id: string; status: ReviewRequestStatus }
}

export type InviteOrganizationMembersMutationVariables = Exact<{
  input: CreateOrganizationInvitationsInput
}>

export type InviteOrganizationMembersMutation = {
  __typename?: 'Mutation'
  createOrganizationInvitations: {
    __typename?: 'CreateOrganizationInvitationsResponse'
    failedItems: Array<{ __typename?: 'CreateOrganizationInvitationFailedItem'; email: string; message: string }>
  }
}

export type InviteOrganizationMemberMutationVariables = Exact<{
  input: CreateOrganizationInvitationInput
}>

export type InviteOrganizationMemberMutation = {
  __typename?: 'Mutation'
  createOrganizationInvitation: {
    __typename?: 'OrganizationInvitation'
    id: string
    email: string
    role: OrganizationUserRole
  }
}

export type CancelInvitationMutationVariables = Exact<{
  input: CancelOrganizationInvitationInput
}>

export type CancelInvitationMutation = { __typename?: 'Mutation'; cancelOrganizationInvitation: boolean }

export type AcceptOrganizationInvitationMutationVariables = Exact<{
  input: AcceptOrganizationInvitationInput
}>

export type AcceptOrganizationInvitationMutation = {
  __typename?: 'Mutation'
  acceptOrganizationInvitation: {
    __typename?: 'OrganizationInvitation'
    id: string
    email: string
    role: OrganizationUserRole
    organization: { __typename?: 'Organization'; name: string; teamUrl: string }
  }
}

export type InviteProjectMembersMutationVariables = Exact<{
  input: CreateProjectInvitationsInput
}>

export type InviteProjectMembersMutation = {
  __typename?: 'Mutation'
  createProjectInvitations: {
    __typename?: 'CreateProjectInvitationsResponse'
    failedItems: Array<{ __typename?: 'CreateProjectInvitationFailedItem'; email: string; message: string }>
  }
}

export type InviteSpaceMembersMutationVariables = Exact<{
  input: CreateSpaceInvitationsInput
}>

export type InviteSpaceMembersMutation = {
  __typename?: 'Mutation'
  createSpaceInvitations: {
    __typename?: 'CreateSpaceInvitationsResponse'
    failedItems: Array<{ __typename?: 'CreateSpaceInvitationFailedItem'; email: string; message: string }>
  }
}

export type AcceptSpaceInvitationMutationVariables = Exact<{
  uniqueId: Scalars['String']['input']
}>

export type AcceptSpaceInvitationMutation = {
  __typename?: 'Mutation'
  acceptSpaceInvitation: {
    __typename?: 'SpaceInvitation'
    id: string
    email: string
    role: SpaceUserRole
    space: {
      __typename?: 'Space'
      name: string
      spaceUrl: string
      isOnboarded: boolean
      organization: {
        __typename?: 'Organization'
        teamUrl: string
        organizationProducts: Array<{
          __typename?: 'OrganizationProduct'
          product: { __typename?: 'Product'; id: string; name: ProductType }
        }>
      }
    }
  }
}

export type AcceptProjectInvitationMutationVariables = Exact<{
  uniqueId: Scalars['String']['input']
}>

export type AcceptProjectInvitationMutation = {
  __typename?: 'Mutation'
  acceptProjectInvitation: {
    __typename?: 'ProjectInvitation'
    id: string
    email: string
    role: ProjectUserRole
    project: {
      __typename?: 'Project'
      name: string
      projectUrl: string
      space: { __typename?: 'Space'; spaceUrl: string; organization: { __typename?: 'Organization'; teamUrl: string } }
    }
  }
}

export type CancelProjectInvitationMutationVariables = Exact<{
  uniqueId: Scalars['String']['input']
}>

export type CancelProjectInvitationMutation = { __typename?: 'Mutation'; cancelProjectInvitation: boolean }

export type CancelSpaceInvitationMutationVariables = Exact<{
  uniqueId: Scalars['String']['input']
}>

export type CancelSpaceInvitationMutation = { __typename?: 'Mutation'; cancelSpaceInvitation: boolean }

export type CreateWorkflowTemplateMutationVariables = Exact<{
  input: CreateWorkflowTemplateInput
}>

export type CreateWorkflowTemplateMutation = {
  __typename?: 'Mutation'
  createWorkflowTemplate: {
    __typename?: 'WorkflowTemplate'
    id: string
    name: string
    nodes: Array<{
      __typename?: 'WorkflowNodeTemplate'
      payload?: any | null
      createdAt: any
      deletedAt?: any | null
      id: string
      name: string
      type: WorkflowNodeType
      updatedAt: any
      children?: Array<{ __typename?: 'WorkflowNodeTemplate'; id: string; name: string }> | null
    }>
  }
}

export type UpdateWorkflowTemplateMutationVariables = Exact<{
  input: UpdateWorkflowTemplateInput
}>

export type UpdateWorkflowTemplateMutation = {
  __typename?: 'Mutation'
  updateWorkflowTemplate: {
    __typename?: 'WorkflowTemplate'
    id: string
    name: string
    nodes: Array<{
      __typename?: 'WorkflowNodeTemplate'
      payload?: any | null
      createdAt: any
      deletedAt?: any | null
      id: string
      name: string
      type: WorkflowNodeType
      updatedAt: any
      children?: Array<{ __typename?: 'WorkflowNodeTemplate'; id: string; name: string }> | null
    }>
  }
}

export type CreateProjectWorkflowTemplateMutationVariables = Exact<{
  input: CreateProjectWorkflowTemplateInput
}>

export type CreateProjectWorkflowTemplateMutation = {
  __typename?: 'Mutation'
  createProjectWorkflowTemplate: {
    __typename?: 'WorkflowTemplate'
    id: string
    name: string
    nodes: Array<{
      __typename?: 'WorkflowNodeTemplate'
      payload?: any | null
      createdAt: any
      deletedAt?: any | null
      id: string
      name: string
      type: WorkflowNodeType
      updatedAt: any
      children?: Array<{ __typename?: 'WorkflowNodeTemplate'; id: string; name: string }> | null
    }>
  }
}

export type UpdateProjectWorkflowTemplateMutationVariables = Exact<{
  input: UpdateProjectWorkflowTemplateInput
}>

export type UpdateProjectWorkflowTemplateMutation = {
  __typename?: 'Mutation'
  updateProjectWorkflowTemplate: {
    __typename?: 'WorkflowTemplate'
    id: string
    name: string
    nodes: Array<{
      __typename?: 'WorkflowNodeTemplate'
      payload?: any | null
      createdAt: any
      deletedAt?: any | null
      id: string
      name: string
      type: WorkflowNodeType
      updatedAt: any
      children?: Array<{ __typename?: 'WorkflowNodeTemplate'; id: string; name: string }> | null
    }>
  }
}

export type DeleteWorkflowTemplateMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type DeleteWorkflowTemplateMutation = { __typename?: 'Mutation'; deleteWorkflowTemplate: boolean }

export type ArchiveWorkflowTemplateMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type ArchiveWorkflowTemplateMutation = { __typename?: 'Mutation'; archiveWorkflowTemplate: boolean }

export type UpdateWorkflowTemplateActiveStatusMutationVariables = Exact<{
  id: Scalars['String']['input']
  active: Scalars['Boolean']['input']
}>

export type UpdateWorkflowTemplateActiveStatusMutation = {
  __typename?: 'Mutation'
  updateWorkflowTemplateActiveStatus: boolean
}

export type UpdateSpaceMutationVariables = Exact<{
  id: Scalars['String']['input']
  input: UpdateSpaceInput
}>

export type UpdateSpaceMutation = {
  __typename?: 'Mutation'
  updateSpace: { __typename?: 'Space'; id: string; name: string; description?: string | null }
}

export type UpdateSpaceUserMutationVariables = Exact<{
  id: Scalars['String']['input']
  input: UpdateSpaceUserInput
}>

export type UpdateSpaceUserMutation = {
  __typename?: 'Mutation'
  updateSpaceUser: { __typename?: 'SpaceUser'; id: string; role: SpaceUserRole }
}

export type CreateCommentThreadMutationVariables = Exact<{
  input: CreateCommentThreadInput
}>

export type CreateCommentThreadMutation = {
  __typename?: 'Mutation'
  createCommentThread: {
    __typename?: 'CommentThread'
    id: string
    externalId: string
    attributes?: string | null
    context?: string | null
    resolvedAt?: any | null
    resolvedBy?: { __typename?: 'User'; id: string } | null
    comments?: Array<{
      __typename?: 'Comment'
      id: string
      externalId: string
      content: string
      createdAt: any
      attributes?: string | null
      author: { __typename?: 'User'; id: string }
    }> | null
  }
}

export type UpdateCommentThreadMutationVariables = Exact<{
  input: UpdateCommentThreadInput
}>

export type UpdateCommentThreadMutation = {
  __typename?: 'Mutation'
  updateCommentThread: {
    __typename?: 'CommentThread'
    id: string
    externalId: string
    attributes?: string | null
    context?: string | null
    resolvedAt?: any | null
    resolvedBy?: { __typename?: 'User'; id: string } | null
    comments?: Array<{
      __typename?: 'Comment'
      id: string
      externalId: string
      content: string
      createdAt: any
      attributes?: string | null
      author: { __typename?: 'User'; id: string }
    }> | null
  }
}

export type DeleteCommentThreadMutationVariables = Exact<{
  input: DeleteCommentThreadInput
}>

export type DeleteCommentThreadMutation = { __typename?: 'Mutation'; deleteCommentThread: boolean }

export type CreateCommentMutationVariables = Exact<{
  input: CreateCommentInput
}>

export type CreateCommentMutation = {
  __typename?: 'Mutation'
  createComment: {
    __typename?: 'Comment'
    attributes?: string | null
    content: string
    createdAt: any
    externalId: string
    id: string
    author: { __typename?: 'User'; id: string }
  }
}

export type UpdateCommentMutationVariables = Exact<{
  input: UpdateCommentInput
}>

export type UpdateCommentMutation = {
  __typename?: 'Mutation'
  updateComment: {
    __typename?: 'Comment'
    attributes?: string | null
    content: string
    createdAt: any
    externalId: string
    id: string
    author: { __typename?: 'User'; id: string }
  }
}

export type DeleteCommentMutationVariables = Exact<{
  input: DeleteCommentInput
}>

export type DeleteCommentMutation = { __typename?: 'Mutation'; deleteComment: boolean }

export type AdvanceWorkflowMutationVariables = Exact<{
  taskId: Scalars['String']['input']
}>

export type AdvanceWorkflowMutation = {
  __typename?: 'Mutation'
  advanceWorkflow?: { __typename?: 'WorkflowNode'; id: string; name: string; status: WorkflowNodeStatus } | null
}

export type UpdateNotificationPreferencesProjectMutationVariables = Exact<{
  input: UpdateNotificationPreferencesProjectInput
}>

export type UpdateNotificationPreferencesProjectMutation = {
  __typename?: 'Mutation'
  updateNotificationPreferencesProject: {
    __typename?: 'NotificationPreferencesProject'
    id: string
    projectId: string
    userId: string
    notifyTasks: NotificationPreferencesProjectOptions
    notifyComments: NotificationPreferencesProjectOptions
    notifyStatusUpdates: NotificationPreferencesProjectStatusOptions
    notifyStatusFrequency?: NotificationPreferencesProjectFrequency | null
    notifyEmail: boolean
    notifySlack: boolean
    muteAll: boolean
  }
}

export type UpdateNotificationPreferencesSpaceMutationVariables = Exact<{
  input: UpdateNotificationPreferencesSpaceInput
}>

export type UpdateNotificationPreferencesSpaceMutation = {
  __typename?: 'Mutation'
  updateNotificationPreferencesSpace: {
    __typename?: 'NotificationPreferencesSpace'
    id: string
    spaceId: string
    userId: string
    notifyAddedToProject: boolean
    notifyProjectCreated: boolean
    notifyEmail: boolean
    notifySlack: boolean
    muteAll: boolean
  }
}

export type UpdateSlackDataChannelMutationVariables = Exact<{
  input: UpdateSlackDataChannelInput
}>

export type UpdateSlackDataChannelMutation = {
  __typename?: 'Mutation'
  updateSlackDataChannel: {
    __typename?: 'SlackDataChannel'
    organizationId: string
    collection: KnockChannelCollections
    objectId: string
    id: string
    name: string
  }
}

export type DeleteSlackDataChannelMutationVariables = Exact<{
  input: DeleteSlackDataChannelInput
}>

export type DeleteSlackDataChannelMutation = { __typename?: 'Mutation'; deleteSlackDataChannel: boolean }

export type CreateNotificationPreferencesUserMutationVariables = Exact<{
  input: CreateNotificationPreferencesUserInput
}>

export type CreateNotificationPreferencesUserMutation = {
  __typename?: 'Mutation'
  createNotificationPreferencesUser: {
    __typename?: 'NotificationPreferencesUser'
    id: string
    userId: string
    notifyEmail: boolean
    notifySlack: boolean
    muteAll: boolean
  }
}

export type UpdateNotificationPreferencesUserMutationVariables = Exact<{
  input: UpdateNotificationPreferencesUserInput
}>

export type UpdateNotificationPreferencesUserMutation = {
  __typename?: 'Mutation'
  updateNotificationPreferencesUser: {
    __typename?: 'NotificationPreferencesUser'
    id: string
    userId: string
    notifyEmail: boolean
    notifySlack: boolean
    muteAll: boolean
  }
}

export type LeaveSpaceMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type LeaveSpaceMutation = { __typename?: 'Mutation'; leaveSpace: boolean }

export type JoinSpaceMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type JoinSpaceMutation = { __typename?: 'Mutation'; joinSpace: { __typename?: 'SpaceUser'; id: string } }

export type UpdateOrganizationUserMutationVariables = Exact<{
  input: UpdateOrganizationUserInput
}>

export type UpdateOrganizationUserMutation = {
  __typename?: 'Mutation'
  updateOrganizationUser: { __typename?: 'OrganizationUser'; id: string }
}

export type UpdateProjectUserMutationVariables = Exact<{
  id: Scalars['String']['input']
  input: UpdateProjectUserInput
}>

export type UpdateProjectUserMutation = {
  __typename?: 'Mutation'
  updateProjectUser: { __typename?: 'ProjectUser'; id: string; role: ProjectUserRole }
}

export type UpdateProjectMutationVariables = Exact<{
  id: Scalars['String']['input']
  input: UpdateProjectInput
}>

export type UpdateProjectMutation = {
  __typename?: 'Mutation'
  updateProject: { __typename?: 'Project'; id: string; name: string; description?: string | null; projectUrl: string }
}

export type LeaveProjectMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type LeaveProjectMutation = { __typename?: 'Mutation'; leaveProject: boolean }

export type DeleteIntegrationMutationVariables = Exact<{
  input: DeleteIntegrationInput
}>

export type DeleteIntegrationMutation = { __typename?: 'Mutation'; deleteIntegration: boolean }

export type CreateProjectTaskAttachmentUploadUrlMutationVariables = Exact<{
  input: CreateTaskAttachmentUploadUrlInput
}>

export type CreateProjectTaskAttachmentUploadUrlMutation = {
  __typename?: 'Mutation'
  createProjectTaskAttachmentUploadUrl: { __typename?: 'PresignedUrl'; signedUrl: string; fileKey: string }
}

export type CreateActivityTaskAttachmentUploadUrlMutationVariables = Exact<{
  input: CreateTaskAttachmentUploadUrlInput
}>

export type CreateActivityTaskAttachmentUploadUrlMutation = {
  __typename?: 'Mutation'
  createActivityTaskAttachmentUploadUrl: { __typename?: 'PresignedUrl'; signedUrl: string; fileKey: string }
}

export type UpdateSuggestionMutationVariables = Exact<{
  input: ResolveSuggestionInput
}>

export type UpdateSuggestionMutation = { __typename?: 'Mutation'; updateSuggestion: boolean }

export type QueueSuggestionsJobForAssetMutationVariables = Exact<{
  input: UpdateAssetWithSuggestionsInput
}>

export type QueueSuggestionsJobForAssetMutation = { __typename?: 'Mutation'; queueSuggestionsJobForAsset: boolean }

export type IgnoreSuggestionsForAssetMutationVariables = Exact<{
  assetId: Scalars['String']['input']
}>

export type IgnoreSuggestionsForAssetMutation = { __typename?: 'Mutation'; ignoreSuggestionsForAsset: boolean }

export type GetOrCreateLiveblocksRoomMutationVariables = Exact<{
  input: CreateRoomForAssetInput
}>

export type GetOrCreateLiveblocksRoomMutation = {
  __typename?: 'Mutation'
  getOrCreateLiveblocksRoomForAsset: {
    __typename?: 'LiveblocksRoom'
    id: string
    createdAt: string
    lastConnectionAt?: string | null
  }
}

export type CreateProgramMutationVariables = Exact<{
  input: CreateProgramInput
}>

export type CreateProgramMutation = { __typename?: 'Mutation'; createProgram: { __typename?: 'Program'; id: string } }

export type UpdateProgramMutationVariables = Exact<{
  id: Scalars['String']['input']
  input: UpdateProgramInput
}>

export type UpdateProgramMutation = { __typename?: 'Mutation'; updateProgram: { __typename?: 'Program'; id: string } }

export type DeleteProgramMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type DeleteProgramMutation = { __typename?: 'Mutation'; deleteProgram: boolean }

export type CreatePartnershipMutationVariables = Exact<{
  input: CreatePartnershipInput
}>

export type CreatePartnershipMutation = {
  __typename?: 'Mutation'
  createPartnership: {
    __typename?: 'Partnership'
    id: string
    sponsorOrganizationId: string
    partnerOrganizationId: string
  }
}

export type UpdatePartnershipMutationVariables = Exact<{
  input: UpdatePartnershipInput
}>

export type UpdatePartnershipMutation = {
  __typename?: 'Mutation'
  updatePartnership: {
    __typename?: 'Partnership'
    id: string
    sponsorOrganizationId: string
    partnerOrganizationId: string
    onboardingStartTime?: any | null
    onboardingFinishTime?: any | null
  }
}

export type DeletePartnershipMutationVariables = Exact<{
  input: DeletePartnershipInput
}>

export type DeletePartnershipMutation = { __typename?: 'Mutation'; deletePartnership: boolean }

export type CreateMosaicPartnershipMutationVariables = Exact<{
  input: CreateMosaicPartnershipInput
}>

export type CreateMosaicPartnershipMutation = {
  __typename?: 'Mutation'
  createMosaicPartnership: {
    __typename?: 'MosaicPartnership'
    id: string
    sponsorOrganizationId: string
    partnerOrganizationId: string
  }
}

export type InitiatePartnerOnboardingMutationVariables = Exact<{
  url: Scalars['String']['input']
}>

export type InitiatePartnerOnboardingMutation = {
  __typename?: 'Mutation'
  initiatePartnerOnboarding: {
    __typename?: 'PartnerOnboardingOutput'
    organization: {
      __typename?: 'OrganizationOnlineBusinessFragment'
      metadata: { __typename?: 'OrganizationOnlineBusinessMetadata'; legalNames: Array<string> }
    }
    licences: Array<{
      __typename?: 'MSBLicenceFragment'
      metadata: { __typename?: 'MSBLicenceMetadata'; legalName: string }
    }>
    links: Array<{ __typename?: 'CategorizedLink'; href: string }>
    notableNews: Array<{
      __typename?: 'GoogleNewsSearchResultFragment'
      metadata: { __typename?: 'GoogleNewsSearchResultMetadata'; snippet: string }
    }>
  }
}

export type CreateProcessMutationVariables = Exact<{
  input: CreateProcessInput
}>

export type CreateProcessMutation = {
  __typename?: 'Mutation'
  createProcess: {
    __typename: 'Process'
    id: string
    status: ProcessStatus
    access: ProcessAccess
    title: string
    description: string
    createdAt: any
    updatedAt: any
    deletedAt?: any | null
    space: { __typename?: 'Space'; id: string; name: string; icon: string; iconColor: string }
    members?: Array<{
      __typename: 'ProcessMember'
      id: string
      user: {
        __typename?: 'User'
        id: string
        email: string
        firstName?: string | null
        lastName?: string | null
        avatarUrl?: string | null
      }
    }> | null
    fields?: Array<{
      __typename: 'ProcessField'
      id: string
      title: string
      description: string
      type: FormFieldType
      isRequired: boolean
      options?: any | null
    }> | null
  }
}

export type UpdateProcessMutationVariables = Exact<{
  input: UpdateProcessInput
}>

export type UpdateProcessMutation = {
  __typename?: 'Mutation'
  updateProcess: {
    __typename: 'Process'
    id: string
    status: ProcessStatus
    access: ProcessAccess
    title: string
    description: string
    createdAt: any
    updatedAt: any
    deletedAt?: any | null
    space: { __typename?: 'Space'; id: string; name: string; icon: string; iconColor: string }
    members?: Array<{
      __typename: 'ProcessMember'
      id: string
      user: {
        __typename?: 'User'
        id: string
        email: string
        firstName?: string | null
        lastName?: string | null
        avatarUrl?: string | null
      }
    }> | null
    fields?: Array<{
      __typename: 'ProcessField'
      id: string
      title: string
      description: string
      type: FormFieldType
      isRequired: boolean
      options?: any | null
    }> | null
  }
}

export type CreateProcessMembersMutationVariables = Exact<{
  input: CreateProcessMembersInput
}>

export type CreateProcessMembersMutation = {
  __typename?: 'Mutation'
  createProcessMembers: Array<{
    __typename: 'ProcessMember'
    id: string
    user: {
      __typename?: 'User'
      id: string
      email: string
      firstName?: string | null
      lastName?: string | null
      avatarUrl?: string | null
    }
  }>
}

export type SaveProcessFieldsMutationVariables = Exact<{
  input: Array<CreateProcessFieldInput> | CreateProcessFieldInput
  processId: Scalars['String']['input']
}>

export type SaveProcessFieldsMutation = {
  __typename?: 'Mutation'
  saveProcessFields: Array<{
    __typename: 'ProcessField'
    id: string
    title: string
    description: string
    type: FormFieldType
    isRequired: boolean
    options?: any | null
  }>
}

export type DeleteProcessFieldsMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type DeleteProcessFieldsMutation = { __typename?: 'Mutation'; removeProcessField: boolean }

export type SetProcessMembersMutationVariables = Exact<{
  input: CreateProcessMembersInput
}>

export type SetProcessMembersMutation = {
  __typename?: 'Mutation'
  setProcessMembers: Array<{
    __typename: 'ProcessMember'
    id: string
    user: {
      __typename?: 'User'
      id: string
      email: string
      firstName?: string | null
      lastName?: string | null
      avatarUrl?: string | null
    }
  }>
}

export type CreateIssuesForPartnerSourceMutationVariables = Exact<{
  partnerSourceId: Scalars['String']['input']
}>

export type CreateIssuesForPartnerSourceMutation = {
  __typename?: 'Mutation'
  createIssuesForPartnerSource: Array<{ __typename?: 'IssueItemOutput'; id: string }>
}

export type CreateIssuesForPartnershipMutationVariables = Exact<{
  partnershipId: Scalars['String']['input']
}>

export type CreateIssuesForPartnershipMutation = {
  __typename?: 'Mutation'
  createIssuesForPartnership: Array<{ __typename?: 'Issue'; id: string }>
}

export type CreatePartnerSourcesMutationVariables = Exact<{
  input: CreatePartnerSourcesInput
}>

export type CreatePartnerSourcesMutation = {
  __typename?: 'Mutation'
  createPartnerSources: Array<{ __typename?: 'PartnerSource'; id: string }>
}

export type CreatePartnerSourceMutationVariables = Exact<{
  input: CreatePartnerSourceInput
}>

export type CreatePartnerSourceMutation = {
  __typename?: 'Mutation'
  createPartnerSource: { __typename?: 'PartnerSource'; id: string }
}

export type UpdatePartnerSourceMutationVariables = Exact<{
  input: UpdatePartnerSourceInput
}>

export type UpdatePartnerSourceMutation = {
  __typename?: 'Mutation'
  updatePartnerSource: { __typename?: 'PartnerSource'; id: string }
}

export type DeletePartnerSourceMutationVariables = Exact<{
  partnerSourceId: Scalars['String']['input']
}>

export type DeletePartnerSourceMutation = {
  __typename?: 'Mutation'
  deletePartnerSource: { __typename?: 'PartnerSource'; id: string }
}

export type RunSinglePartnerSourceMutationVariables = Exact<{
  partnerSourceId: Scalars['String']['input']
}>

export type RunSinglePartnerSourceMutation = { __typename?: 'Mutation'; runSinglePartnerSource: boolean }

export type DeleteIssueMutationVariables = Exact<{
  issueId: Scalars['String']['input']
}>

export type DeleteIssueMutation = { __typename?: 'Mutation'; deleteIssue: { __typename?: 'Issue'; id: string } }

export type CreateRuleMutationVariables = Exact<{
  input: CreateRuleInput
}>

export type CreateRuleMutation = { __typename?: 'Mutation'; createRule: { __typename?: 'Rule'; id: string } }

export type UpdateRuleMutationVariables = Exact<{
  input: UpdateRuleInput
}>

export type UpdateRuleMutation = { __typename?: 'Mutation'; updateRule: { __typename?: 'Rule'; id: string } }

export type UpdateActivityMutationVariables = Exact<{
  id: Scalars['String']['input']
  input: UpdateActivityInput
}>

export type UpdateActivityMutation = {
  __typename?: 'Mutation'
  updateActivity: { __typename?: 'Activity'; id: string }
}

export type DeleteActivityMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type DeleteActivityMutation = { __typename?: 'Mutation'; deleteActivity: boolean }

export type DeleteActivitiesMutationVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type DeleteActivitiesMutation = { __typename?: 'Mutation'; deleteActivities: boolean }

export type CreateActivityMutationVariables = Exact<{
  input: CreateActivityInput
}>

export type CreateActivityMutation = {
  __typename?: 'Mutation'
  createActivity: { __typename?: 'Activity'; id: string }
}

export type DeleteMosaicPartnershipMutationVariables = Exact<{
  input: DeletePartnershipInput
}>

export type DeleteMosaicPartnershipMutation = { __typename?: 'Mutation'; deleteMosaicPartnership: boolean }

export type DeleteActivitySubscriptionMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type DeleteActivitySubscriptionMutation = { __typename?: 'Mutation'; deleteActivitySubscription: boolean }

export type DeleteActivitySubscriptionCascadeMutationVariables = Exact<{
  id: Scalars['String']['input']
  cascade: Scalars['Boolean']['input']
}>

export type DeleteActivitySubscriptionCascadeMutation = {
  __typename?: 'Mutation'
  deleteActivitySubscriptionCascade: boolean
}

export type CreateActivitySubscriptionMutationVariables = Exact<{
  input: CreateActivitySubscriptionInput
}>

export type CreateActivitySubscriptionMutation = {
  __typename?: 'Mutation'
  createActivitySubscription: {
    __typename?: 'ActivitySubscription'
    id: string
    description?: string | null
    status: ActivitySubscriptionStatus
    inheritedFromBank: boolean
    optedOut: boolean
    createdAt: any
    updatedAt: any
    deletedAt?: any | null
    activity: { __typename?: 'Activity'; id: string }
    organization: { __typename?: 'Organization'; id: string }
    sourceBank?: { __typename?: 'Organization'; id: string } | null
  }
}

export type CreateBankSubscriptionMutationVariables = Exact<{
  input: CreateBankSubscriptionInput
}>

export type CreateBankSubscriptionMutation = {
  __typename?: 'Mutation'
  createBankSubscription: {
    __typename?: 'ActivitySubscription'
    id: string
    description?: string | null
    status: ActivitySubscriptionStatus
    inheritedFromBank: boolean
    optedOut: boolean
    createdAt: any
    updatedAt: any
    deletedAt?: any | null
    activity: { __typename?: 'Activity'; id: string }
    organization: { __typename?: 'Organization'; id: string }
    sourceBank?: { __typename?: 'Organization'; id: string } | null
  }
}

export type AddActivityTaskCommentMutationVariables = Exact<{
  input: AddActivityTaskCommentInput
}>

export type AddActivityTaskCommentMutation = {
  __typename?: 'Mutation'
  addCommentToActivityTask: {
    __typename?: 'ActivityTaskComment'
    id: string
    content: string
    createdAt: any
    updatedAt: any
    author: { __typename?: 'User'; id: string; firstName?: string | null; lastName?: string | null }
  }
}

export type AddAssetToActivityTaskMutationVariables = Exact<{
  input: AddAssetToActivityTaskInput
}>

export type AddAssetToActivityTaskMutation = {
  __typename?: 'Mutation'
  addAssetToActivityTask: { __typename?: 'ActivityTask'; id: string }
}

export type DeleteAssetFromActivityTaskMutationVariables = Exact<{
  input: DeleteAssetFromActivityTaskInput
}>

export type DeleteAssetFromActivityTaskMutation = {
  __typename?: 'Mutation'
  deleteAssetFromActivityTask: { __typename?: 'ActivityTask'; id: string }
}

export type ApproveActivityTaskMutationVariables = Exact<{
  input: ApproveActivityTaskInput
}>

export type ApproveActivityTaskMutation = {
  __typename?: 'Mutation'
  approveActivityTask: { __typename?: 'ActivityTask'; id: string }
}

export type SubmitActivityTaskMutationVariables = Exact<{
  input: SubmitActivityTaskInput
}>

export type SubmitActivityTaskMutation = {
  __typename?: 'Mutation'
  submitActivityTask: { __typename?: 'ActivityTask'; id: string }
}

export type UpdateActivityTaskAssigneeMutationVariables = Exact<{
  input: UpdateActivityTaskAssigneeInput
}>

export type UpdateActivityTaskAssigneeMutation = {
  __typename?: 'Mutation'
  updateActivityTaskAssignee: { __typename?: 'ActivityTask'; id: string }
}

export type UpdateActivityTaskStatusMutationVariables = Exact<{
  input: UpdateActivityTaskStatusInput
}>

export type UpdateActivityTaskStatusMutation = {
  __typename?: 'Mutation'
  updateActivityTaskStatus: { __typename?: 'ActivityTask'; id: string }
}

export type UpdateActivityTaskDueDateMutationVariables = Exact<{
  input: UpdateActivityTaskDueDateInput
}>

export type UpdateActivityTaskDueDateMutation = {
  __typename?: 'Mutation'
  updateActivityTaskDueDate: { __typename?: 'ActivityTask'; id: string }
}

export type MarkOutOfScopeMutationVariables = Exact<{
  input: MarkOutOfScopeInput
}>

export type MarkOutOfScopeMutation = { __typename?: 'Mutation'; markOutOfScope: boolean }

export type CreateActivityTaskFromSubscriptionMutationVariables = Exact<{
  input: CreateActivityTaskFromSubscriptionIdInput
}>

export type CreateActivityTaskFromSubscriptionMutation = {
  __typename?: 'Mutation'
  createActivityTaskFromSubscription: { __typename?: 'ActivityTask'; id: string }
}

export type SendLogAnalysisInsightsChatMessageMutationVariables = Exact<{
  input: EnrichedFragmentQaInput
}>

export type SendLogAnalysisInsightsChatMessageMutation = {
  __typename?: 'Mutation'
  enrichmentQA: { __typename?: 'LogAnalysisResponse'; output: string }
}

export type DeleteAssetMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type DeleteAssetMutation = { __typename?: 'Mutation'; deleteAsset: boolean }

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>

export type GetCurrentUserQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    email: string
    firstName?: string | null
    lastName?: string | null
    role: UserRole
    avatarUrl?: string | null
    externalId: string
    organizationUsers?: Array<{
      __typename?: 'OrganizationUser'
      role: OrganizationUserRole
      createdAt: any
      organizationId: string
      organization: { __typename?: 'Organization'; name: string; type: OrganizationType; teamUrl: string }
    }> | null
  }
}

export type GetOrganizationsQueryVariables = Exact<{ [key: string]: never }>

export type GetOrganizationsQuery = {
  __typename?: 'Query'
  organizations: Array<{
    __typename?: 'Organization'
    id: string
    name: string
    teamUrl: string
    logoUrl?: string | null
    metadata?: any | null
    type: OrganizationType
    sponsor?: {
      __typename?: 'Partnership'
      id: string
      onboardingStartTime?: any | null
      onboardingFinishTime?: any | null
    } | null
    users: Array<{
      __typename?: 'OrganizationUser'
      id: string
      role: OrganizationUserRole
      createdAt: any
      user: {
        __typename?: 'User'
        externalId: string
        id: string
        firstName?: string | null
        lastName?: string | null
        email: string
        avatarUrl?: string | null
        firstLoggedInAt?: any | null
      }
    }>
    spaces: Array<{ __typename?: 'Space'; id: string; name: string; spaceUrl: string; icon: string; iconColor: string }>
  }>
}

export type GetOrganizationForMosaicAdminQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type GetOrganizationForMosaicAdminQuery = {
  __typename?: 'Query'
  organizationForMosaicAdmin?: {
    __typename?: 'Organization'
    id: string
    name: string
    logoUrl?: string | null
    type: OrganizationType
    users: Array<{
      __typename?: 'OrganizationUser'
      id: string
      role: OrganizationUserRole
      createdAt: any
      user: {
        __typename?: 'User'
        externalId: string
        id: string
        firstName?: string | null
        lastName?: string | null
        email: string
        avatarUrl?: string | null
        firstLoggedInAt?: any | null
      }
    }>
  } | null
}

export type GetOrganizationQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type GetOrganizationQuery = {
  __typename?: 'Query'
  organization?: {
    __typename?: 'Organization'
    id: string
    name: string
    logoUrl?: string | null
    type: OrganizationType
    users: Array<{
      __typename?: 'OrganizationUser'
      id: string
      role: OrganizationUserRole
      title: OrganizationUserTitle
      createdAt: any
      user: {
        __typename?: 'User'
        externalId: string
        id: string
        firstName?: string | null
        lastName?: string | null
        email: string
        avatarUrl?: string | null
        firstLoggedInAt?: any | null
      }
    }>
  } | null
}

export type GetOrganizationDashboardQueryVariables = Exact<{
  organizationId: Scalars['String']['input']
}>

export type GetOrganizationDashboardQuery = {
  __typename?: 'Query'
  projectsByUserInOrg: Array<{
    __typename?: 'Project'
    id: string
    name: string
    projectUrl: string
    icon: string
    iconColor: string
    archivedAt?: string | null
    users: Array<{
      __typename?: 'ProjectUser'
      id: string
      user: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        avatarUrl?: string | null
        email: string
      }
    }>
    space: { __typename?: 'Space'; id: string; name: string; spaceUrl: string }
  }>
  projectTasksCreatedInOrg: Array<{ __typename?: 'ProjectTask'; id: string }>
  projectTasksAssignedInOrg: Array<{
    __typename?: 'ProjectTask'
    id: string
    name: string
    description?: string | null
    dueDate?: any | null
    archivedAt?: any | null
    priority: ProjectTaskPriority
    project: {
      __typename?: 'Project'
      name: string
      icon: string
      iconColor: string
      projectUrl: string
      space: { __typename?: 'Space'; spaceUrl: string }
    }
  }>
}

export type GetOrganizationByTeamUrlQueryVariables = Exact<{
  teamUrl: Scalars['String']['input']
}>

export type GetOrganizationByTeamUrlQuery = {
  __typename?: 'Query'
  getOrganizationByTeamUrl?: {
    __typename?: 'Organization'
    id: string
    name: string
    teamUrl: string
    type: OrganizationType
    users: Array<{
      __typename?: 'OrganizationUser'
      id: string
      role: OrganizationUserRole
      user: { __typename?: 'User'; id: string; firstName?: string | null; lastName?: string | null; email: string }
    }>
    spaces: Array<{ __typename?: 'Space'; id: string; name: string }>
  } | null
}

export type GetSpaceByUrlQueryVariables = Exact<{
  spaceUrl: Scalars['String']['input']
  organizationId: Scalars['String']['input']
}>

export type GetSpaceByUrlQuery = {
  __typename?: 'Query'
  spaceByUrl: {
    __typename?: 'Space'
    id: string
    name: string
    spaceUrl: string
    description?: string | null
    isPublic: boolean
    isOnboarded: boolean
    createdAt: any
    icon: string
    iconColor: string
    owner: { __typename?: 'User'; id: string; firstName?: string | null; lastName?: string | null }
    projects: Array<{
      __typename?: 'Project'
      id: string
      name: string
      projectUrl: string
      description?: string | null
      createdAt: any
      updatedAt: any
      iconUrl?: string | null
      icon: string
      iconColor: string
      tasks?: Array<{
        __typename?: 'ProjectTask'
        id: string
        name: string
        description?: string | null
        status: ProjectTaskStatus
        dueDate?: any | null
        archivedAt?: any | null
        assignee?: {
          __typename?: 'User'
          id: string
          avatarUrl?: string | null
          firstName?: string | null
          lastName?: string | null
        } | null
      }> | null
      users: Array<{
        __typename?: 'ProjectUser'
        id: string
        role: ProjectUserRole
        user: {
          __typename?: 'User'
          id: string
          avatarUrl?: string | null
          firstName?: string | null
          lastName?: string | null
          email: string
        }
      }>
    }>
    users: Array<{
      __typename?: 'SpaceUser'
      id: string
      role: SpaceUserRole
      user: {
        __typename?: 'User'
        id: string
        avatarUrl?: string | null
        firstName?: string | null
        lastName?: string | null
        email: string
      }
    }>
  }
}

export type GetProjectQueryVariables = Exact<{
  spaceId: Scalars['String']['input']
  projectUrl: Scalars['String']['input']
}>

export type GetProjectQuery = {
  __typename?: 'Query'
  projectByUrl: {
    __typename?: 'Project'
    id: string
    name: string
    projectUrl: string
    description?: string | null
    isPublic: boolean
    createdAt: any
    icon: string
    iconColor: string
    createdBy?: { __typename?: 'User'; firstName?: string | null; lastName?: string | null; id: string } | null
    space: { __typename?: 'Space'; id: string; name: string; spaceUrl: string }
    tasks?: Array<{
      __typename?: 'ProjectTask'
      id: string
      name: string
      description?: string | null
      dueDate?: any | null
      priority: ProjectTaskPriority
      status: ProjectTaskStatus
      archivedAt?: any | null
      updatedAt: any
      createdAt: any
      assignee?: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        avatarUrl?: string | null
      } | null
      reviewer?: { __typename?: 'User'; id: string; firstName?: string | null; lastName?: string | null } | null
      comments?: Array<{
        __typename?: 'ProjectTaskComment'
        id: string
        content: string
        createdAt: any
        author: {
          __typename?: 'User'
          id: string
          firstName?: string | null
          lastName?: string | null
          avatarUrl?: string | null
          email: string
        }
      }> | null
      attachments?: Array<{ __typename?: 'ProjectTaskAttachment'; id: string; fileKey: string; name: string }> | null
      workflow: {
        __typename?: 'Workflow'
        status: WorkflowStatus
        currentNode?: { __typename?: 'WorkflowNode'; status: WorkflowNodeStatus; type: WorkflowNodeType } | null
      }
    }> | null
    users: Array<{
      __typename?: 'ProjectUser'
      id: string
      role: ProjectUserRole
      user: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        email: string
        avatarUrl?: string | null
      }
    }>
  }
}

export type GetIssueQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type GetIssueQuery = {
  __typename?: 'Query'
  issue: {
    __typename?: 'IssueItemOutput'
    id: string
    ticketId: string
    title: string
    createdAt: any
    description: string
    status: IssueStatus
    level: string
    justification: string
    reasons: Array<{
      __typename?: 'IssueReason'
      title: string
      justification: string
      reference: string
      referenceUrl: string
    }>
    fragmentItem: {
      __typename?: 'PartnerSourceFragmentItemOutput'
      id: string
      type: string
      createdAt: any
      updatedAt: any
      partnerSource: { __typename?: 'PartnerSource'; name: SourceName; config: any }
      data?:
        | {
            __typename: 'CFPBComplaintMetadata'
            complaint_id: number
            company: string
            complaint_what_happened: string
            has_narrative: boolean
            issue: string
            product: string
            state: string
          }
        | { __typename: 'GoogleNewsSearchResultMetadata'; title: string; date: any }
        | {
            __typename: 'RedditPost'
            id: string
            username: string
            url: string
            body?: string | null
            communityName: string
            redditCreatedAt: string
            upVotes: number
            numberOfComments?: number | null
          }
        | { __typename?: 'SARFilingMetadata' }
        | {
            __typename: 'TikTokPostMetadata'
            mediaUrls: Array<string>
            webVideoUrl: string
            videoMeta: { __typename?: 'TikTokProfileVideoMetadata'; coverUrl: string }
            authorMeta: { __typename?: 'TikTokPostAuthorMetadata'; name: string }
          }
        | {
            __typename: 'TrustpilotReview'
            title: string
            text: string
            id: string
            rating: number
            pending: boolean
            location?: string | null
            consumer: { __typename?: 'TrustpilotConsumer'; displayName: string }
          }
        | { __typename: 'WebsitePage'; canonicalUrl: string; screenshotUrl?: string | null }
        | null
    }
  }
}

export type GetEnrichedTiktokPostFragmentQueryVariables = Exact<{
  enrichedFragmentId: Scalars['String']['input']
}>

export type GetEnrichedTiktokPostFragmentQuery = {
  __typename?: 'Query'
  enrichedTiktokPostFragment?: {
    __typename?: 'EnrichedTiktokPostFragment'
    id: string
    discriminator: string
    fragment: { __typename?: 'Fragment'; id: string; discriminator: string }
    metadata: {
      __typename?: 'EnrichedTiktokPostMetadata'
      text: string
      webUrl: string
      playUrl: string
      coverImageUrl: string
    }
  } | null
}

export type GetAllFragmentsPartnerSourceIdQueryVariables = Exact<{
  partnerSourceId: Scalars['String']['input']
}>

export type GetAllFragmentsPartnerSourceIdQuery = {
  __typename?: 'Query'
  getAllFragmentsPartnerSourceId: Array<{
    __typename?: 'PartnerSourceEnrichedFragmentItemOutput'
    id: string
    type: string
    ukey: string
    createdAt: any
    updatedAt: any
    partnerSource: { __typename?: 'PartnerSource'; name: SourceName; config: any }
    data?:
      | {
          __typename: 'CFPBComplaintMetadata'
          complaint_id: number
          company: string
          complaint_what_happened: string
          has_narrative: boolean
          issue: string
          product: string
          state: string
        }
      | { __typename: 'GoogleNewsSearchResultMetadata'; title: string; date: any }
      | {
          __typename: 'RedditPost'
          id: string
          username: string
          url: string
          body?: string | null
          communityName: string
          redditCreatedAt: string
          upVotes: number
          numberOfComments?: number | null
        }
      | { __typename?: 'SARFilingMetadata' }
      | {
          __typename: 'TikTokPostMetadata'
          mediaUrls: Array<string>
          webVideoUrl: string
          videoMeta: { __typename?: 'TikTokProfileVideoMetadata'; coverUrl: string }
          authorMeta: { __typename?: 'TikTokPostAuthorMetadata'; name: string }
        }
      | {
          __typename: 'TrustpilotReview'
          title: string
          text: string
          id: string
          rating: number
          pending: boolean
          location?: string | null
          consumer: { __typename?: 'TrustpilotConsumer'; displayName: string }
        }
      | { __typename: 'WebsitePage'; canonicalUrl: string; screenshotUrl?: string | null }
      | null
  }>
}

export type GetEnrichedFragmentByFragmentIdQueryVariables = Exact<{
  fragmentId: Scalars['String']['input']
}>

export type GetEnrichedFragmentByFragmentIdQuery = {
  __typename?: 'Query'
  getEnrichedFragmentByFragmentId: Array<{
    __typename?: 'PartnerSourceEnrichedFragmentItemOutput'
    id: string
    type: string
    createdAt: any
    updatedAt: any
    ukey: string
    enrichedData?:
      | {
          __typename: 'EnrichedCFPBComplaintMetadata'
          category: ComplaintCategory
          keywords: Array<string>
          summary: string
          threatensLegalAction: boolean
          mentionsDispute: boolean
        }
      | { __typename?: 'EnrichedGoogleNewsSearchResultMetadata' }
      | { __typename?: 'EnrichedRedditPostMetadata' }
      | { __typename?: 'EnrichedSARFilingMetadata' }
      | { __typename?: 'EnrichedTiktokPostMetadata' }
      | {
          __typename: 'EnrichedTrustPilotReviewMetadata'
          category: ComplaintCategory
          keywords: Array<string>
          summary: string
          threatensLegalAction: boolean
          mentionsDispute: boolean
        }
      | {
          __typename: 'EnrichedWebsitePageMetadata'
          text: string
          suggestions: Array<{
            __typename?: 'TextSuggestion'
            highlightedText: string
            reasonForChange: string
            highLevelReason: string
            potentialAlternateCopy?: string | null
          }>
        }
      | null
    data?:
      | {
          __typename: 'CFPBComplaintMetadata'
          complaint_id: number
          company: string
          complaint_what_happened: string
          has_narrative: boolean
          issue: string
          product: string
          state: string
        }
      | { __typename: 'GoogleNewsSearchResultMetadata'; title: string; date: any }
      | {
          __typename: 'RedditPost'
          id: string
          username: string
          url: string
          body?: string | null
          communityName: string
          redditCreatedAt: string
          upVotes: number
          numberOfComments?: number | null
        }
      | { __typename?: 'SARFilingMetadata' }
      | {
          __typename: 'TikTokPostMetadata'
          mediaUrls: Array<string>
          webVideoUrl: string
          videoMeta: { __typename?: 'TikTokProfileVideoMetadata'; coverUrl: string }
          authorMeta: { __typename?: 'TikTokPostAuthorMetadata'; name: string }
        }
      | {
          __typename: 'TrustpilotReview'
          title: string
          text: string
          id: string
          rating: number
          pending: boolean
          location?: string | null
          consumer: { __typename?: 'TrustpilotConsumer'; displayName: string }
        }
      | {
          __typename: 'WebsitePage'
          canonicalUrl: string
          screenshotUrl?: string | null
          html: string
          stagehandScreenshotUrl?: string | null
        }
      | null
  }>
}

export type GetFragmentByIdQueryVariables = Exact<{
  fragmentId: Scalars['String']['input']
}>

export type GetFragmentByIdQuery = {
  __typename?: 'Query'
  getFragment: Array<{
    __typename?: 'PartnerSourceFragmentItemOutput'
    id: string
    type: string
    createdAt: any
    updatedAt: any
    ukey: string
    partnerSource: { __typename?: 'PartnerSource'; config: any; name: SourceName }
    data?:
      | {
          __typename: 'CFPBComplaintMetadata'
          complaint_id: number
          company: string
          complaint_what_happened: string
          has_narrative: boolean
          issue: string
          product: string
          state: string
        }
      | { __typename: 'GoogleNewsSearchResultMetadata'; title: string; date: any }
      | {
          __typename: 'RedditPost'
          id: string
          username: string
          url: string
          body?: string | null
          communityName: string
          redditCreatedAt: string
          upVotes: number
          numberOfComments?: number | null
        }
      | { __typename?: 'SARFilingMetadata' }
      | {
          __typename: 'TikTokPostMetadata'
          mediaUrls: Array<string>
          webVideoUrl: string
          videoMeta: { __typename?: 'TikTokProfileVideoMetadata'; coverUrl: string }
          authorMeta: { __typename?: 'TikTokPostAuthorMetadata'; name: string }
        }
      | {
          __typename: 'TrustpilotReview'
          title: string
          text: string
          id: string
          rating: number
          pending: boolean
          location?: string | null
          consumer: { __typename?: 'TrustpilotConsumer'; displayName: string }
        }
      | { __typename: 'WebsitePage'; canonicalUrl: string; screenshotUrl?: string | null }
      | null
  }>
}

export type GetAllFragmentsForPartnershipIdQueryVariables = Exact<{
  input: GetFragmentsForPartnershipInput
}>

export type GetAllFragmentsForPartnershipIdQuery = {
  __typename?: 'Query'
  getAllFragmentsForPartnershipId: Array<{
    __typename?: 'PartnerSourceFragmentItemOutput'
    id: string
    type: string
    createdAt: any
    updatedAt: any
    ukey: string
    partnerSource: { __typename?: 'PartnerSource'; config: any; name: SourceName }
    data?:
      | {
          __typename: 'CFPBComplaintMetadata'
          complaint_id: number
          company: string
          complaint_what_happened: string
          has_narrative: boolean
          issue: string
          product: string
          state: string
        }
      | { __typename: 'GoogleNewsSearchResultMetadata'; title: string; date: any }
      | {
          __typename: 'RedditPost'
          id: string
          username: string
          url: string
          body?: string | null
          communityName: string
          redditCreatedAt: string
          upVotes: number
          numberOfComments?: number | null
        }
      | { __typename?: 'SARFilingMetadata' }
      | {
          __typename: 'TikTokPostMetadata'
          mediaUrls: Array<string>
          webVideoUrl: string
          videoMeta: { __typename?: 'TikTokProfileVideoMetadata'; coverUrl: string }
          authorMeta: { __typename?: 'TikTokPostAuthorMetadata'; name: string }
        }
      | {
          __typename: 'TrustpilotReview'
          title: string
          text: string
          id: string
          rating: number
          pending: boolean
          location?: string | null
          consumer: { __typename?: 'TrustpilotConsumer'; displayName: string }
        }
      | { __typename: 'WebsitePage'; canonicalUrl: string; screenshotUrl?: string | null }
      | null
  }>
}

export type GetAllEnrichedFragmentsForPartnershipIdQueryVariables = Exact<{
  input: GetFragmentsForPartnershipInput
}>

export type GetAllEnrichedFragmentsForPartnershipIdQuery = {
  __typename?: 'Query'
  getAllEnrichedFragmentsForPartnershipId: Array<{
    __typename?: 'PartnerSourceEnrichedFragmentItemOutput'
    id: string
    type: string
    createdAt: any
    updatedAt: any
    ukey: string
    partnerSource: { __typename?: 'PartnerSource'; config: any; name: SourceName }
    enrichedData?:
      | {
          __typename: 'EnrichedCFPBComplaintMetadata'
          category: ComplaintCategory
          keywords: Array<string>
          summary: string
          threatensLegalAction: boolean
          mentionsDispute: boolean
        }
      | { __typename?: 'EnrichedGoogleNewsSearchResultMetadata' }
      | { __typename?: 'EnrichedRedditPostMetadata' }
      | { __typename?: 'EnrichedSARFilingMetadata' }
      | { __typename?: 'EnrichedTiktokPostMetadata' }
      | {
          __typename: 'EnrichedTrustPilotReviewMetadata'
          category: ComplaintCategory
          keywords: Array<string>
          summary: string
          threatensLegalAction: boolean
          mentionsDispute: boolean
        }
      | {
          __typename: 'EnrichedWebsitePageMetadata'
          text: string
          suggestions: Array<{
            __typename?: 'TextSuggestion'
            highlightedText: string
            reasonForChange: string
            highLevelReason: string
            potentialAlternateCopy?: string | null
          }>
        }
      | null
    data?:
      | {
          __typename: 'CFPBComplaintMetadata'
          complaint_id: number
          company: string
          complaint_what_happened: string
          has_narrative: boolean
          issue: string
          product: string
          state: string
        }
      | { __typename: 'GoogleNewsSearchResultMetadata'; title: string; date: any }
      | {
          __typename: 'RedditPost'
          id: string
          username: string
          url: string
          body?: string | null
          communityName: string
          redditCreatedAt: string
          upVotes: number
          numberOfComments?: number | null
        }
      | { __typename?: 'SARFilingMetadata' }
      | {
          __typename: 'TikTokPostMetadata'
          mediaUrls: Array<string>
          webVideoUrl: string
          videoMeta: { __typename?: 'TikTokProfileVideoMetadata'; coverUrl: string }
          authorMeta: { __typename?: 'TikTokPostAuthorMetadata'; name: string }
        }
      | {
          __typename: 'TrustpilotReview'
          title: string
          text: string
          id: string
          rating: number
          pending: boolean
          location?: string | null
          consumer: { __typename?: 'TrustpilotConsumer'; displayName: string }
        }
      | { __typename: 'WebsitePage'; canonicalUrl: string; screenshotUrl?: string | null; html: string }
      | null
  }>
}

export type GetIssuesByPartnerSourceIdQueryVariables = Exact<{
  partnerSourceId: Scalars['String']['input']
}>

export type GetIssuesByPartnerSourceIdQuery = {
  __typename?: 'Query'
  issues: Array<{
    __typename?: 'IssueItemOutput'
    id: string
    title: string
    createdAt: any
    description: string
    status: IssueStatus
    level: string
    justification: string
    fragmentItem: {
      __typename?: 'PartnerSourceFragmentItemOutput'
      id: string
      type: string
      createdAt: any
      updatedAt: any
      partnerSource: { __typename?: 'PartnerSource'; name: SourceName; config: any }
      data?:
        | {
            __typename: 'CFPBComplaintMetadata'
            complaint_id: number
            company: string
            complaint_what_happened: string
            has_narrative: boolean
            issue: string
            product: string
            state: string
          }
        | { __typename: 'GoogleNewsSearchResultMetadata'; title: string; date: any }
        | {
            __typename: 'RedditPost'
            id: string
            username: string
            url: string
            body?: string | null
            communityName: string
            redditCreatedAt: string
            upVotes: number
            numberOfComments?: number | null
          }
        | { __typename?: 'SARFilingMetadata' }
        | {
            __typename: 'TikTokPostMetadata'
            mediaUrls: Array<string>
            webVideoUrl: string
            videoMeta: { __typename?: 'TikTokProfileVideoMetadata'; coverUrl: string }
            authorMeta: { __typename?: 'TikTokPostAuthorMetadata'; name: string }
          }
        | {
            __typename: 'TrustpilotReview'
            title: string
            text: string
            id: string
            rating: number
            pending: boolean
            location?: string | null
            consumer: { __typename?: 'TrustpilotConsumer'; displayName: string }
          }
        | { __typename: 'WebsitePage'; canonicalUrl: string; screenshotUrl?: string | null }
        | null
    }
  }>
}

export type GetIssuesByPartnershipQueryVariables = Exact<{
  partnershipId: Scalars['String']['input']
}>

export type GetIssuesByPartnershipQuery = {
  __typename?: 'Query'
  issuesByPartnership: Array<{
    __typename?: 'IssueItemOutput'
    id: string
    ticketId: string
    title: string
    createdAt: any
    description: string
    status: IssueStatus
    level: string
    justification: string
    reasons: Array<{
      __typename?: 'IssueReason'
      title: string
      justification: string
      reference: string
      referenceUrl: string
    }>
    fragmentItem: {
      __typename?: 'PartnerSourceFragmentItemOutput'
      id: string
      type: string
      createdAt: any
      updatedAt: any
      partnerSource: { __typename?: 'PartnerSource'; name: SourceName; config: any }
      data?:
        | {
            __typename: 'CFPBComplaintMetadata'
            complaint_id: number
            company: string
            complaint_what_happened: string
            has_narrative: boolean
            issue: string
            product: string
            state: string
          }
        | { __typename: 'GoogleNewsSearchResultMetadata'; title: string; date: any }
        | {
            __typename: 'RedditPost'
            id: string
            username: string
            url: string
            body?: string | null
            communityName: string
            redditCreatedAt: string
            upVotes: number
            numberOfComments?: number | null
          }
        | { __typename?: 'SARFilingMetadata' }
        | {
            __typename: 'TikTokPostMetadata'
            mediaUrls: Array<string>
            webVideoUrl: string
            videoMeta: { __typename?: 'TikTokProfileVideoMetadata'; coverUrl: string }
            authorMeta: { __typename?: 'TikTokPostAuthorMetadata'; name: string }
          }
        | {
            __typename: 'TrustpilotReview'
            title: string
            text: string
            id: string
            rating: number
            pending: boolean
            location?: string | null
            consumer: { __typename?: 'TrustpilotConsumer'; displayName: string }
          }
        | { __typename: 'WebsitePage'; canonicalUrl: string; screenshotUrl?: string | null }
        | null
    }
  }>
}

export type GetAssetUploadUrlQueryVariables = Exact<{
  type?: InputMaybe<MimeType>
}>

export type GetAssetUploadUrlQuery = {
  __typename?: 'Query'
  assetUploadUrl: { __typename?: 'PresignedUrl'; signedUrl: string; fileKey: string }
}

export type GetNewAssetVersionUploadUrlQueryVariables = Exact<{
  id: Scalars['String']['input']
  mimeType: MimeType
}>

export type GetNewAssetVersionUploadUrlQuery = {
  __typename?: 'Query'
  assetVersionUpdateUrl: { __typename?: 'PresignedUrl'; signedUrl: string; fileKey: string }
}

export type GetAssetDownloadUrlQueryVariables = Exact<{
  id: Scalars['String']['input']
  versionId?: InputMaybe<Scalars['String']['input']>
}>

export type GetAssetDownloadUrlQuery = {
  __typename?: 'Query'
  assetDownloadUrl: { __typename?: 'PresignedUrl'; signedUrl: string; fileKey: string; mimeType?: string | null }
}

export type GetReviewRequestsQueryVariables = Exact<{
  assetId: Scalars['String']['input']
}>

export type GetReviewRequestsQuery = {
  __typename?: 'Query'
  reviewRequests: Array<{
    __typename?: 'ReviewRequest'
    id: string
    status: ReviewRequestStatus
    requesterUser: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      avatarUrl?: string | null
    }
  }>
}

export type GetAllRevisionsQueryVariables = Exact<{
  assetId: Scalars['String']['input']
}>

export type GetAllRevisionsQuery = {
  __typename?: 'Query'
  documentAssetRevisions: Array<{
    __typename?: 'DocumentAssetRevision'
    attributes?: string | null
    authorsIds?: Array<string> | null
    createdAt: any
    creatorId?: string | null
    fromVersion: number
    id: string
    name?: string | null
    toVersion: number
    externalId: string
    updatedAt: any
  }>
}

export type GetRevisionQueryVariables = Exact<{
  assetId: Scalars['String']['input']
  revisionId: Scalars['String']['input']
}>

export type GetRevisionQuery = {
  __typename?: 'Query'
  documentAssetRevision: {
    __typename?: 'DocumentAssetRevision'
    attributes?: string | null
    authorsIds?: Array<string> | null
    createdAt: any
    creatorId?: string | null
    fromVersion: number
    diffData?: string | null
    id: string
    name?: string | null
    toVersion: number
    externalId: string
    updatedAt: any
  }
}

export type GetProjectTaskQueryVariables = Exact<{
  taskId: Scalars['String']['input']
}>

export type GetProjectTaskQuery = {
  __typename?: 'Query'
  projectTask: {
    __typename?: 'ProjectTask'
    id: string
    name: string
    createdAt: any
    status: ProjectTaskStatus
    description?: string | null
    dueDate?: any | null
    archivedAt?: any | null
    priority: ProjectTaskPriority
    requirementId?: string | null
    partnershipId?: string | null
    asset?: {
      __typename?: 'Asset'
      id: string
      name: string
      key: string
      version: string
      originalMimeType: MimeType
      suggestionsJobRunning: boolean
    } | null
    assignee?: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      avatarUrl?: string | null
    } | null
    reviewer?: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      avatarUrl?: string | null
    } | null
    project: {
      __typename?: 'Project'
      id: string
      name: string
      users: Array<{
        __typename?: 'ProjectUser'
        id: string
        user: {
          __typename?: 'User'
          id: string
          firstName?: string | null
          lastName?: string | null
          avatarUrl?: string | null
        }
      }>
    }
  }
}

export type GetWorkflowTemplatesQueryVariables = Exact<{
  organizationId: Scalars['String']['input']
  onlyConstructed?: Scalars['Boolean']['input']
  projectId?: InputMaybe<Scalars['String']['input']>
  active?: InputMaybe<Scalars['Boolean']['input']>
  archived?: InputMaybe<Scalars['Boolean']['input']>
}>

export type GetWorkflowTemplatesQuery = {
  __typename?: 'Query'
  workflowTemplates: Array<{
    __typename?: 'WorkflowTemplate'
    id: string
    name: string
    description?: string | null
    createdAt: any
    status: WorkflowTemplateStatus
    active: boolean
    projectId?: string | null
    createdBy?: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      avatarUrl?: string | null
    } | null
    organization?: { __typename?: 'Organization'; id: string; name: string; logoUrl?: string | null } | null
  }>
}

export type GetWorkflowTemplateQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type GetWorkflowTemplateQuery = {
  __typename?: 'Query'
  workflowTemplate: {
    __typename?: 'WorkflowTemplate'
    id: string
    name: string
    description?: string | null
    projectId?: string | null
    rootNodeId?: string | null
    status: WorkflowTemplateStatus
    type: WorkflowTemplateType
    nodes: Array<{
      __typename?: 'WorkflowNodeTemplate'
      id: string
      name: string
      type: WorkflowNodeType
      createdAt: any
      updatedAt: any
      payload?: any | null
      children?: Array<{ __typename?: 'WorkflowNodeTemplate'; id: string; name: string }> | null
    }>
  }
}

export type OrganizationInvitationsQueryVariables = Exact<{ [key: string]: never }>

export type OrganizationInvitationsQuery = {
  __typename?: 'Query'
  organizationInvitations: Array<{
    __typename?: 'OrganizationInvitation'
    id: string
    email: string
    role: OrganizationUserRole
    uniqueId: string
    organization: { __typename?: 'Organization'; id: string; name: string; teamUrl: string; type: OrganizationType }
  }>
}

export type SentOrganizationInvitationsQueryVariables = Exact<{
  organizationId: Scalars['String']['input']
}>

export type SentOrganizationInvitationsQuery = {
  __typename?: 'Query'
  sentOrganizationInvitations: Array<{ __typename?: 'OrganizationInvitation'; id: string; email: string }>
}

export type GetOrganizationInvitationQueryVariables = Exact<{
  uniqueId: Scalars['String']['input']
}>

export type GetOrganizationInvitationQuery = {
  __typename?: 'Query'
  organizationInvitation: {
    __typename?: 'OrganizationInvitation'
    id: string
    email: string
    role: OrganizationUserRole
    uniqueId: string
    organization: { __typename?: 'Organization'; id: string; name: string; teamUrl: string; type: OrganizationType }
  }
}

export type GetProductsByOrganizationQueryVariables = Exact<{
  organizationId: Scalars['String']['input']
}>

export type GetProductsByOrganizationQuery = {
  __typename?: 'Query'
  getProductsByOrganization: Array<{
    __typename?: 'OrganizationProduct'
    deletedAt?: any | null
    activeAt?: any | null
    isActive: boolean
    product: { __typename?: 'Product'; id: string; name: ProductType }
  }>
}

export type GetProjectInvitationQueryVariables = Exact<{
  uniqueId: Scalars['String']['input']
}>

export type GetProjectInvitationQuery = {
  __typename?: 'Query'
  projectInvitation?: {
    __typename?: 'ProjectInvitation'
    id: string
    email: string
    role: ProjectUserRole
    uniqueId: string
    project: { __typename?: 'Project'; id: string; name: string; projectUrl: string }
  } | null
}

export type GetSpaceInvitationQueryVariables = Exact<{
  uniqueId: Scalars['String']['input']
}>

export type GetSpaceInvitationQuery = {
  __typename?: 'Query'
  spaceInvitation?: {
    __typename?: 'SpaceInvitation'
    id: string
    email: string
    role: SpaceUserRole
    uniqueId: string
    space: { __typename?: 'Space'; id: string; name: string; spaceUrl: string }
  } | null
}

export type GetSpaceInvitationsForSpaceQueryVariables = Exact<{
  input: GetSpaceInvitationsForSpaceInput
}>

export type GetSpaceInvitationsForSpaceQuery = {
  __typename?: 'Query'
  spaceInvitationsForSpace: Array<{
    __typename?: 'SpaceInvitation'
    id: string
    email: string
    role: SpaceUserRole
    uniqueId: string
    space: { __typename?: 'Space'; id: string; name: string; spaceUrl: string }
  }>
}

export type GetSpacesQueryVariables = Exact<{
  organizationId: Scalars['String']['input']
}>

export type GetSpacesQuery = {
  __typename?: 'Query'
  spaces: Array<{
    __typename: 'Space'
    id: string
    name: string
    description?: string | null
    spaceUrl: string
    icon: string
    iconColor: string
    owner: { __typename?: 'User'; id: string }
    organization: { __typename?: 'Organization'; id: string; name: string; teamUrl: string }
    projects: Array<{
      __typename?: 'Project'
      id: string
      name: string
      projectUrl: string
      icon: string
      iconColor: string
    }>
    users: Array<{ __typename?: 'SpaceUser'; user: { __typename?: 'User'; id: string } }>
  }>
}

export type GetSidebarQueryVariables = Exact<{
  organizationId: Scalars['String']['input']
}>

export type GetSidebarQuery = {
  __typename?: 'Query'
  spaces: Array<{
    __typename: 'Space'
    id: string
    name: string
    description?: string | null
    spaceUrl: string
    icon: string
    iconColor: string
    owner: { __typename?: 'User'; id: string }
    organization: { __typename?: 'Organization'; id: string; name: string; teamUrl: string }
    projects: Array<{
      __typename?: 'Project'
      id: string
      name: string
      projectUrl: string
      icon: string
      iconColor: string
    }>
    users: Array<{ __typename?: 'SpaceUser'; user: { __typename?: 'User'; id: string } }>
  }>
}

export type ProjectInvitationsForUserQueryVariables = Exact<{
  input: GetProjectInvitationsForUserInput
}>

export type ProjectInvitationsForUserQuery = {
  __typename?: 'Query'
  projectInvitationsForUser: Array<{
    __typename?: 'ProjectInvitation'
    id: string
    email: string
    role: ProjectUserRole
    uniqueId: string
    project: { __typename?: 'Project'; id: string; name: string; projectUrl: string }
  }>
}

export type ProjectInvitationsForProjectQueryVariables = Exact<{
  input: GetProjectInvitationsForProjectInput
}>

export type ProjectInvitationsForProjectQuery = {
  __typename?: 'Query'
  projectInvitationsForProject: Array<{
    __typename?: 'ProjectInvitation'
    id: string
    email: string
    role: ProjectUserRole
    uniqueId: string
    project: { __typename?: 'Project'; id: string; name: string; projectUrl: string }
  }>
}

export type SentProjectInvitationsQueryVariables = Exact<{
  projectId: Scalars['String']['input']
}>

export type SentProjectInvitationsQuery = {
  __typename?: 'Query'
  sentProjectInvitations: Array<{
    __typename?: 'ProjectInvitation'
    email: string
    uniqueId: string
    inviter: { __typename?: 'User'; id: string }
  }>
}

export type GetCommentThreadQueryVariables = Exact<{
  input: GetCommentThreadInput
}>

export type GetCommentThreadQuery = {
  __typename?: 'Query'
  commentThread: {
    __typename?: 'CommentThread'
    id: string
    externalId: string
    attributes?: string | null
    context?: string | null
    resolvedAt?: any | null
    resolvedBy?: { __typename?: 'User'; id: string } | null
    comments?: Array<{
      __typename?: 'Comment'
      id: string
      externalId: string
      content: string
      createdAt: any
      attributes?: string | null
      author: { __typename?: 'User'; id: string }
    }> | null
  }
}

export type GetIntegrationsQueryVariables = Exact<{
  organizationId: Scalars['String']['input']
}>

export type GetIntegrationsQuery = {
  __typename?: 'Query'
  integrations: Array<{
    __typename?: 'Integration'
    id: string
    createdAt: any
    deletedAt?: any | null
    integrationProviderId: IntegrationProvider
    isEnabled: boolean
    updatedAt: any
  }>
}

export type GetIntegrationQueryVariables = Exact<{
  organizationId: Scalars['String']['input']
  integrationProviderId: Scalars['String']['input']
}>

export type GetIntegrationQuery = {
  __typename?: 'Query'
  integration: {
    __typename?: 'Integration'
    id: string
    createdAt: any
    deletedAt?: any | null
    integrationProviderId: IntegrationProvider
    isEnabled: boolean
    updatedAt: any
  }
}

export type GetCommentThreadsQueryVariables = Exact<{
  input: GetCommentThreadsInput
}>

export type GetCommentThreadsQuery = {
  __typename?: 'Query'
  commentThreads: Array<{
    __typename?: 'CommentThread'
    id: string
    externalId: string
    attributes?: string | null
    context?: string | null
    resolvedAt?: any | null
    resolvedBy?: { __typename?: 'User'; id: string } | null
    comments?: Array<{
      __typename?: 'Comment'
      id: string
      externalId: string
      content: string
      createdAt: any
      attributes?: string | null
      author: { __typename?: 'User'; id: string }
    }> | null
  }>
}

export type GetNotificationPreferencesProjectQueryVariables = Exact<{
  projectId: Scalars['String']['input']
  userId: Scalars['String']['input']
}>

export type GetNotificationPreferencesProjectQuery = {
  __typename?: 'Query'
  notificationPreferencesProject?: {
    __typename?: 'NotificationPreferencesProject'
    id: string
    userId: string
    projectId: string
    notifyTasks: NotificationPreferencesProjectOptions
    notifyComments: NotificationPreferencesProjectOptions
    notifyStatusUpdates: NotificationPreferencesProjectStatusOptions
    notifyStatusFrequency?: NotificationPreferencesProjectFrequency | null
    notifyEmail: boolean
    notifySlack: boolean
    muteAll: boolean
    user: { __typename?: 'User'; id: string }
    project: { __typename?: 'Project'; id: string }
  } | null
}

export type GetSlackChannelsQueryVariables = Exact<{
  organizationId: Scalars['String']['input']
}>

export type GetSlackChannelsQuery = {
  __typename?: 'Query'
  slackChannels: Array<{ __typename?: 'SlackChannel'; id: string; name: string }>
}

export type GetSlackUsersQueryVariables = Exact<{
  organizationId: Scalars['String']['input']
}>

export type GetSlackUsersQuery = {
  __typename?: 'Query'
  slackUsers: Array<{ __typename?: 'SlackUser'; id: string; realName: string }>
}

export type GetNotificationPreferencesSpaceQueryVariables = Exact<{
  spaceId: Scalars['String']['input']
  userId: Scalars['String']['input']
}>

export type GetNotificationPreferencesSpaceQuery = {
  __typename?: 'Query'
  notificationPreferencesSpace?: {
    __typename?: 'NotificationPreferencesSpace'
    id: string
    spaceId: string
    userId: string
    notifyProjectCreated: boolean
    notifyAddedToProject: boolean
    notifyEmail: boolean
    notifySlack: boolean
    muteAll: boolean
    space: { __typename?: 'Space'; id: string; name: string; spaceUrl: string }
    user: { __typename?: 'User'; id: string; firstName?: string | null; lastName?: string | null; email: string }
  } | null
}

export type GetSlackChannelQueryVariables = Exact<{
  organizationId: Scalars['String']['input']
}>

export type GetSlackChannelQuery = {
  __typename?: 'Query'
  slackChannel: Array<{ __typename?: 'SlackChannel'; id: string; name: string }>
}

export type GetSlackTeamInfoQueryVariables = Exact<{
  organizationId: Scalars['String']['input']
}>

export type GetSlackTeamInfoQuery = {
  __typename?: 'Query'
  slackTeamInfo: {
    __typename?: 'SlackTeamInfo'
    id: string
    name: string
    url?: string | null
    domain: string
    emailDomain: string
    icon: string
  }
}

export type GetSlackDataChannelQueryVariables = Exact<{
  organizationId: Scalars['String']['input']
  collection: Scalars['String']['input']
  objectId: Scalars['String']['input']
}>

export type GetSlackDataChannelQuery = {
  __typename?: 'Query'
  slackDataChannel: {
    __typename?: 'SlackDataChannel'
    collection: KnockChannelCollections
    objectId: string
    id: string
    name: string
  }
}

export type GetNotificationPreferencesUserQueryVariables = Exact<{
  userId: Scalars['String']['input']
}>

export type GetNotificationPreferencesUserQuery = {
  __typename?: 'Query'
  notificationPreferencesUser: {
    __typename?: 'NotificationPreferencesUser'
    id: string
    userId: string
    notifyEmail: boolean
    notifySlack: boolean
    muteAll: boolean
    user: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      notificationPreferencesProject?: Array<{
        __typename?: 'NotificationPreferencesProject'
        id: string
        projectId: string
        notifyTasks: NotificationPreferencesProjectOptions
        notifyComments: NotificationPreferencesProjectOptions
        notifyStatusUpdates: NotificationPreferencesProjectStatusOptions
        notifyStatusFrequency?: NotificationPreferencesProjectFrequency | null
        notifyEmail: boolean
        notifySlack: boolean
        muteAll: boolean
      }> | null
      notificationPreferencesSpace?: Array<{
        __typename?: 'NotificationPreferencesSpace'
        id: string
        spaceId: string
        notifyProjectCreated: boolean
        notifyAddedToProject: boolean
        notifyEmail: boolean
        notifySlack: boolean
        muteAll: boolean
      }> | null
    }
  }
}

export type GetSuggestionsForAssetQueryVariables = Exact<{
  input: GetSuggestionsInput
}>

export type GetSuggestionsForAssetQuery = {
  __typename?: 'Query'
  suggestions: Array<{
    __typename?: 'Suggestion'
    id: string
    highlightedText: string
    reasonForChange: string
    highLevelReason: string
    potentialAlternateCopy?: string | null
    createdAt: any
    resolveType?: string | null
    createdBy?: { __typename?: 'User'; id: string; firstName?: string | null; lastName?: string | null } | null
    asset: {
      __typename?: 'Asset'
      id: string
      name: string
      key: string
      version: string
      user: { __typename?: 'User'; id: string }
    }
    metadata?: {
      __typename?: 'SuggestionMetadata'
      page_number: number
      coordinates: {
        __typename?: 'Coordinates'
        points: Array<Array<number>>
        layout_width: number
        layout_height: number
      }
    } | null
  }>
}

export type ResolveSuggestionQueryVariables = Exact<{
  input: ResolveSuggestionInput
}>

export type ResolveSuggestionQuery = { __typename?: 'Query'; resolveSuggestion: boolean }

export type GetOrganizationLogoUploadUrlQueryVariables = Exact<{
  input: GetLogoUploadUrlInput
}>

export type GetOrganizationLogoUploadUrlQuery = {
  __typename?: 'Query'
  logoUploadUrl: { __typename?: 'PresignedUrl'; signedUrl: string; fileKey: string }
}

export type GetTasksForUserAndOrganizationQueryVariables = Exact<{
  organizationId: Scalars['String']['input']
}>

export type GetTasksForUserAndOrganizationQuery = {
  __typename?: 'Query'
  getTasksForUserAndOrganization: Array<{
    __typename?: 'ProjectTask'
    id: string
    name: string
    status: ProjectTaskStatus
    description?: string | null
    dueDate?: any | null
    priority: ProjectTaskPriority
    createdAt: any
    updatedAt: any
    archivedAt?: any | null
    requirementId?: string | null
    partnershipId?: string | null
    reviewer?: { __typename?: 'User'; id: string } | null
    workflow: { __typename?: 'Workflow'; id: string; status: WorkflowStatus }
    assignee?: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      avatarUrl?: string | null
    } | null
    asset?: {
      __typename?: 'Asset'
      id: string
      name: string
      key: string
      version: string
      originalMimeType: MimeType
    } | null
    project: {
      __typename?: 'Project'
      id: string
      name: string
      projectUrl: string
      space: { __typename?: 'Space'; id: string; name: string; spaceUrl: string }
      users: Array<{
        __typename?: 'ProjectUser'
        id: string
        role: ProjectUserRole
        user: { __typename?: 'User'; id: string; firstName?: string | null; lastName?: string | null; email: string }
      }>
    }
  }>
}

export type GetAssetEditsQueryVariables = Exact<{
  assetId: Scalars['String']['input']
}>

export type GetAssetEditsQuery = {
  __typename?: 'Query'
  assetEdits: Array<{
    __typename?: 'AssetEdit'
    id: string
    addedText?: string | null
    removedText?: string | null
    fromVersionId: string
    toVersionId: string
    createdAt: any
    user: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      avatarUrl?: string | null
    }
  }>
}

export type ProjectTaskAttachmentDownloadUrlQueryVariables = Exact<{
  attachmentId: Scalars['String']['input']
}>

export type ProjectTaskAttachmentDownloadUrlQuery = {
  __typename?: 'Query'
  projectTaskAttachmentDownloadUrl: {
    __typename?: 'PresignedUrl'
    signedUrl: string
    fileKey: string
    mimeType?: string | null
  }
}

export type GetActivityTaskAttachmentDownloadUrlQueryVariables = Exact<{
  attachmentId: Scalars['String']['input']
}>

export type GetActivityTaskAttachmentDownloadUrlQuery = {
  __typename?: 'Query'
  activityTaskAttachmentDownloadUrl: {
    __typename?: 'PresignedUrl'
    signedUrl: string
    fileKey: string
    mimeType?: string | null
  }
}

export type GetActiveNodeForTaskQueryVariables = Exact<{
  taskId: Scalars['String']['input']
}>

export type GetActiveNodeForTaskQuery = {
  __typename?: 'Query'
  getActiveNodeForTask?: {
    __typename?: 'WorkflowNode'
    id: string
    name: string
    type: WorkflowNodeType
    payload?: any | null
    status: WorkflowNodeStatus
    children?: Array<{
      __typename?: 'WorkflowNode'
      id: string
      name: string
      type: WorkflowNodeType
      payload?: any | null
      status: WorkflowNodeStatus
    }> | null
    workflow: { __typename?: 'Workflow'; id: string; status: WorkflowStatus; name: string }
  } | null
}

export type GetAssetVersionsQueryVariables = Exact<{
  assetId: Scalars['String']['input']
}>

export type GetAssetVersionsQuery = {
  __typename?: 'Query'
  assetVersions: Array<{
    __typename?: '_AssetVersion'
    versionId: string
    lastModified: any
    size: number
    eTag: string
    isLatest?: boolean | null
    signedUrl?: string | null
    comments?: Array<any> | null
  }>
}

export type GetCurrentAssetVersionsQueryVariables = Exact<{
  assetId: Scalars['String']['input']
}>

export type GetCurrentAssetVersionsQuery = {
  __typename?: 'Query'
  currentAssetVersions: Array<{
    __typename?: 'AssetVersion'
    id: string
    roomId: string
    label: string
    mimeType: MimeType
    editableMimeType?: MimeType | null
    file: { __typename?: 'PresignedUrl'; fileKey: string; signedUrl: string; mimeType?: string | null }
    editableFile?: { __typename?: 'PresignedUrl'; fileKey: string; signedUrl: string; mimeType?: string | null } | null
  }>
}

export type GetProgramsForOrganizationQueryVariables = Exact<{
  organizationId: Scalars['String']['input']
}>

export type GetProgramsForOrganizationQuery = {
  __typename?: 'Query'
  getProgramsForOrganization: Array<{
    __typename?: 'Program'
    id: string
    productType: PartnerProductType
    programType: PartnerProgramType
    createdAt: any
    name: string
    description?: string | null
    isActive: boolean
    createdBy?: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      avatarUrl?: string | null
    } | null
    programRequirements: Array<{
      __typename?: 'ProgramRequirement'
      requirement: {
        __typename?: 'Requirement'
        id: string
        name: string
        description?: string | null
        category: RequirementCategory
        mimeTypes?: Array<MimeType> | null
      }
    }>
  }>
}

export type GetProgramByIdQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type GetProgramByIdQuery = {
  __typename?: 'Query'
  program: Array<{
    __typename?: 'Program'
    id: string
    productType: PartnerProductType
    programType: PartnerProgramType
    createdAt: any
    name: string
    description?: string | null
    isActive: boolean
    createdBy?: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      avatarUrl?: string | null
    } | null
    programRequirements: Array<{
      __typename?: 'ProgramRequirement'
      requirement: {
        __typename?: 'Requirement'
        id: string
        name: string
        description?: string | null
        category: RequirementCategory
        mimeTypes?: Array<MimeType> | null
      }
    }>
  }>
}

export type GetPartnershipsForOrganizationQueryVariables = Exact<{
  organizationId: Scalars['String']['input']
}>

export type GetPartnershipsForOrganizationQuery = {
  __typename?: 'Query'
  getPartnershipsForOrganization: Array<{
    __typename?: 'Partnership'
    id: string
    createdAt: any
    updatedAt: any
    stage: PartnershipStage
    logoUrl?: string | null
    healthScore?: number | null
    owner?: {
      __typename?: 'User'
      id: string
      email: string
      firstName?: string | null
      lastName?: string | null
      avatarUrl?: string | null
    } | null
    partnerOrganization: {
      __typename?: 'Organization'
      id: string
      name: string
      metadata?: any | null
      type: OrganizationType
    }
    program: {
      __typename?: 'Program'
      id: string
      name: string
      description?: string | null
      programType: PartnerProgramType
      productType: PartnerProductType
      isActive: boolean
    }
  }>
}

export type GetProjectByIdQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type GetProjectByIdQuery = {
  __typename?: 'Query'
  project: {
    __typename?: 'Project'
    id: string
    name: string
    projectUrl: string
    description?: string | null
    isPublic: boolean
    createdAt: any
    icon: string
    iconColor: string
    createdBy?: { __typename?: 'User'; firstName?: string | null; lastName?: string | null; id: string } | null
    space: { __typename?: 'Space'; id: string; name: string; spaceUrl: string }
    tasks?: Array<{
      __typename?: 'ProjectTask'
      id: string
      name: string
      description?: string | null
      dueDate?: any | null
      priority: ProjectTaskPriority
      status: ProjectTaskStatus
      archivedAt?: any | null
      updatedAt: any
      createdAt: any
      asset?: {
        __typename?: 'Asset'
        id: string
        name: string
        key: string
        version: string
        originalMimeType: MimeType
      } | null
      assignee?: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        avatarUrl?: string | null
      } | null
      reviewer?: { __typename?: 'User'; id: string; firstName?: string | null; lastName?: string | null } | null
      comments?: Array<{
        __typename?: 'ProjectTaskComment'
        id: string
        content: string
        createdAt: any
        author: {
          __typename?: 'User'
          id: string
          firstName?: string | null
          lastName?: string | null
          avatarUrl?: string | null
          email: string
        }
      }> | null
      attachments?: Array<{ __typename?: 'ProjectTaskAttachment'; id: string; fileKey: string; name: string }> | null
    }> | null
    users: Array<{
      __typename?: 'ProjectUser'
      id: string
      role: ProjectUserRole
      user: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        email: string
        avatarUrl?: string | null
      }
    }>
  }
}

export type RequirementCategorySummariesQueryVariables = Exact<{
  partnershipId: Scalars['String']['input']
}>

export type RequirementCategorySummariesQuery = {
  __typename?: 'Query'
  requirementCategories: Array<{
    __typename?: 'RequirementCategorySummary'
    category: RequirementCategory
    documentCount: number
    inProgressDocumentCount: number
    completedDocumentCount: number
    status: RequirementCategoryStatus
    programId: string
    progress: number
    program: { __typename?: 'Program'; id: string; createdAt: any; name: string; description?: string | null }
    tasks: Array<{
      __typename?: 'ProjectTask'
      id: string
      name: string
      description?: string | null
      status: ProjectTaskStatus
      dueDate?: any | null
      archivedAt?: any | null
      assignee?: {
        __typename?: 'User'
        id: string
        avatarUrl?: string | null
        firstName?: string | null
        lastName?: string | null
      } | null
      asset?: {
        __typename?: 'Asset'
        id: string
        name: string
        key: string
        version: string
        originalMimeType: MimeType
      } | null
      requirement?: {
        __typename?: 'Requirement'
        id: string
        name: string
        description?: string | null
        category: RequirementCategory
        mimeTypes?: Array<MimeType> | null
      } | null
    }>
  }>
}

export type SearchQueryVariables = Exact<{
  input: SearchFilters
  organizationId: Scalars['String']['input']
  pagination: Pagination
}>

export type SearchQuery = {
  __typename?: 'Query'
  search: {
    __typename?: 'SearchResult'
    data: Array<{
      __typename?: 'SearchResultData'
      link: string
      object:
        | {
            __typename: 'Asset'
            id: string
            createdAt: any
            updatedAt: any
            deletedAt?: any | null
            name: string
            key: string
            version: string
            type: AssetType
            documentType: NarrativeDocumentType
            state: AssetState
            originalMimeType: MimeType
            user: {
              __typename?: 'User'
              id: string
              email: string
              firstName?: string | null
              lastName?: string | null
              avatarUrl?: string | null
            }
          }
        | {
            __typename: 'Organization'
            id: string
            createdAt: any
            updatedAt: any
            deletedAt?: any | null
            name: string
            teamUrl: string
            teamSize?: string | null
            metadata?: any | null
          }
        | {
            __typename: 'Project'
            id: string
            createdAt: any
            updatedAt: any
            deletedAt?: any | null
            name: string
            projectUrl: string
            description?: string | null
            isPublic: boolean
            createdBy?: {
              __typename?: 'User'
              id: string
              firstName?: string | null
              lastName?: string | null
              email: string
            } | null
            space: { __typename?: 'Space'; name: string }
          }
        | {
            __typename: 'Space'
            id: string
            name: string
            description?: string | null
            spaceUrl: string
            createdAt: any
            updatedAt: any
            deletedAt?: any | null
            owner: { __typename?: 'User'; id: string }
            organization: { __typename?: 'Organization'; id: string; name: string; teamUrl: string }
            projects: Array<{
              __typename?: 'Project'
              id: string
              name: string
              projectUrl: string
              icon: string
              iconColor: string
            }>
            users: Array<{ __typename?: 'SpaceUser'; user: { __typename?: 'User'; id: string } }>
          }
    }>
    meta: {
      __typename?: 'PaginationMeta'
      totalItems: number
      itemsPerPage: number
      totalPages: number
      currentPage: number
    }
  }
}

export type FilterAuditTrailQueryVariables = Exact<{
  input: FilterAuditTrailInput
}>

export type FilterAuditTrailQuery = {
  __typename?: 'Query'
  filterAuditTrail: Array<{
    __typename: 'AuditTrail'
    id: string
    organizationId?: string | null
    createdAt: any
    contextId?: string | null
    action: string
    entityId: string
    entityType: AuditTrailEntity
    sourceId?: string | null
    sourceType?: AuditTrailSource | null
    metadata?: any | null
  }>
}

export type FilterAuditTrailByEntityIdsQueryVariables = Exact<{
  input: FilterAuditTrailByEntityIdsInput
}>

export type FilterAuditTrailByEntityIdsQuery = {
  __typename?: 'Query'
  filterAuditTrailByEntityIds: Array<{
    __typename: 'AuditTrail'
    id: string
    organizationId?: string | null
    createdAt: any
    contextId?: string | null
    action: string
    entityId: string
    entityType: AuditTrailEntity
    sourceId?: string | null
    sourceType?: AuditTrailSource | null
    metadata?: any | null
  }>
}

export type GetProjectTaskAuditTrailByWorkflowTemplateQueryVariables = Exact<{
  organizationId: Scalars['String']['input']
  workflowTemplateId: Scalars['String']['input']
}>

export type GetProjectTaskAuditTrailByWorkflowTemplateQuery = {
  __typename?: 'Query'
  getProjectTaskAuditTrailByWorkflowTemplate: Array<{
    __typename: 'AuditTrail'
    id: string
    organizationId?: string | null
    createdAt: any
    contextId?: string | null
    action: string
    entityId: string
    entityType: AuditTrailEntity
    sourceId?: string | null
    sourceType?: AuditTrailSource | null
    metadata?: any | null
  }>
}

export type GetProcessQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type GetProcessQuery = {
  __typename?: 'Query'
  process: {
    __typename: 'Process'
    id: string
    status: ProcessStatus
    access: ProcessAccess
    title: string
    description: string
    createdAt: any
    updatedAt: any
    deletedAt?: any | null
    space: { __typename?: 'Space'; id: string; name: string; icon: string; iconColor: string }
    members?: Array<{
      __typename: 'ProcessMember'
      id: string
      user: {
        __typename?: 'User'
        id: string
        email: string
        firstName?: string | null
        lastName?: string | null
        avatarUrl?: string | null
      }
    }> | null
    fields?: Array<{
      __typename: 'ProcessField'
      id: string
      title: string
      description: string
      type: FormFieldType
      isRequired: boolean
      options?: any | null
    }> | null
  }
}

export type GetProcessesQueryVariables = Exact<{
  organizationId: Scalars['String']['input']
}>

export type GetProcessesQuery = {
  __typename?: 'Query'
  processes: Array<{
    __typename: 'Process'
    id: string
    status: ProcessStatus
    access: ProcessAccess
    title: string
    description: string
    createdAt: any
    updatedAt: any
    deletedAt?: any | null
    space: { __typename?: 'Space'; id: string; name: string; icon: string; iconColor: string }
    members?: Array<{
      __typename: 'ProcessMember'
      id: string
      user: {
        __typename?: 'User'
        id: string
        email: string
        firstName?: string | null
        lastName?: string | null
        avatarUrl?: string | null
      }
    }> | null
    fields?: Array<{
      __typename: 'ProcessField'
      id: string
      title: string
      description: string
      type: FormFieldType
      isRequired: boolean
      options?: any | null
    }> | null
  }>
}

export type GetSponsorQueryVariables = Exact<{
  input: GetSponsorInput
}>

export type GetSponsorQuery = { __typename?: 'Query'; getSponsorOrg: { __typename?: 'Partnership'; id: string } }

export type GetPartnerSourcesForPartnershipQueryVariables = Exact<{
  partnershipId: Scalars['String']['input']
}>

export type GetPartnerSourcesForPartnershipQuery = {
  __typename?: 'Query'
  getAllForPartnershipId: Array<{
    __typename?: 'PartnerSource'
    id: string
    createdAt: any
    config: any
    name: SourceName
    type: SourceType
    syncedAt?: any | null
  }>
}

export type GetPartnerSourcesWithCountsForPartnershipQueryVariables = Exact<{
  partnershipId: Scalars['String']['input']
}>

export type GetPartnerSourcesWithCountsForPartnershipQuery = {
  __typename?: 'Query'
  getAllWithCountsForPartnershipId: Array<{
    __typename?: 'PartnerSourceWithCounts'
    id: string
    name: SourceName
    createdAt: any
    config: any
    syncedAt?: any | null
    fragmentCount: number
    issueCount: number
  }>
}

export type GetPartnerSourceByIdQueryVariables = Exact<{
  partnerSourceId: Scalars['String']['input']
}>

export type GetPartnerSourceByIdQuery = {
  __typename?: 'Query'
  getPartnerSourceById: {
    __typename?: 'PartnerSource'
    id: string
    name: SourceName
    createdAt: any
    config: any
    type: SourceType
    syncedAt?: any | null
  }
}

export type GetRuleByPartnerSourceQueryVariables = Exact<{
  partnerSourceId: Scalars['String']['input']
}>

export type GetRuleByPartnerSourceQuery = {
  __typename?: 'Query'
  rulesByPartnerSource: Array<{
    __typename?: 'Rule'
    id: string
    name: string
    conditionGroups?: Array<{
      __typename?: 'ConditionGroup'
      conditions: Array<{
        __typename?: 'RegulationCondition'
        operator: ComparisonOperator
        value: string
        regulation: Regulation
      }>
    }> | null
  }>
}

export type GetActivitiesQueryVariables = Exact<{ [key: string]: never }>

export type GetActivitiesQuery = {
  __typename?: 'Query'
  getActivities: Array<{
    __typename?: 'Activity'
    id: string
    name: string
    description: string
    updateFrequency: UpdateFrequency
    responsibleParty: ResponsibleParty
    active: boolean
    createdAt: any
    updatedAt: any
    topic: { __typename?: 'Topic'; id: string; name: string; description?: string | null; createdAt: any }
    subtopic: { __typename?: 'Subtopic'; id: string; name: string; description?: string | null; createdAt: any }
  }>
}

export type ActivityQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type ActivityQuery = {
  __typename?: 'Query'
  activity: {
    __typename?: 'Activity'
    id: string
    name: string
    description: string
    updateFrequency: UpdateFrequency
    responsibleParty: ResponsibleParty
    active: boolean
    createdAt: any
    updatedAt: any
    topic: { __typename?: 'Topic'; id: string; name: string; description?: string | null; createdAt: any }
    subtopic: { __typename?: 'Subtopic'; id: string; name: string; description?: string | null; createdAt: any }
  }
}

export type OrganizationsByTypeQueryVariables = Exact<{
  type: Scalars['String']['input']
}>

export type OrganizationsByTypeQuery = {
  __typename?: 'Query'
  organizationsByType: Array<{
    __typename?: 'Organization'
    id: string
    name: string
    teamUrl: string
    logoUrl?: string | null
    metadata?: any | null
    type: OrganizationType
    organizationProducts: Array<{
      __typename?: 'OrganizationProduct'
      product: { __typename?: 'Product'; id: string; name: ProductType }
    }>
  }>
}

export type GetAdminBankReportQueryVariables = Exact<{ [key: string]: never }>

export type GetAdminBankReportQuery = {
  __typename?: 'Query'
  getAdminBankReport: {
    __typename?: 'MosaicAdminReport'
    id: string
    description: string
    reports: Array<{
      __typename?: 'MosaicBankHealthReport'
      id: string
      name: string
      overallHealth: string
      topics: Array<{ __typename?: 'TopicStatus'; id: string; name: string; healthStatus: ComplianceHealthStatus }>
    }>
  }
}

export type GetAllMosaicPartnershipsQueryVariables = Exact<{ [key: string]: never }>

export type GetAllMosaicPartnershipsQuery = {
  __typename?: 'Query'
  getAllMosaicPartnerships: Array<{
    __typename?: 'MosaicPartnership'
    id: string
    partnerOrganization: { __typename?: 'Organization'; id: string; name: string }
    sponsorOrganization: { __typename?: 'Organization'; id: string; name: string }
  }>
}

export type GetMosaicPartnershipsForOrganizationQueryVariables = Exact<{
  input: GetSponsorshipsInput
}>

export type GetMosaicPartnershipsForOrganizationQuery = {
  __typename?: 'Query'
  getMosaicPartnershipsForOrganization: Array<{
    __typename?: 'MosaicPartnership'
    id: string
    productType: PartnerProductType
    programType: PartnerProgramType
    partnerOrganization: { __typename?: 'Organization'; id: string; name: string }
  }>
}

export type GetActivitySubscriptionsForActivityQueryVariables = Exact<{
  id: Scalars['String']['input']
  organizationType?: InputMaybe<OrganizationType>
  sourceBankId?: InputMaybe<Scalars['String']['input']>
}>

export type GetActivitySubscriptionsForActivityQuery = {
  __typename?: 'Query'
  getActivitySubscriptionsForActivity: Array<{
    __typename?: 'ActivitySubscription'
    id: string
    dueDate?: any | null
    description?: string | null
    status: ActivitySubscriptionStatus
    inheritedFromBank: boolean
    optedOut: boolean
    createdAt: any
    updatedAt: any
    deletedAt?: any | null
    activity: { __typename?: 'Activity'; id: string; name: string; description: string }
    organization: { __typename?: 'Organization'; id: string; name: string; type: OrganizationType }
    sourceBank?: { __typename?: 'Organization'; id: string; name: string; type: OrganizationType } | null
  }>
}

export type GetSubscriptionsForOrganizationQueryVariables = Exact<{
  orgId: Scalars['String']['input']
}>

export type GetSubscriptionsForOrganizationQuery = {
  __typename?: 'Query'
  getSubscriptionsForOrganization: Array<{
    __typename?: 'ActivitySubscription'
    id: string
    activity: { __typename?: 'Activity'; id: string }
  }>
}

export type ActivityTaskQueryVariables = Exact<{
  taskId: Scalars['String']['input']
}>

export type ActivityTaskQuery = {
  __typename?: 'Query'
  activityTask: {
    __typename: 'ActivityTask'
    createdAt: any
    deletedAt?: any | null
    description?: string | null
    dueDate: any
    id: string
    submittingUserExplanation?: string | null
    approvalUserExplanation?: string | null
    submissionStatus: ActivityTaskSubmissionStatus
    submittedAt?: any | null
    updatedAt: any
    summary?: string | null
    assignedTo?: {
      __typename?: 'OrganizationUser'
      id: string
      user: { __typename?: 'User'; firstName?: string | null; lastName?: string | null; avatarUrl?: string | null }
    } | null
    submittedBy?: {
      __typename?: 'OrganizationUser'
      id: string
      user: { __typename?: 'User'; firstName?: string | null; lastName?: string | null; avatarUrl?: string | null }
    } | null
    approvedBy?: {
      __typename?: 'OrganizationUser'
      id: string
      user: { __typename?: 'User'; firstName?: string | null; lastName?: string | null; avatarUrl?: string | null }
    } | null
    subscription: {
      __typename?: 'ActivitySubscription'
      id: string
      optedOut: boolean
      activity: {
        __typename?: 'Activity'
        id: string
        name: string
        description: string
        responsibleParty: ResponsibleParty
        topic: { __typename?: 'Topic'; id: string; name: string }
        subtopic: { __typename?: 'Subtopic'; id: string; name: string }
      }
      organization: { __typename?: 'Organization'; id: string; name: string }
      sourceBank?: { __typename?: 'Organization'; id: string; name: string } | null
    }
    comments?: Array<{
      __typename?: 'ActivityTaskComment'
      id: string
      content: string
      createdAt: any
      author: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        avatarUrl?: string | null
        email: string
      }
    }> | null
    assets?: Array<{
      __typename?: 'Asset'
      id: string
      name: string
      key: string
      version: string
      originalMimeType: MimeType
      createdAt: any
      user: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        avatarUrl?: string | null
      }
    }> | null
    attachments?: Array<{ __typename?: 'ActivityTaskAttachment'; id: string; name: string; fileKey: string }> | null
  }
}

export type GetActivityTasksForSubscriberQueryVariables = Exact<{
  input: GetActivityTasksInput
}>

export type GetActivityTasksForSubscriberQuery = {
  __typename?: 'Query'
  getActivityTasksForSubscriber: Array<{
    __typename: 'ActivityTask'
    createdAt: any
    deletedAt?: any | null
    description?: string | null
    dueDate: any
    id: string
    submittingUserExplanation?: string | null
    approvalUserExplanation?: string | null
    submissionStatus: ActivityTaskSubmissionStatus
    submittedAt?: any | null
    updatedAt: any
    summary?: string | null
    assignedTo?: {
      __typename?: 'OrganizationUser'
      id: string
      user: { __typename?: 'User'; firstName?: string | null; lastName?: string | null; avatarUrl?: string | null }
    } | null
    submittedBy?: {
      __typename?: 'OrganizationUser'
      id: string
      user: { __typename?: 'User'; firstName?: string | null; lastName?: string | null; avatarUrl?: string | null }
    } | null
    approvedBy?: {
      __typename?: 'OrganizationUser'
      id: string
      user: { __typename?: 'User'; firstName?: string | null; lastName?: string | null; avatarUrl?: string | null }
    } | null
    subscription: {
      __typename?: 'ActivitySubscription'
      id: string
      optedOut: boolean
      activity: {
        __typename?: 'Activity'
        id: string
        name: string
        description: string
        responsibleParty: ResponsibleParty
        topic: { __typename?: 'Topic'; id: string; name: string }
        subtopic: { __typename?: 'Subtopic'; id: string; name: string }
      }
      organization: { __typename?: 'Organization'; id: string; name: string }
      sourceBank?: { __typename?: 'Organization'; id: string; name: string } | null
    }
    comments?: Array<{
      __typename?: 'ActivityTaskComment'
      id: string
      content: string
      createdAt: any
      author: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        avatarUrl?: string | null
        email: string
      }
    }> | null
    assets?: Array<{
      __typename?: 'Asset'
      id: string
      name: string
      key: string
      version: string
      originalMimeType: MimeType
      createdAt: any
      user: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        avatarUrl?: string | null
      }
    }> | null
    attachments?: Array<{ __typename?: 'ActivityTaskAttachment'; id: string; name: string; fileKey: string }> | null
  }>
}

export type GetActivityTasksForTodoQueryVariables = Exact<{
  input: GetActivityTasksInput
}>

export type GetActivityTasksForTodoQuery = {
  __typename?: 'Query'
  getActivityTasksForTodo: Array<{
    __typename: 'ActivityTask'
    createdAt: any
    deletedAt?: any | null
    description?: string | null
    dueDate: any
    id: string
    submittingUserExplanation?: string | null
    approvalUserExplanation?: string | null
    submissionStatus: ActivityTaskSubmissionStatus
    submittedAt?: any | null
    updatedAt: any
    summary?: string | null
    assignedTo?: {
      __typename?: 'OrganizationUser'
      id: string
      user: { __typename?: 'User'; firstName?: string | null; lastName?: string | null; avatarUrl?: string | null }
    } | null
    submittedBy?: {
      __typename?: 'OrganizationUser'
      id: string
      user: { __typename?: 'User'; firstName?: string | null; lastName?: string | null; avatarUrl?: string | null }
    } | null
    approvedBy?: {
      __typename?: 'OrganizationUser'
      id: string
      user: { __typename?: 'User'; firstName?: string | null; lastName?: string | null; avatarUrl?: string | null }
    } | null
    subscription: {
      __typename?: 'ActivitySubscription'
      id: string
      optedOut: boolean
      activity: {
        __typename?: 'Activity'
        id: string
        name: string
        description: string
        responsibleParty: ResponsibleParty
        topic: { __typename?: 'Topic'; id: string; name: string }
        subtopic: { __typename?: 'Subtopic'; id: string; name: string }
      }
      organization: { __typename?: 'Organization'; id: string; name: string }
      sourceBank?: { __typename?: 'Organization'; id: string; name: string } | null
    }
    comments?: Array<{
      __typename?: 'ActivityTaskComment'
      id: string
      content: string
      createdAt: any
      author: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        avatarUrl?: string | null
        email: string
      }
    }> | null
    assets?: Array<{
      __typename?: 'Asset'
      id: string
      name: string
      key: string
      version: string
      originalMimeType: MimeType
      createdAt: any
      user: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        avatarUrl?: string | null
      }
    }> | null
    attachments?: Array<{ __typename?: 'ActivityTaskAttachment'; id: string; name: string; fileKey: string }> | null
  }>
}

export type GetActivityTasksForPartnerTodoQueryVariables = Exact<{
  input: GetActivityTasksInput
}>

export type GetActivityTasksForPartnerTodoQuery = {
  __typename?: 'Query'
  getActivityTasksForPartnerTodo: Array<{
    __typename: 'ActivityTask'
    createdAt: any
    deletedAt?: any | null
    description?: string | null
    dueDate: any
    id: string
    submittingUserExplanation?: string | null
    approvalUserExplanation?: string | null
    submissionStatus: ActivityTaskSubmissionStatus
    submittedAt?: any | null
    updatedAt: any
    summary?: string | null
    assignedTo?: {
      __typename?: 'OrganizationUser'
      id: string
      user: { __typename?: 'User'; firstName?: string | null; lastName?: string | null; avatarUrl?: string | null }
    } | null
    submittedBy?: {
      __typename?: 'OrganizationUser'
      id: string
      user: { __typename?: 'User'; firstName?: string | null; lastName?: string | null; avatarUrl?: string | null }
    } | null
    approvedBy?: {
      __typename?: 'OrganizationUser'
      id: string
      user: { __typename?: 'User'; firstName?: string | null; lastName?: string | null; avatarUrl?: string | null }
    } | null
    subscription: {
      __typename?: 'ActivitySubscription'
      id: string
      optedOut: boolean
      activity: {
        __typename?: 'Activity'
        id: string
        name: string
        description: string
        responsibleParty: ResponsibleParty
        topic: { __typename?: 'Topic'; id: string; name: string }
        subtopic: { __typename?: 'Subtopic'; id: string; name: string }
      }
      organization: { __typename?: 'Organization'; id: string; name: string }
      sourceBank?: { __typename?: 'Organization'; id: string; name: string } | null
    }
    comments?: Array<{
      __typename?: 'ActivityTaskComment'
      id: string
      content: string
      createdAt: any
      author: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        avatarUrl?: string | null
        email: string
      }
    }> | null
    assets?: Array<{
      __typename?: 'Asset'
      id: string
      name: string
      key: string
      version: string
      originalMimeType: MimeType
      createdAt: any
      user: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        avatarUrl?: string | null
      }
    }> | null
    attachments?: Array<{ __typename?: 'ActivityTaskAttachment'; id: string; name: string; fileKey: string }> | null
  }>
}

export type GetActivityTasksForFintechQueryVariables = Exact<{
  input: GetActivityTasksInput
}>

export type GetActivityTasksForFintechQuery = {
  __typename?: 'Query'
  getActivityTasksForFintech: Array<{
    __typename: 'ActivityTask'
    createdAt: any
    deletedAt?: any | null
    description?: string | null
    dueDate: any
    id: string
    submittingUserExplanation?: string | null
    approvalUserExplanation?: string | null
    submissionStatus: ActivityTaskSubmissionStatus
    submittedAt?: any | null
    updatedAt: any
    summary?: string | null
    assignedTo?: {
      __typename?: 'OrganizationUser'
      id: string
      user: { __typename?: 'User'; firstName?: string | null; lastName?: string | null; avatarUrl?: string | null }
    } | null
    submittedBy?: {
      __typename?: 'OrganizationUser'
      id: string
      user: { __typename?: 'User'; firstName?: string | null; lastName?: string | null; avatarUrl?: string | null }
    } | null
    approvedBy?: {
      __typename?: 'OrganizationUser'
      id: string
      user: { __typename?: 'User'; firstName?: string | null; lastName?: string | null; avatarUrl?: string | null }
    } | null
    subscription: {
      __typename?: 'ActivitySubscription'
      id: string
      optedOut: boolean
      activity: {
        __typename?: 'Activity'
        id: string
        name: string
        description: string
        responsibleParty: ResponsibleParty
        topic: { __typename?: 'Topic'; id: string; name: string }
        subtopic: { __typename?: 'Subtopic'; id: string; name: string }
      }
      organization: { __typename?: 'Organization'; id: string; name: string }
      sourceBank?: { __typename?: 'Organization'; id: string; name: string } | null
    }
    comments?: Array<{
      __typename?: 'ActivityTaskComment'
      id: string
      content: string
      createdAt: any
      author: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        avatarUrl?: string | null
        email: string
      }
    }> | null
    assets?: Array<{
      __typename?: 'Asset'
      id: string
      name: string
      key: string
      version: string
      originalMimeType: MimeType
      createdAt: any
      user: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        avatarUrl?: string | null
      }
    }> | null
    attachments?: Array<{ __typename?: 'ActivityTaskAttachment'; id: string; name: string; fileKey: string }> | null
  }>
}

export type GetActivityTasksByFilterQueryVariables = Exact<{
  input: GetActivityTasksInput
}>

export type GetActivityTasksByFilterQuery = {
  __typename?: 'Query'
  getActivityTasksByFilter: Array<{
    __typename: 'ActivityTask'
    createdAt: any
    deletedAt?: any | null
    description?: string | null
    dueDate: any
    id: string
    submittingUserExplanation?: string | null
    approvalUserExplanation?: string | null
    submissionStatus: ActivityTaskSubmissionStatus
    submittedAt?: any | null
    updatedAt: any
    summary?: string | null
    assignedTo?: {
      __typename?: 'OrganizationUser'
      id: string
      user: { __typename?: 'User'; firstName?: string | null; lastName?: string | null; avatarUrl?: string | null }
    } | null
    submittedBy?: {
      __typename?: 'OrganizationUser'
      id: string
      user: { __typename?: 'User'; firstName?: string | null; lastName?: string | null; avatarUrl?: string | null }
    } | null
    approvedBy?: {
      __typename?: 'OrganizationUser'
      id: string
      user: { __typename?: 'User'; firstName?: string | null; lastName?: string | null; avatarUrl?: string | null }
    } | null
    subscription: {
      __typename?: 'ActivitySubscription'
      id: string
      optedOut: boolean
      activity: {
        __typename?: 'Activity'
        id: string
        name: string
        description: string
        responsibleParty: ResponsibleParty
        topic: { __typename?: 'Topic'; id: string; name: string }
        subtopic: { __typename?: 'Subtopic'; id: string; name: string }
      }
      organization: { __typename?: 'Organization'; id: string; name: string }
      sourceBank?: { __typename?: 'Organization'; id: string; name: string } | null
    }
    comments?: Array<{
      __typename?: 'ActivityTaskComment'
      id: string
      content: string
      createdAt: any
      author: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        avatarUrl?: string | null
        email: string
      }
    }> | null
    assets?: Array<{
      __typename?: 'Asset'
      id: string
      name: string
      key: string
      version: string
      originalMimeType: MimeType
      createdAt: any
      user: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        avatarUrl?: string | null
      }
    }> | null
    attachments?: Array<{ __typename?: 'ActivityTaskAttachment'; id: string; name: string; fileKey: string }> | null
  }>
}

export type GetActivityTasksStatusCountQueryVariables = Exact<{
  input: ActivityStatusCountInput
}>

export type GetActivityTasksStatusCountQuery = {
  __typename?: 'Query'
  getActivityTasksByStatusCount: Array<{
    __typename?: 'ActivityTaskCountByStatus'
    status: ActivityTaskSubmissionStatus
    count: number
  }>
}

export type GetReportQueryVariables = Exact<{
  input: CreateMosaicReportInput
}>

export type GetReportQuery = {
  __typename?: 'Query'
  getReport: {
    __typename?: 'MosaicReportOutput'
    type: MosaicReportType
    report?:
      | {
          __typename: 'MosaicActivitiesReport'
          id: string
          description: string
          reports: Array<{
            __typename?: 'MosaicActivityReport'
            id: string
            description: string
            activity: {
              __typename?: 'Activity'
              id: string
              name: string
              description: string
              responsibleParty: ResponsibleParty
              updateFrequency: UpdateFrequency
              topic: { __typename?: 'Topic'; name: string }
              subtopic: { __typename?: 'Subtopic'; name: string }
            }
            items: Array<{
              __typename?: 'MosaicReportItem'
              id: string
              complianceStatus: ComplianceStatus
              partnerName: string
              activityTasks: Array<{
                __typename: 'ActivityTask'
                createdAt: any
                deletedAt?: any | null
                description?: string | null
                dueDate: any
                id: string
                submittingUserExplanation?: string | null
                approvalUserExplanation?: string | null
                submissionStatus: ActivityTaskSubmissionStatus
                submittedAt?: any | null
                updatedAt: any
                summary?: string | null
                assignedTo?: {
                  __typename?: 'OrganizationUser'
                  id: string
                  user: {
                    __typename?: 'User'
                    firstName?: string | null
                    lastName?: string | null
                    avatarUrl?: string | null
                  }
                } | null
                submittedBy?: {
                  __typename?: 'OrganizationUser'
                  id: string
                  user: {
                    __typename?: 'User'
                    firstName?: string | null
                    lastName?: string | null
                    avatarUrl?: string | null
                  }
                } | null
                approvedBy?: {
                  __typename?: 'OrganizationUser'
                  id: string
                  user: {
                    __typename?: 'User'
                    firstName?: string | null
                    lastName?: string | null
                    avatarUrl?: string | null
                  }
                } | null
                subscription: {
                  __typename?: 'ActivitySubscription'
                  id: string
                  optedOut: boolean
                  activity: {
                    __typename?: 'Activity'
                    id: string
                    name: string
                    description: string
                    responsibleParty: ResponsibleParty
                    topic: { __typename?: 'Topic'; id: string; name: string }
                    subtopic: { __typename?: 'Subtopic'; id: string; name: string }
                  }
                  organization: { __typename?: 'Organization'; id: string; name: string }
                  sourceBank?: { __typename?: 'Organization'; id: string; name: string } | null
                }
                comments?: Array<{
                  __typename?: 'ActivityTaskComment'
                  id: string
                  content: string
                  createdAt: any
                  author: {
                    __typename?: 'User'
                    id: string
                    firstName?: string | null
                    lastName?: string | null
                    avatarUrl?: string | null
                    email: string
                  }
                }> | null
                assets?: Array<{
                  __typename?: 'Asset'
                  id: string
                  name: string
                  key: string
                  version: string
                  originalMimeType: MimeType
                  createdAt: any
                  user: {
                    __typename?: 'User'
                    id: string
                    firstName?: string | null
                    lastName?: string | null
                    avatarUrl?: string | null
                  }
                }> | null
                attachments?: Array<{
                  __typename?: 'ActivityTaskAttachment'
                  id: string
                  name: string
                  fileKey: string
                }> | null
              }>
              latestActivityTaskSummary: {
                __typename?: 'LatestActivityTaskSummary'
                lastRequestedAt: any
                lastSubmittedAt?: any | null
                submittingUserExplanation?: string | null
                approvalUserExplanation?: string | null
                comments: Array<{
                  __typename?: 'ActivityTaskComment'
                  content: string
                  author: { __typename?: 'User'; firstName?: string | null }
                }>
              }
            }>
          }>
        }
      | {
          __typename: 'MosaicActivityReport'
          id: string
          description: string
          activity: {
            __typename?: 'Activity'
            id: string
            name: string
            description: string
            responsibleParty: ResponsibleParty
            updateFrequency: UpdateFrequency
            topic: { __typename?: 'Topic'; name: string }
            subtopic: { __typename?: 'Subtopic'; name: string }
          }
          items: Array<{
            __typename?: 'MosaicReportItem'
            id: string
            complianceStatus: ComplianceStatus
            partnerName: string
            activityTasks: Array<{
              __typename: 'ActivityTask'
              createdAt: any
              deletedAt?: any | null
              description?: string | null
              dueDate: any
              id: string
              submittingUserExplanation?: string | null
              approvalUserExplanation?: string | null
              submissionStatus: ActivityTaskSubmissionStatus
              submittedAt?: any | null
              updatedAt: any
              summary?: string | null
              assignedTo?: {
                __typename?: 'OrganizationUser'
                id: string
                user: {
                  __typename?: 'User'
                  firstName?: string | null
                  lastName?: string | null
                  avatarUrl?: string | null
                }
              } | null
              submittedBy?: {
                __typename?: 'OrganizationUser'
                id: string
                user: {
                  __typename?: 'User'
                  firstName?: string | null
                  lastName?: string | null
                  avatarUrl?: string | null
                }
              } | null
              approvedBy?: {
                __typename?: 'OrganizationUser'
                id: string
                user: {
                  __typename?: 'User'
                  firstName?: string | null
                  lastName?: string | null
                  avatarUrl?: string | null
                }
              } | null
              subscription: {
                __typename?: 'ActivitySubscription'
                id: string
                optedOut: boolean
                activity: {
                  __typename?: 'Activity'
                  id: string
                  name: string
                  description: string
                  responsibleParty: ResponsibleParty
                  topic: { __typename?: 'Topic'; id: string; name: string }
                  subtopic: { __typename?: 'Subtopic'; id: string; name: string }
                }
                organization: { __typename?: 'Organization'; id: string; name: string }
                sourceBank?: { __typename?: 'Organization'; id: string; name: string } | null
              }
              comments?: Array<{
                __typename?: 'ActivityTaskComment'
                id: string
                content: string
                createdAt: any
                author: {
                  __typename?: 'User'
                  id: string
                  firstName?: string | null
                  lastName?: string | null
                  avatarUrl?: string | null
                  email: string
                }
              }> | null
              assets?: Array<{
                __typename?: 'Asset'
                id: string
                name: string
                key: string
                version: string
                originalMimeType: MimeType
                createdAt: any
                user: {
                  __typename?: 'User'
                  id: string
                  firstName?: string | null
                  lastName?: string | null
                  avatarUrl?: string | null
                }
              }> | null
              attachments?: Array<{
                __typename?: 'ActivityTaskAttachment'
                id: string
                name: string
                fileKey: string
              }> | null
            }>
            latestActivityTaskSummary: {
              __typename?: 'LatestActivityTaskSummary'
              lastRequestedAt: any
              lastSubmittedAt?: any | null
              submittingUserExplanation?: string | null
              approvalUserExplanation?: string | null
              comments: Array<{
                __typename?: 'ActivityTaskComment'
                content: string
                author: { __typename?: 'User'; firstName?: string | null }
              }>
            }
          }>
        }
      | { __typename?: 'MosaicAdminReport' }
      | { __typename?: 'MosaicBankReport'; id: string }
      | { __typename?: 'MosaicFintechReport'; id: string }
      | null
  }
}

export type GetActiveActivitiesForOrganizationQueryVariables = Exact<{
  orgId: Scalars['String']['input']
}>

export type GetActiveActivitiesForOrganizationQuery = {
  __typename?: 'Query'
  getActiveActivitiesForOrganization: Array<{
    __typename?: 'Activity'
    id: string
    name: string
    description: string
    updateFrequency: UpdateFrequency
    responsibleParty: ResponsibleParty
    active: boolean
    createdAt: any
    updatedAt: any
    topic: { __typename?: 'Topic'; id: string; name: string; description?: string | null; createdAt: any }
    subtopic: { __typename?: 'Subtopic'; id: string; name: string; description?: string | null; createdAt: any }
  }>
}

export type ComputeTextDifferencesQueryVariables = Exact<{
  fragmentId1: Scalars['String']['input']
  fragmentId2: Scalars['String']['input']
}>

export type ComputeTextDifferencesQuery = {
  __typename?: 'Query'
  computeTextDifferences: Array<
    Array<{ __typename?: 'ChangeType'; value?: string | null; added?: boolean | null; removed?: boolean | null }>
  >
}

export const SpacesQueryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SpacesQuery' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Space' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'spaceUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconColor' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SpacesQueryFragment, unknown>
export const SpaceSearchFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SpaceSearch' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Space' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'spaceUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconColor' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SpaceSearchFragment, unknown>
export const AssetSearchFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetSearch' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalMimeType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AssetSearchFragment, unknown>
export const OrganizationSearchFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrganizationSearch' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Organization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrganizationSearchFragment, unknown>
export const ProjectSearchFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectSearch' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'space' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectSearchFragment, unknown>
export const PaginationSearchFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaginationSearch' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginationMeta' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'totalItems' } },
          { kind: 'Field', name: { kind: 'Name', value: 'itemsPerPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaginationSearchFragment, unknown>
export const AuditTrailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditTrail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditTrail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contextId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuditTrailFragment, unknown>
export const ProcessMemberFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProcessMemberFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProcessMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProcessMemberFieldsFragment, unknown>
export const ProcessFieldFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProcessFieldFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProcessField' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
          { kind: 'Field', name: { kind: 'Name', value: 'options' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProcessFieldFieldsFragment, unknown>
export const ProcessFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProcessFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Process' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'access' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'space' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconColor' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'members' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProcessMemberFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProcessFieldFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProcessMemberFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProcessMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProcessFieldFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProcessField' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
          { kind: 'Field', name: { kind: 'Name', value: 'options' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProcessFieldsFragment, unknown>
export const ActivityTaskDetailsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityTaskDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ActivityTask' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittingUserExplanation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalUserExplanation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submissionStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedTo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approvedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optedOut' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'responsibleParty' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topic' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subtopic' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourceBank' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'author' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'originalMimeType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActivityTaskDetailsFragment, unknown>
export const ActivityDetailsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Activity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateFrequency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responsibleParty' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtopic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActivityDetailsFragment, unknown>
export const UpdateUserRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateUserRoleInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>
export const UpdateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateUserInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>
export const CreateOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateOrganizationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateOrganizationMutation, CreateOrganizationMutationVariables>
export const UpdateOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateOrganizationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>
export const UpdateOrganizationMetadataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateOrganizationMetadata' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateOrganizationMetadataInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrganizationMetadata' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateOrganizationMetadataMutation, UpdateOrganizationMetadataMutationVariables>
export const DeleteOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>
export const LeaveOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LeaveOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leaveOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeaveOrganizationMutation, LeaveOrganizationMutationVariables>
export const CreateSpaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSpace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateSpaceInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSpace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'spaceUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSpaceMutation, CreateSpaceMutationVariables>
export const DeleteSpaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSpace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSpace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteSpaceMutation, DeleteSpaceMutationVariables>
export const ArchiveSpaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ArchiveSpace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ArchiveSpaceInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archiveSpace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ArchiveSpaceMutation, ArchiveSpaceMutationVariables>
export const CreateProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateProjectInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateProjectMutation, CreateProjectMutationVariables>
export const DeleteProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteProjectMutation, DeleteProjectMutationVariables>
export const ArchiveProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ArchiveProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archiveProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ArchiveProjectMutation, ArchiveProjectMutationVariables>
export const CreateProjectTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProjectTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateProjectTaskInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProjectTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'asset' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'projectUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'space' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'spaceUrl' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateProjectTaskMutation, CreateProjectTaskMutationVariables>
export const UpdateProjectTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProjectTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateProjectTaskInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProjectTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reviewer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProjectTaskMutation, UpdateProjectTaskMutationVariables>
export const ArchiveProjectTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ArchiveProjectTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archiveProjectTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ArchiveProjectTaskMutation, ArchiveProjectTaskMutationVariables>
export const CreateProjectTaskAttachmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createProjectTaskAttachments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateTaskAttachmentInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProjectTaskAttachments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateProjectTaskAttachmentsMutation, CreateProjectTaskAttachmentsMutationVariables>
export const CreateActivityTaskAttachmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateActivityTaskAttachments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateTaskAttachmentInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createActivityTaskAttachments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateActivityTaskAttachmentsMutation, CreateActivityTaskAttachmentsMutationVariables>
export const UpdateProjectAssetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateProjectAsset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateProjectAssetInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProjectAsset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProjectAssetMutation, UpdateProjectAssetMutationVariables>
export const UpdateProjectTaskStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProjectTaskStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateProjectTaskStatusInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProjectTaskStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProjectTaskStatusMutation, UpdateProjectTaskStatusMutationVariables>
export const BumpAssetVersionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BumpAssetVersion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BumpAssetVersionInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bumpAssetVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BumpAssetVersionMutation, BumpAssetVersionMutationVariables>
export const CreateOrUpdateDocumentAssetRevisionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateOrUpdateDocumentAssetRevisions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'COUDocumentAssetRevisionInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createOrUpdateDocumentAssetRevisions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'creatorId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authorsIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'attributes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'diffData' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateOrUpdateDocumentAssetRevisionsMutation,
  CreateOrUpdateDocumentAssetRevisionsMutationVariables
>
export const CreateAssetReviewRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAssetReviewRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateReviewRequestInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reviewRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateAssetReviewRequestMutation, CreateAssetReviewRequestMutationVariables>
export const UpdateAssetReviewRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAssetReviewRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateReviewRequestInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateReviewRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateAssetReviewRequestMutation, UpdateAssetReviewRequestMutationVariables>
export const InviteOrganizationMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InviteOrganizationMembers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateOrganizationInvitationsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createOrganizationInvitations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'failedItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InviteOrganizationMembersMutation, InviteOrganizationMembersMutationVariables>
export const InviteOrganizationMemberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InviteOrganizationMember' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateOrganizationInvitationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createOrganizationInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InviteOrganizationMemberMutation, InviteOrganizationMemberMutationVariables>
export const CancelInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CancelOrganizationInvitationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelOrganizationInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelInvitationMutation, CancelInvitationMutationVariables>
export const AcceptOrganizationInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AcceptOrganizationInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AcceptOrganizationInvitationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acceptOrganizationInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'teamUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AcceptOrganizationInvitationMutation, AcceptOrganizationInvitationMutationVariables>
export const InviteProjectMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InviteProjectMembers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateProjectInvitationsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProjectInvitations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'failedItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InviteProjectMembersMutation, InviteProjectMembersMutationVariables>
export const InviteSpaceMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InviteSpaceMembers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateSpaceInvitationsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSpaceInvitations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'failedItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InviteSpaceMembersMutation, InviteSpaceMembersMutationVariables>
export const AcceptSpaceInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AcceptSpaceInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uniqueId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acceptSpaceInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uniqueId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uniqueId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'space' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'spaceUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isOnboarded' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'organizationProducts' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'product' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'teamUrl' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AcceptSpaceInvitationMutation, AcceptSpaceInvitationMutationVariables>
export const AcceptProjectInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AcceptProjectInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uniqueId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acceptProjectInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uniqueId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uniqueId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'projectUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'space' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'spaceUrl' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'organization' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'teamUrl' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AcceptProjectInvitationMutation, AcceptProjectInvitationMutationVariables>
export const CancelProjectInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelProjectInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uniqueId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelProjectInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uniqueId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uniqueId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelProjectInvitationMutation, CancelProjectInvitationMutationVariables>
export const CancelSpaceInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelSpaceInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uniqueId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelSpaceInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uniqueId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uniqueId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelSpaceInvitationMutation, CancelSpaceInvitationMutationVariables>
export const CreateWorkflowTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateWorkflowTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateWorkflowTemplateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createWorkflowTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'payload' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateWorkflowTemplateMutation, CreateWorkflowTemplateMutationVariables>
export const UpdateWorkflowTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateWorkflowTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateWorkflowTemplateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWorkflowTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'payload' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateWorkflowTemplateMutation, UpdateWorkflowTemplateMutationVariables>
export const CreateProjectWorkflowTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProjectWorkflowTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateProjectWorkflowTemplateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProjectWorkflowTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'payload' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateProjectWorkflowTemplateMutation, CreateProjectWorkflowTemplateMutationVariables>
export const UpdateProjectWorkflowTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProjectWorkflowTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateProjectWorkflowTemplateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProjectWorkflowTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'payload' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProjectWorkflowTemplateMutation, UpdateProjectWorkflowTemplateMutationVariables>
export const DeleteWorkflowTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteWorkflowTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteWorkflowTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteWorkflowTemplateMutation, DeleteWorkflowTemplateMutationVariables>
export const ArchiveWorkflowTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ArchiveWorkflowTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archiveWorkflowTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ArchiveWorkflowTemplateMutation, ArchiveWorkflowTemplateMutationVariables>
export const UpdateWorkflowTemplateActiveStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateWorkflowTemplateActiveStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'active' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWorkflowTemplateActiveStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'active' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'active' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateWorkflowTemplateActiveStatusMutation,
  UpdateWorkflowTemplateActiveStatusMutationVariables
>
export const UpdateSpaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSpace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateSpaceInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSpace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSpaceMutation, UpdateSpaceMutationVariables>
export const UpdateSpaceUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSpaceUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateSpaceUserInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSpaceUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSpaceUserMutation, UpdateSpaceUserMutationVariables>
export const CreateCommentThreadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCommentThread' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateCommentThreadInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCommentThread' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'attributes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'context' } },
                { kind: 'Field', name: { kind: 'Name', value: 'resolvedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resolvedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'comments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'author' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'attributes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCommentThreadMutation, CreateCommentThreadMutationVariables>
export const UpdateCommentThreadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCommentThread' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateCommentThreadInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCommentThread' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'attributes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'context' } },
                { kind: 'Field', name: { kind: 'Name', value: 'resolvedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resolvedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'comments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'author' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'attributes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCommentThreadMutation, UpdateCommentThreadMutationVariables>
export const DeleteCommentThreadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCommentThread' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteCommentThreadInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCommentThread' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteCommentThreadMutation, DeleteCommentThreadMutationVariables>
export const CreateCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateCommentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createComment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'attributes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'author' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCommentMutation, CreateCommentMutationVariables>
export const UpdateCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateCommentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateComment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'attributes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'author' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCommentMutation, UpdateCommentMutationVariables>
export const DeleteCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteCommentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteComment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteCommentMutation, DeleteCommentMutationVariables>
export const AdvanceWorkflowDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdvanceWorkflow' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'taskId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'advanceWorkflow' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'taskId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'taskId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdvanceWorkflowMutation, AdvanceWorkflowMutationVariables>
export const UpdateNotificationPreferencesProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateNotificationPreferencesProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateNotificationPreferencesProjectInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateNotificationPreferencesProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notifyTasks' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notifyComments' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notifyStatusUpdates' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notifyStatusFrequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notifyEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notifySlack' } },
                { kind: 'Field', name: { kind: 'Name', value: 'muteAll' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateNotificationPreferencesProjectMutation,
  UpdateNotificationPreferencesProjectMutationVariables
>
export const UpdateNotificationPreferencesSpaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateNotificationPreferencesSpace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateNotificationPreferencesSpaceInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateNotificationPreferencesSpace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'spaceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notifyAddedToProject' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notifyProjectCreated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notifyEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notifySlack' } },
                { kind: 'Field', name: { kind: 'Name', value: 'muteAll' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateNotificationPreferencesSpaceMutation,
  UpdateNotificationPreferencesSpaceMutationVariables
>
export const UpdateSlackDataChannelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSlackDataChannel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateSlackDataChannelInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSlackDataChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'collection' } },
                { kind: 'Field', name: { kind: 'Name', value: 'objectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSlackDataChannelMutation, UpdateSlackDataChannelMutationVariables>
export const DeleteSlackDataChannelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSlackDataChannel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteSlackDataChannelInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSlackDataChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteSlackDataChannelMutation, DeleteSlackDataChannelMutationVariables>
export const CreateNotificationPreferencesUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateNotificationPreferencesUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateNotificationPreferencesUserInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createNotificationPreferencesUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notifyEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notifySlack' } },
                { kind: 'Field', name: { kind: 'Name', value: 'muteAll' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateNotificationPreferencesUserMutation,
  CreateNotificationPreferencesUserMutationVariables
>
export const UpdateNotificationPreferencesUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateNotificationPreferencesUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateNotificationPreferencesUserInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateNotificationPreferencesUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notifyEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notifySlack' } },
                { kind: 'Field', name: { kind: 'Name', value: 'muteAll' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateNotificationPreferencesUserMutation,
  UpdateNotificationPreferencesUserMutationVariables
>
export const LeaveSpaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LeaveSpace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leaveSpace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeaveSpaceMutation, LeaveSpaceMutationVariables>
export const JoinSpaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'JoinSpace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'joinSpace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JoinSpaceMutation, JoinSpaceMutationVariables>
export const UpdateOrganizationUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateOrganizationUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateOrganizationUserInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrganizationUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateOrganizationUserMutation, UpdateOrganizationUserMutationVariables>
export const UpdateProjectUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProjectUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateProjectUserInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProjectUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProjectUserMutation, UpdateProjectUserMutationVariables>
export const UpdateProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateProjectInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProjectMutation, UpdateProjectMutationVariables>
export const LeaveProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LeaveProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leaveProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeaveProjectMutation, LeaveProjectMutationVariables>
export const DeleteIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteIntegrationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>
export const CreateProjectTaskAttachmentUploadUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createProjectTaskAttachmentUploadUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateTaskAttachmentUploadUrlInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProjectTaskAttachmentUploadUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateProjectTaskAttachmentUploadUrlMutation,
  CreateProjectTaskAttachmentUploadUrlMutationVariables
>
export const CreateActivityTaskAttachmentUploadUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateActivityTaskAttachmentUploadUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateTaskAttachmentUploadUrlInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createActivityTaskAttachmentUploadUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateActivityTaskAttachmentUploadUrlMutation,
  CreateActivityTaskAttachmentUploadUrlMutationVariables
>
export const UpdateSuggestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateSuggestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ResolveSuggestionInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSuggestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSuggestionMutation, UpdateSuggestionMutationVariables>
export const QueueSuggestionsJobForAssetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'queueSuggestionsJobForAsset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateAssetWithSuggestionsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'queueSuggestionsJobForAsset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QueueSuggestionsJobForAssetMutation, QueueSuggestionsJobForAssetMutationVariables>
export const IgnoreSuggestionsForAssetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ignoreSuggestionsForAsset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'assetId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ignoreSuggestionsForAsset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assetId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'assetId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IgnoreSuggestionsForAssetMutation, IgnoreSuggestionsForAssetMutationVariables>
export const GetOrCreateLiveblocksRoomDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GetOrCreateLiveblocksRoom' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateRoomForAssetInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOrCreateLiveblocksRoomForAsset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastConnectionAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrCreateLiveblocksRoomMutation, GetOrCreateLiveblocksRoomMutationVariables>
export const CreateProgramDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProgram' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateProgramInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProgram' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateProgramMutation, CreateProgramMutationVariables>
export const UpdateProgramDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProgram' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateProgramInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProgram' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProgramMutation, UpdateProgramMutationVariables>
export const DeleteProgramDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteProgram' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProgram' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteProgramMutation, DeleteProgramMutationVariables>
export const CreatePartnershipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePartnership' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreatePartnershipInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPartnership' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sponsorOrganizationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'partnerOrganizationId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePartnershipMutation, CreatePartnershipMutationVariables>
export const UpdatePartnershipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePartnership' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdatePartnershipInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePartnership' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sponsorOrganizationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'partnerOrganizationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'onboardingStartTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'onboardingFinishTime' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePartnershipMutation, UpdatePartnershipMutationVariables>
export const DeletePartnershipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePartnership' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeletePartnershipInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePartnership' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeletePartnershipMutation, DeletePartnershipMutationVariables>
export const CreateMosaicPartnershipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateMosaicPartnership' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateMosaicPartnershipInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMosaicPartnership' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sponsorOrganizationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'partnerOrganizationId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateMosaicPartnershipMutation, CreateMosaicPartnershipMutationVariables>
export const InitiatePartnerOnboardingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'initiatePartnerOnboarding' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'url' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'initiatePartnerOnboarding' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'url' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'url' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'legalNames' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'licences' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'legalName' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'links' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'href' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notableNews' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'snippet' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InitiatePartnerOnboardingMutation, InitiatePartnerOnboardingMutationVariables>
export const CreateProcessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProcess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateProcessInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProcess' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProcessFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProcessMemberFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProcessMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProcessFieldFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProcessField' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
          { kind: 'Field', name: { kind: 'Name', value: 'options' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProcessFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Process' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'access' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'space' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconColor' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'members' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProcessMemberFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProcessFieldFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateProcessMutation, CreateProcessMutationVariables>
export const UpdateProcessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProcess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateProcessInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProcess' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProcessFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProcessMemberFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProcessMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProcessFieldFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProcessField' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
          { kind: 'Field', name: { kind: 'Name', value: 'options' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProcessFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Process' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'access' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'space' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconColor' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'members' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProcessMemberFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProcessFieldFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProcessMutation, UpdateProcessMutationVariables>
export const CreateProcessMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProcessMembers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateProcessMembersInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProcessMembers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProcessMemberFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProcessMemberFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProcessMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateProcessMembersMutation, CreateProcessMembersMutationVariables>
export const SaveProcessFieldsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveProcessFields' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateProcessFieldInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'processId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveProcessFields' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'processId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'processId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProcessFieldFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProcessFieldFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProcessField' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
          { kind: 'Field', name: { kind: 'Name', value: 'options' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveProcessFieldsMutation, SaveProcessFieldsMutationVariables>
export const DeleteProcessFieldsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteProcessFields' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeProcessField' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteProcessFieldsMutation, DeleteProcessFieldsMutationVariables>
export const SetProcessMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetProcessMembers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateProcessMembersInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setProcessMembers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProcessMemberFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProcessMemberFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProcessMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetProcessMembersMutation, SetProcessMembersMutationVariables>
export const CreateIssuesForPartnerSourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateIssuesForPartnerSource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'partnerSourceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createIssuesForPartnerSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'partnerSourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'partnerSourceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateIssuesForPartnerSourceMutation, CreateIssuesForPartnerSourceMutationVariables>
export const CreateIssuesForPartnershipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateIssuesForPartnership' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'partnershipId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createIssuesForPartnership' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'partnershipId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'partnershipId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateIssuesForPartnershipMutation, CreateIssuesForPartnershipMutationVariables>
export const CreatePartnerSourcesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePartnerSources' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreatePartnerSourcesInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPartnerSources' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePartnerSourcesMutation, CreatePartnerSourcesMutationVariables>
export const CreatePartnerSourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePartnerSource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreatePartnerSourceInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPartnerSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePartnerSourceMutation, CreatePartnerSourceMutationVariables>
export const UpdatePartnerSourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePartnerSource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdatePartnerSourceInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePartnerSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePartnerSourceMutation, UpdatePartnerSourceMutationVariables>
export const DeletePartnerSourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePartnerSource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'partnerSourceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePartnerSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'partnerSourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'partnerSourceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeletePartnerSourceMutation, DeletePartnerSourceMutationVariables>
export const RunSinglePartnerSourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RunSinglePartnerSource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'partnerSourceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'runSinglePartnerSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'partnerSourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'partnerSourceId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RunSinglePartnerSourceMutation, RunSinglePartnerSourceMutationVariables>
export const DeleteIssueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteIssue' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'issueId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteIssue' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'issueId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'issueId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteIssueMutation, DeleteIssueMutationVariables>
export const CreateRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateRuleInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateRuleMutation, CreateRuleMutationVariables>
export const UpdateRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateRuleInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateRuleMutation, UpdateRuleMutationVariables>
export const UpdateActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateActivityInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateActivityMutation, UpdateActivityMutationVariables>
export const DeleteActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteActivityMutation, DeleteActivityMutationVariables>
export const DeleteActivitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteActivities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteActivitiesMutation, DeleteActivitiesMutationVariables>
export const CreateActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateActivityInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateActivityMutation, CreateActivityMutationVariables>
export const DeleteMosaicPartnershipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteMosaicPartnership' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeletePartnershipInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteMosaicPartnership' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteMosaicPartnershipMutation, DeleteMosaicPartnershipMutationVariables>
export const DeleteActivitySubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteActivitySubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteActivitySubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteActivitySubscriptionMutation, DeleteActivitySubscriptionMutationVariables>
export const DeleteActivitySubscriptionCascadeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteActivitySubscriptionCascade' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cascade' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteActivitySubscriptionCascade' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cascade' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cascade' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteActivitySubscriptionCascadeMutation,
  DeleteActivitySubscriptionCascadeMutationVariables
>
export const CreateActivitySubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateActivitySubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateActivitySubscriptionInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createActivitySubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourceBank' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inheritedFromBank' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optedOut' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateActivitySubscriptionMutation, CreateActivitySubscriptionMutationVariables>
export const CreateBankSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateBankSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateBankSubscriptionInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBankSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourceBank' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inheritedFromBank' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optedOut' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateBankSubscriptionMutation, CreateBankSubscriptionMutationVariables>
export const AddActivityTaskCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddActivityTaskComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AddActivityTaskCommentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addCommentToActivityTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'author' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddActivityTaskCommentMutation, AddActivityTaskCommentMutationVariables>
export const AddAssetToActivityTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddAssetToActivityTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AddAssetToActivityTaskInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addAssetToActivityTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddAssetToActivityTaskMutation, AddAssetToActivityTaskMutationVariables>
export const DeleteAssetFromActivityTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteAssetFromActivityTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteAssetFromActivityTaskInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAssetFromActivityTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteAssetFromActivityTaskMutation, DeleteAssetFromActivityTaskMutationVariables>
export const ApproveActivityTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ApproveActivityTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ApproveActivityTaskInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approveActivityTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApproveActivityTaskMutation, ApproveActivityTaskMutationVariables>
export const SubmitActivityTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubmitActivityTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SubmitActivityTaskInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitActivityTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubmitActivityTaskMutation, SubmitActivityTaskMutationVariables>
export const UpdateActivityTaskAssigneeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateActivityTaskAssignee' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateActivityTaskAssigneeInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateActivityTaskAssignee' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateActivityTaskAssigneeMutation, UpdateActivityTaskAssigneeMutationVariables>
export const UpdateActivityTaskStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateActivityTaskStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateActivityTaskStatusInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateActivityTaskStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateActivityTaskStatusMutation, UpdateActivityTaskStatusMutationVariables>
export const UpdateActivityTaskDueDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateActivityTaskDueDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateActivityTaskDueDateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateActivityTaskDueDate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateActivityTaskDueDateMutation, UpdateActivityTaskDueDateMutationVariables>
export const MarkOutOfScopeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkOutOfScope' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarkOutOfScopeInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markOutOfScope' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MarkOutOfScopeMutation, MarkOutOfScopeMutationVariables>
export const CreateActivityTaskFromSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateActivityTaskFromSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateActivityTaskFromSubscriptionIdInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createActivityTaskFromSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateActivityTaskFromSubscriptionMutation,
  CreateActivityTaskFromSubscriptionMutationVariables
>
export const SendLogAnalysisInsightsChatMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendLogAnalysisInsightsChatMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EnrichedFragmentQAInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enrichmentQA' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'output' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SendLogAnalysisInsightsChatMessageMutation,
  SendLogAnalysisInsightsChatMessageMutationVariables
>
export const DeleteAssetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteAsset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAsset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteAssetMutation, DeleteAssetMutationVariables>
export const GetCurrentUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCurrentUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organizationUsers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'teamUrl' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCurrentUserQuery, GetCurrentUserQueryVariables>
export const GetOrganizationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrganizations' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sponsor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'onboardingStartTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'onboardingFinishTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstLoggedInAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'spaces' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'spaceUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'iconColor' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrganizationsQuery, GetOrganizationsQueryVariables>
export const GetOrganizationForMosaicAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrganizationForMosaicAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationForMosaicAdmin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstLoggedInAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrganizationForMosaicAdminQuery, GetOrganizationForMosaicAdminQueryVariables>
export const GetOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstLoggedInAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrganizationQuery, GetOrganizationQueryVariables>
export const GetOrganizationDashboardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrganizationDashboard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectsByUserInOrg' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'space' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'spaceUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectTasksCreatedInOrg' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectTasksAssignedInOrg' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'iconColor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'projectUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'space' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'spaceUrl' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrganizationDashboardQuery, GetOrganizationDashboardQueryVariables>
export const GetOrganizationByTeamUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrganizationByTeamUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'teamUrl' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOrganizationByTeamUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'teamUrl' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'teamUrl' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'spaces' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrganizationByTeamUrlQuery, GetOrganizationByTeamUrlQueryVariables>
export const GetSpaceByUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSpaceByUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'spaceUrl' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'spaceByUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'spaceUrl' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'spaceUrl' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'spaceUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOnboarded' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconColor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'owner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'projectUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'iconUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'iconColor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tasks' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assignee' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'users' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSpaceByUrlQuery, GetSpaceByUrlQueryVariables>
export const GetProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'spaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectUrl' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectByUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'spaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'spaceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectUrl' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectUrl' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconColor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'space' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'spaceUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignee' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reviewer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'author' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attachments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workflow' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currentNode' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectQuery, GetProjectQueryVariables>
export const GetIssueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetIssue' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issue' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ticketId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                { kind: 'Field', name: { kind: 'Name', value: 'justification' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reasons' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'justification' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'referenceUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fragmentItem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'partnerSource' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'config' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WebsitePage' } },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'screenshotUrl' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TrustpilotReview' } },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'consumer' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'displayName' } }],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'GoogleNewsSearchResultMetadata' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TikTokPostMetadata' } },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'mediaUrls' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'webVideoUrl' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'videoMeta' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coverUrl' } }],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'authorMeta' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RedditPost' } },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'communityName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'redditCreatedAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'upVotes' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'numberOfComments' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'CFPBComplaintMetadata' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'complaint_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'complaint_what_happened' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'has_narrative' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'issue' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetIssueQuery, GetIssueQueryVariables>
export const GetEnrichedTiktokPostFragmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEnrichedTiktokPostFragment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'enrichedFragmentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enrichedTiktokPostFragment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enrichedFragmentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'enrichedFragmentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discriminator' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fragment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'discriminator' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'webUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'coverImageUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEnrichedTiktokPostFragmentQuery, GetEnrichedTiktokPostFragmentQueryVariables>
export const GetAllFragmentsPartnerSourceIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllFragmentsPartnerSourceId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'partnerSourceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAllFragmentsPartnerSourceId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'partnerSourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'partnerSourceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ukey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'partnerSource' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'config' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WebsitePage' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'screenshotUrl' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TrustpilotReview' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'consumer' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'displayName' } }],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'GoogleNewsSearchResultMetadata' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TikTokPostMetadata' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mediaUrls' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'webVideoUrl' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'videoMeta' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coverUrl' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'authorMeta' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RedditPost' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'communityName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'redditCreatedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'upVotes' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'numberOfComments' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CFPBComplaintMetadata' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'complaint_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'complaint_what_happened' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'has_narrative' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'issue' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllFragmentsPartnerSourceIdQuery, GetAllFragmentsPartnerSourceIdQueryVariables>
export const GetEnrichedFragmentByFragmentIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEnrichedFragmentByFragmentId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fragmentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getEnrichedFragmentByFragmentId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fragmentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fragmentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ukey' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enrichedData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'EnrichedTrustPilotReviewMetadata' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'keywords' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'threatensLegalAction' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mentionsDispute' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'EnrichedCFPBComplaintMetadata' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'keywords' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'threatensLegalAction' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mentionsDispute' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'EnrichedWebsitePageMetadata' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'suggestions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'highlightedText' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'reasonForChange' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'highLevelReason' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'potentialAlternateCopy' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WebsitePage' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'screenshotUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'stagehandScreenshotUrl' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TrustpilotReview' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'consumer' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'displayName' } }],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'GoogleNewsSearchResultMetadata' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TikTokPostMetadata' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mediaUrls' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'webVideoUrl' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'videoMeta' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coverUrl' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'authorMeta' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RedditPost' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'communityName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'redditCreatedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'upVotes' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'numberOfComments' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CFPBComplaintMetadata' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'complaint_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'complaint_what_happened' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'has_narrative' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'issue' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEnrichedFragmentByFragmentIdQuery, GetEnrichedFragmentByFragmentIdQueryVariables>
export const GetFragmentByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFragmentById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fragmentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getFragment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fragmentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fragmentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ukey' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'partnerSource' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'config' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WebsitePage' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'screenshotUrl' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TrustpilotReview' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'consumer' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'displayName' } }],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'GoogleNewsSearchResultMetadata' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TikTokPostMetadata' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mediaUrls' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'webVideoUrl' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'videoMeta' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coverUrl' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'authorMeta' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RedditPost' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'communityName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'redditCreatedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'upVotes' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'numberOfComments' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CFPBComplaintMetadata' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'complaint_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'complaint_what_happened' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'has_narrative' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'issue' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFragmentByIdQuery, GetFragmentByIdQueryVariables>
export const GetAllFragmentsForPartnershipIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllFragmentsForPartnershipId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetFragmentsForPartnershipInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAllFragmentsForPartnershipId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ukey' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'partnerSource' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'config' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WebsitePage' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'screenshotUrl' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TrustpilotReview' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'consumer' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'displayName' } }],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'GoogleNewsSearchResultMetadata' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TikTokPostMetadata' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mediaUrls' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'webVideoUrl' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'videoMeta' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coverUrl' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'authorMeta' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RedditPost' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'communityName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'redditCreatedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'upVotes' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'numberOfComments' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CFPBComplaintMetadata' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'complaint_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'complaint_what_happened' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'has_narrative' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'issue' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllFragmentsForPartnershipIdQuery, GetAllFragmentsForPartnershipIdQueryVariables>
export const GetAllEnrichedFragmentsForPartnershipIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllEnrichedFragmentsForPartnershipId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetFragmentsForPartnershipInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAllEnrichedFragmentsForPartnershipId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ukey' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'partnerSource' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'config' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enrichedData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'EnrichedTrustPilotReviewMetadata' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'keywords' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'threatensLegalAction' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mentionsDispute' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'EnrichedCFPBComplaintMetadata' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'keywords' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'threatensLegalAction' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mentionsDispute' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'EnrichedWebsitePageMetadata' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'suggestions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'highlightedText' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'reasonForChange' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'highLevelReason' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'potentialAlternateCopy' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WebsitePage' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'screenshotUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TrustpilotReview' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'consumer' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'displayName' } }],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'GoogleNewsSearchResultMetadata' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TikTokPostMetadata' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mediaUrls' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'webVideoUrl' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'videoMeta' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coverUrl' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'authorMeta' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RedditPost' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'communityName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'redditCreatedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'upVotes' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'numberOfComments' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CFPBComplaintMetadata' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'complaint_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'complaint_what_happened' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'has_narrative' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'issue' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllEnrichedFragmentsForPartnershipIdQuery,
  GetAllEnrichedFragmentsForPartnershipIdQueryVariables
>
export const GetIssuesByPartnerSourceIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetIssuesByPartnerSourceId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'partnerSourceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issues' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'partnerSourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'partnerSourceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                { kind: 'Field', name: { kind: 'Name', value: 'justification' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fragmentItem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'partnerSource' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'config' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WebsitePage' } },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'screenshotUrl' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TrustpilotReview' } },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'consumer' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'displayName' } }],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'GoogleNewsSearchResultMetadata' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TikTokPostMetadata' } },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'mediaUrls' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'webVideoUrl' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'videoMeta' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coverUrl' } }],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'authorMeta' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RedditPost' } },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'communityName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'redditCreatedAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'upVotes' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'numberOfComments' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'CFPBComplaintMetadata' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'complaint_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'complaint_what_happened' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'has_narrative' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'issue' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetIssuesByPartnerSourceIdQuery, GetIssuesByPartnerSourceIdQueryVariables>
export const GetIssuesByPartnershipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetIssuesByPartnership' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'partnershipId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issuesByPartnership' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'partnershipId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'partnershipId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ticketId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                { kind: 'Field', name: { kind: 'Name', value: 'justification' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reasons' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'justification' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'referenceUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fragmentItem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'partnerSource' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'config' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WebsitePage' } },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'screenshotUrl' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TrustpilotReview' } },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'consumer' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'displayName' } }],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'GoogleNewsSearchResultMetadata' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TikTokPostMetadata' } },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'mediaUrls' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'webVideoUrl' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'videoMeta' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coverUrl' } }],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'authorMeta' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RedditPost' } },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'communityName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'redditCreatedAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'upVotes' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'numberOfComments' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'CFPBComplaintMetadata' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'complaint_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'complaint_what_happened' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'has_narrative' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'issue' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetIssuesByPartnershipQuery, GetIssuesByPartnershipQueryVariables>
export const GetAssetUploadUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAssetUploadUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MimeType' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assetUploadUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAssetUploadUrlQuery, GetAssetUploadUrlQueryVariables>
export const GetNewAssetVersionUploadUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetNewAssetVersionUploadUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mimeType' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'MimeType' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assetVersionUpdateUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mimeType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mimeType' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetNewAssetVersionUploadUrlQuery, GetNewAssetVersionUploadUrlQueryVariables>
export const GetAssetDownloadUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAssetDownloadUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'versionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assetDownloadUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assetId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'versionId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'versionId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAssetDownloadUrlQuery, GetAssetDownloadUrlQueryVariables>
export const GetReviewRequestsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetReviewRequests' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'assetId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reviewRequests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assetId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'assetId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requesterUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetReviewRequestsQuery, GetReviewRequestsQueryVariables>
export const GetAllRevisionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllRevisions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'assetId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documentAssetRevisions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assetId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'assetId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'attributes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authorsIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'creatorId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllRevisionsQuery, GetAllRevisionsQueryVariables>
export const GetRevisionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRevision' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'assetId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'revisionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documentAssetRevision' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assetId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'assetId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'revisionId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'revisionId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'attributes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authorsIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'creatorId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'diffData' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRevisionQuery, GetRevisionQueryVariables>
export const GetProjectTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProjectTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'taskId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'taskId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'taskId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'asset' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originalMimeType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'suggestionsJobRunning' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'requirementId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'partnershipId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reviewer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'users' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectTaskQuery, GetProjectTaskQueryVariables>
export const GetWorkflowTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWorkflowTemplates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'onlyConstructed' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'active' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workflowTemplates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'onlyConstructed' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'onlyConstructed' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'active' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'active' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'archived' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWorkflowTemplatesQuery, GetWorkflowTemplatesQueryVariables>
export const GetWorkflowTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWorkflowTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workflowTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'payload' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rootNodeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWorkflowTemplateQuery, GetWorkflowTemplateQueryVariables>
export const OrganizationInvitationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OrganizationInvitations' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationInvitations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uniqueId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'teamUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrganizationInvitationsQuery, OrganizationInvitationsQueryVariables>
export const SentOrganizationInvitationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SentOrganizationInvitations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentOrganizationInvitations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SentOrganizationInvitationsQuery, SentOrganizationInvitationsQueryVariables>
export const GetOrganizationInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrganizationInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uniqueId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uniqueId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uniqueId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uniqueId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'teamUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrganizationInvitationQuery, GetOrganizationInvitationQueryVariables>
export const GetProductsByOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProductsByOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProductsByOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activeAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProductsByOrganizationQuery, GetProductsByOrganizationQueryVariables>
export const GetProjectInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProjectInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uniqueId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uniqueId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uniqueId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uniqueId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'projectUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectInvitationQuery, GetProjectInvitationQueryVariables>
export const GetSpaceInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSpaceInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uniqueId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'spaceInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uniqueId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uniqueId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uniqueId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'space' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'spaceUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSpaceInvitationQuery, GetSpaceInvitationQueryVariables>
export const GetSpaceInvitationsForSpaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSpaceInvitationsForSpace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetSpaceInvitationsForSpaceInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'spaceInvitationsForSpace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uniqueId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'space' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'spaceUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSpaceInvitationsForSpaceQuery, GetSpaceInvitationsForSpaceQueryVariables>
export const GetSpacesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSpaces' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'spaces' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SpacesQuery' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SpacesQuery' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Space' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'spaceUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconColor' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSpacesQuery, GetSpacesQueryVariables>
export const GetSidebarDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSidebar' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'spaces' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SpacesQuery' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SpacesQuery' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Space' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'spaceUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconColor' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSidebarQuery, GetSidebarQueryVariables>
export const ProjectInvitationsForUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectInvitationsForUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetProjectInvitationsForUserInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectInvitationsForUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uniqueId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'projectUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectInvitationsForUserQuery, ProjectInvitationsForUserQueryVariables>
export const ProjectInvitationsForProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectInvitationsForProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetProjectInvitationsForProjectInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectInvitationsForProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uniqueId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'projectUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectInvitationsForProjectQuery, ProjectInvitationsForProjectQueryVariables>
export const SentProjectInvitationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SentProjectInvitations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentProjectInvitations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uniqueId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inviter' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SentProjectInvitationsQuery, SentProjectInvitationsQueryVariables>
export const GetCommentThreadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCommentThread' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetCommentThreadInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commentThread' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'attributes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'context' } },
                { kind: 'Field', name: { kind: 'Name', value: 'resolvedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resolvedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'comments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'author' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'attributes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCommentThreadQuery, GetCommentThreadQueryVariables>
export const GetIntegrationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetIntegrations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'integrations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'integrationProviderId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetIntegrationsQuery, GetIntegrationsQueryVariables>
export const GetIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'integrationProviderId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'integration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'integrationProviderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'integrationProviderId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'integrationProviderId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetIntegrationQuery, GetIntegrationQueryVariables>
export const GetCommentThreadsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCommentThreads' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetCommentThreadsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commentThreads' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'attributes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'context' } },
                { kind: 'Field', name: { kind: 'Name', value: 'resolvedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resolvedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'comments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'author' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'attributes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCommentThreadsQuery, GetCommentThreadsQueryVariables>
export const GetNotificationPreferencesProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetNotificationPreferencesProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notificationPreferencesProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'notifyTasks' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notifyComments' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notifyStatusUpdates' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notifyStatusFrequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notifyEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notifySlack' } },
                { kind: 'Field', name: { kind: 'Name', value: 'muteAll' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetNotificationPreferencesProjectQuery, GetNotificationPreferencesProjectQueryVariables>
export const GetSlackChannelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSlackChannels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slackChannels' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSlackChannelsQuery, GetSlackChannelsQueryVariables>
export const GetSlackUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSlackUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slackUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'realName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSlackUsersQuery, GetSlackUsersQueryVariables>
export const GetNotificationPreferencesSpaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetNotificationPreferencesSpace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'spaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notificationPreferencesSpace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'spaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'spaceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'spaceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'space' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'spaceUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'notifyProjectCreated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notifyAddedToProject' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notifyEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notifySlack' } },
                { kind: 'Field', name: { kind: 'Name', value: 'muteAll' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetNotificationPreferencesSpaceQuery, GetNotificationPreferencesSpaceQueryVariables>
export const GetSlackChannelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSlackChannel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slackChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSlackChannelQuery, GetSlackChannelQueryVariables>
export const GetSlackTeamInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSlackTeamInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slackTeamInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailDomain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSlackTeamInfoQuery, GetSlackTeamInfoQueryVariables>
export const GetSlackDataChannelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSlackDataChannel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'collection' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'objectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slackDataChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'collection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'collection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'objectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'collection' } },
                { kind: 'Field', name: { kind: 'Name', value: 'objectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSlackDataChannelQuery, GetSlackDataChannelQueryVariables>
export const GetNotificationPreferencesUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetNotificationPreferencesUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notificationPreferencesUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notificationPreferencesProject' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'notifyTasks' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'notifyComments' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'notifyStatusUpdates' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'notifyStatusFrequency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'notifyEmail' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'notifySlack' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'muteAll' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notificationPreferencesSpace' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'spaceId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'notifyProjectCreated' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'notifyAddedToProject' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'notifyEmail' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'notifySlack' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'muteAll' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'notifyEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notifySlack' } },
                { kind: 'Field', name: { kind: 'Name', value: 'muteAll' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetNotificationPreferencesUserQuery, GetNotificationPreferencesUserQueryVariables>
export const GetSuggestionsForAssetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSuggestionsForAsset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetSuggestionsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'suggestions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'highlightedText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reasonForChange' } },
                { kind: 'Field', name: { kind: 'Name', value: 'highLevelReason' } },
                { kind: 'Field', name: { kind: 'Name', value: 'potentialAlternateCopy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'resolveType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'asset' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coordinates' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'points' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'layout_width' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'layout_height' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'page_number' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSuggestionsForAssetQuery, GetSuggestionsForAssetQueryVariables>
export const ResolveSuggestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResolveSuggestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ResolveSuggestionInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resolveSuggestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResolveSuggestionQuery, ResolveSuggestionQueryVariables>
export const GetOrganizationLogoUploadUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrganizationLogoUploadUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetLogoUploadUrlInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logoUploadUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrganizationLogoUploadUrlQuery, GetOrganizationLogoUploadUrlQueryVariables>
export const GetTasksForUserAndOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTasksForUserAndOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTasksForUserAndOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'requirementId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'partnershipId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reviewer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workflow' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'asset' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originalMimeType' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'projectUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'space' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'spaceUrl' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'users' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTasksForUserAndOrganizationQuery, GetTasksForUserAndOrganizationQueryVariables>
export const GetAssetEditsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAssetEdits' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'assetId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assetEdits' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assetId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'assetId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addedText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'removedText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromVersionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toVersionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAssetEditsQuery, GetAssetEditsQueryVariables>
export const ProjectTaskAttachmentDownloadUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'projectTaskAttachmentDownloadUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'attachmentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectTaskAttachmentDownloadUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'attachmentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'attachmentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectTaskAttachmentDownloadUrlQuery, ProjectTaskAttachmentDownloadUrlQueryVariables>
export const GetActivityTaskAttachmentDownloadUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetActivityTaskAttachmentDownloadUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'attachmentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityTaskAttachmentDownloadUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'attachmentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'attachmentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetActivityTaskAttachmentDownloadUrlQuery,
  GetActivityTaskAttachmentDownloadUrlQueryVariables
>
export const GetActiveNodeForTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetActiveNodeForTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'taskId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getActiveNodeForTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'taskId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'taskId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'payload' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'children' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'payload' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workflow' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetActiveNodeForTaskQuery, GetActiveNodeForTaskQueryVariables>
export const GetAssetVersionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAssetVersions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'assetId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assetVersions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assetId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'assetId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'versionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isLatest' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comments' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAssetVersionsQuery, GetAssetVersionsQueryVariables>
export const GetCurrentAssetVersionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCurrentAssetVersions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'assetId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentAssetVersions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assetId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'assetId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roomId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'editableMimeType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'editableFile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCurrentAssetVersionsQuery, GetCurrentAssetVersionsQueryVariables>
export const GetProgramsForOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProgramsForOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProgramsForOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organizationId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'programType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'programRequirements' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requirement' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mimeTypes' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProgramsForOrganizationQuery, GetProgramsForOrganizationQueryVariables>
export const GetProgramByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProgramById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'program' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'programType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'programRequirements' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requirement' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mimeTypes' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProgramByIdQuery, GetProgramByIdQueryVariables>
export const GetPartnershipsForOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPartnershipsForOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPartnershipsForOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organizationId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'healthScore' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'owner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'partnerOrganization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'program' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'programType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPartnershipsForOrganizationQuery, GetPartnershipsForOrganizationQueryVariables>
export const GetProjectByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProjectById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconColor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'space' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'spaceUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'asset' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'originalMimeType' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignee' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reviewer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'author' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attachments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectByIdQuery, GetProjectByIdQueryVariables>
export const RequirementCategorySummariesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RequirementCategorySummaries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'partnershipId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requirementCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'partnershipId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'partnershipId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'documentCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inProgressDocumentCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completedDocumentCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'program' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'programId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'progress' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignee' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'asset' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'originalMimeType' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requirement' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mimeTypes' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RequirementCategorySummariesQuery, RequirementCategorySummariesQueryVariables>
export const SearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Search' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchFilters' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Pagination' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'search' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'object' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AssetSearch' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrganizationSearch' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SpaceSearch' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectSearch' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaginationSearch' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetSearch' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalMimeType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrganizationSearch' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Organization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SpaceSearch' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Space' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'spaceUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconColor' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectSearch' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'space' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaginationSearch' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginationMeta' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'totalItems' } },
          { kind: 'Field', name: { kind: 'Name', value: 'itemsPerPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchQuery, SearchQueryVariables>
export const FilterAuditTrailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FilterAuditTrail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FilterAuditTrailInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filterAuditTrail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuditTrail' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditTrail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditTrail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contextId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FilterAuditTrailQuery, FilterAuditTrailQueryVariables>
export const FilterAuditTrailByEntityIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FilterAuditTrailByEntityIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FilterAuditTrailByEntityIdsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filterAuditTrailByEntityIds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuditTrail' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditTrail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditTrail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contextId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FilterAuditTrailByEntityIdsQuery, FilterAuditTrailByEntityIdsQueryVariables>
export const GetProjectTaskAuditTrailByWorkflowTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProjectTaskAuditTrailByWorkflowTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowTemplateId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectTaskAuditTrailByWorkflowTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workflowTemplateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workflowTemplateId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuditTrail' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditTrail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditTrail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contextId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectTaskAuditTrailByWorkflowTemplateQuery,
  GetProjectTaskAuditTrailByWorkflowTemplateQueryVariables
>
export const GetProcessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProcess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'process' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProcessFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProcessMemberFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProcessMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProcessFieldFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProcessField' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
          { kind: 'Field', name: { kind: 'Name', value: 'options' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProcessFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Process' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'access' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'space' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconColor' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'members' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProcessMemberFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProcessFieldFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProcessQuery, GetProcessQueryVariables>
export const GetProcessesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProcesses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'processes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProcessFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProcessMemberFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProcessMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProcessFieldFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProcessField' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
          { kind: 'Field', name: { kind: 'Name', value: 'options' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProcessFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Process' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'access' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'space' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconColor' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'members' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProcessMemberFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProcessFieldFields' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProcessesQuery, GetProcessesQueryVariables>
export const GetSponsorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSponsor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetSponsorInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getSponsorOrg' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSponsorQuery, GetSponsorQueryVariables>
export const GetPartnerSourcesForPartnershipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPartnerSourcesForPartnership' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'partnershipId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAllForPartnershipId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'partnershipId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'partnershipId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'config' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'syncedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPartnerSourcesForPartnershipQuery, GetPartnerSourcesForPartnershipQueryVariables>
export const GetPartnerSourcesWithCountsForPartnershipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPartnerSourcesWithCountsForPartnership' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'partnershipId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAllWithCountsForPartnershipId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'partnershipId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'partnershipId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'config' } },
                { kind: 'Field', name: { kind: 'Name', value: 'syncedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fragmentCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'issueCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPartnerSourcesWithCountsForPartnershipQuery,
  GetPartnerSourcesWithCountsForPartnershipQueryVariables
>
export const GetPartnerSourceByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPartnerSourceById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'partnerSourceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPartnerSourceById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'partnerSourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'partnerSourceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'config' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'syncedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPartnerSourceByIdQuery, GetPartnerSourceByIdQueryVariables>
export const GetRuleByPartnerSourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRuleByPartnerSource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'partnerSourceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rulesByPartnerSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'partnerSourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'partnerSourceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'conditionGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'conditions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'operator' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'regulation' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRuleByPartnerSourceQuery, GetRuleByPartnerSourceQueryVariables>
export const GetActivitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetActivities' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActivityDetails' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Activity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateFrequency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responsibleParty' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtopic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetActivitiesQuery, GetActivitiesQueryVariables>
export const ActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Activity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActivityDetails' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Activity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateFrequency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responsibleParty' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtopic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActivityQuery, ActivityQueryVariables>
export const OrganizationsByTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OrganizationsByType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationsByType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organizationProducts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrganizationsByTypeQuery, OrganizationsByTypeQueryVariables>
export const GetAdminBankReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAdminBankReport' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAdminBankReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reports' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'overallHealth' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'healthStatus' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAdminBankReportQuery, GetAdminBankReportQueryVariables>
export const GetAllMosaicPartnershipsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllMosaicPartnerships' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAllMosaicPartnerships' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'partnerOrganization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sponsorOrganization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllMosaicPartnershipsQuery, GetAllMosaicPartnershipsQueryVariables>
export const GetMosaicPartnershipsForOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMosaicPartnershipsForOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetSponsorshipsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMosaicPartnershipsForOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'programType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'partnerOrganization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMosaicPartnershipsForOrganizationQuery,
  GetMosaicPartnershipsForOrganizationQueryVariables
>
export const GetActivitySubscriptionsForActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetActivitySubscriptionsForActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrganizationType' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sourceBankId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getActivitySubscriptionsForActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sourceBankId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sourceBankId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourceBank' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inheritedFromBank' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optedOut' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetActivitySubscriptionsForActivityQuery,
  GetActivitySubscriptionsForActivityQueryVariables
>
export const GetSubscriptionsForOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSubscriptionsForOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orgId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getSubscriptionsForOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orgId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orgId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSubscriptionsForOrganizationQuery, GetSubscriptionsForOrganizationQueryVariables>
export const ActivityTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ActivityTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'taskId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'taskId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'taskId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActivityTaskDetails' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityTaskDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ActivityTask' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittingUserExplanation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalUserExplanation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submissionStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedTo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approvedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optedOut' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'responsibleParty' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topic' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subtopic' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourceBank' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'author' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'originalMimeType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActivityTaskQuery, ActivityTaskQueryVariables>
export const GetActivityTasksForSubscriberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetActivityTasksForSubscriber' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetActivityTasksInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getActivityTasksForSubscriber' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActivityTaskDetails' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityTaskDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ActivityTask' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittingUserExplanation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalUserExplanation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submissionStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedTo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approvedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optedOut' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'responsibleParty' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topic' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subtopic' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourceBank' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'author' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'originalMimeType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetActivityTasksForSubscriberQuery, GetActivityTasksForSubscriberQueryVariables>
export const GetActivityTasksForTodoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetActivityTasksForTodo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetActivityTasksInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getActivityTasksForTodo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActivityTaskDetails' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityTaskDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ActivityTask' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittingUserExplanation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalUserExplanation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submissionStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedTo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approvedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optedOut' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'responsibleParty' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topic' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subtopic' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourceBank' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'author' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'originalMimeType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetActivityTasksForTodoQuery, GetActivityTasksForTodoQueryVariables>
export const GetActivityTasksForPartnerTodoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetActivityTasksForPartnerTodo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetActivityTasksInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getActivityTasksForPartnerTodo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActivityTaskDetails' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityTaskDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ActivityTask' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittingUserExplanation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalUserExplanation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submissionStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedTo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approvedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optedOut' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'responsibleParty' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topic' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subtopic' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourceBank' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'author' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'originalMimeType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetActivityTasksForPartnerTodoQuery, GetActivityTasksForPartnerTodoQueryVariables>
export const GetActivityTasksForFintechDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetActivityTasksForFintech' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetActivityTasksInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getActivityTasksForFintech' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActivityTaskDetails' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityTaskDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ActivityTask' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittingUserExplanation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalUserExplanation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submissionStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedTo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approvedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optedOut' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'responsibleParty' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topic' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subtopic' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourceBank' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'author' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'originalMimeType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetActivityTasksForFintechQuery, GetActivityTasksForFintechQueryVariables>
export const GetActivityTasksByFilterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetActivityTasksByFilter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetActivityTasksInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getActivityTasksByFilter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActivityTaskDetails' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityTaskDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ActivityTask' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittingUserExplanation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalUserExplanation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submissionStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedTo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approvedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optedOut' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'responsibleParty' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topic' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subtopic' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourceBank' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'author' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'originalMimeType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetActivityTasksByFilterQuery, GetActivityTasksByFilterQueryVariables>
export const GetActivityTasksStatusCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetActivityTasksStatusCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ActivityStatusCountInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getActivityTasksByStatusCount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetActivityTasksStatusCountQuery, GetActivityTasksStatusCountQueryVariables>
export const GetReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateMosaicReportInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MosaicActivityReport' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activity' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'topic' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subtopic' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'responsibleParty' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'updateFrequency' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'complianceStatus' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'partnerName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'activityTasks' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'ActivityTaskDetails' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latestActivityTaskSummary' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'lastRequestedAt' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'lastSubmittedAt' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'comments' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'author' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                                  ],
                                                },
                                              },
                                              { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                            ],
                                          },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'submittingUserExplanation' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'approvalUserExplanation' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MosaicActivitiesReport' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reports' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'activity' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'topic' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'subtopic' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                                          },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'responsibleParty' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'updateFrequency' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'complianceStatus' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'partnerName' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'activityTasks' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'ActivityTaskDetails' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'latestActivityTaskSummary' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'lastRequestedAt' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'lastSubmittedAt' } },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'comments' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'author' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                                        ],
                                                      },
                                                    },
                                                    { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'submittingUserExplanation' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'approvalUserExplanation' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MosaicBankReport' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MosaicFintechReport' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityTaskDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ActivityTask' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittingUserExplanation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalUserExplanation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submissionStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedTo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approvedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optedOut' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'responsibleParty' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topic' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subtopic' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourceBank' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'author' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'originalMimeType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetReportQuery, GetReportQueryVariables>
export const GetActiveActivitiesForOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetActiveActivitiesForOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orgId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getActiveActivitiesForOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orgId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orgId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActivityDetails' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Activity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateFrequency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responsibleParty' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtopic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetActiveActivitiesForOrganizationQuery, GetActiveActivitiesForOrganizationQueryVariables>
export const ComputeTextDifferencesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ComputeTextDifferences' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fragmentId1' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fragmentId2' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'computeTextDifferences' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fragmentId1' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fragmentId1' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fragmentId2' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fragmentId2' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'added' } },
                { kind: 'Field', name: { kind: 'Name', value: 'removed' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ComputeTextDifferencesQuery, ComputeTextDifferencesQueryVariables>
